<!--
 * @Description: 弹窗搜索选择组件
 * @Author: luozhong
 * @Date: 2020-07-21 22:08:42
-->
<template>
  <gg-dialog
    class="search-dialog"
    :title="title"
    :visible.sync="isShow"
    width="580px"
    v-loading="isLoading"
  >
    <div class="search-content" :class="{ single: isSingle || !showCheckbox }">
      <div class="search-res-box">
        <gg-chips
          v-model="selectObjArr"
          :md-placeholder="placeholder"
          ref="selectChips"
          md-check-duplicated
          :filter.sync="filterName"
          @md-delete="mdDelete"
        >
          <div
            slot-scope="scope"
            slot="md-chip"
            class="chip-item"
            :class="{ 'is-user': scope.chip.isUser }"
          >
            <slot name="chip" :item="scope.chip">
              <template v-if="scope.chip.isUser">
                <div class="user-select-chip">
                  <head-img
                    class="user-avatar"
                    :src="scope.chip.avatar"
                    :firstName="scope.chip.firstName"
                    :lastName="scope.chip.lastName"
                    line-height="36"
                  />
                  <span>{{ scope.chip.fullName }}</span>
                </div>
              </template>
              <template v-else>{{ scope.chip[label] }}</template>
            </slot>
          </div>
        </gg-chips>
      </div>
	  <div v-if='(isShowSearchRes && listArr.length)' class="slotAva"style=" height: 32px;" ></div>
      <p class="search-desc" v-if='isShowSearchRes'>{{addStudentDes?`${selectObjArr.length} enrolled students for the class size of ${listArr.length}`:'SEARCH RESULTS'}}</p>
      <p v-if="!listArr.length" class="no_result">
        <i class="iconfont iconyiquxiao" />
        No results
      </p>
      <ul class="select-ul select-ul-list" :class="(title =='Withdraw students' || title=='Remove teacher')?'mar20':''" v-show="isShowList" v-if='isShowSearchRes || (title =="Withdraw students" || title=="Remove teacher")' >
        <li v-for="(item, index) in listArr" :key="index">
		  <md-checkbox
            v-model="selectArr"
            class="md-primary"
            @change="change"
            :value="item.id"
			:disabled="item.tipMessage?true:false"
          >
		  
            <slot name="checkboxLine" :row="item">
              <div class="customer-line" 
			  :class="item.tipMessage?'disabledStuednt':''"
			  v-if="item.isUser">
                <head-img
                  class="customer-icon"
                  :src="item.avatar"
                  :firstName="item.firstName"
                  :lastName="item.lastName"
                  line-height="36"
                />
				<el-tooltip
				  popper-class="edoovo-table-cell-tooltip"
				  :content="item.tipMessage"
				  placement="bottom-start"
				  :disabled="!item.tipMessage"
				  :visible-arrow="false"
				>
				<ul>
				  <p class="user-name">{{ item.fullName || "-" }}</p>
				  <p class="user-email">{{ item.email || "-" }}</p>
				</ul>
				</el-tooltip>
                
              </div>
              <template v-else>{{ item[label] }}</template>
            </slot>
          </md-checkbox>
        </li>
      </ul>
	  <div v-if="!isShowSearchRes" style="height: 352px;"></div>
      <p class="desc" v-if="desc">
        {{ desc }}
      </p>
      <ul class="select-ul selected" v-if="showSelect">
        <li v-for="item in selectObjArr" :key="item.id">
          <div class="customer-line" v-if="item.isUser">
            <head-img
              class="customer-icon"
              :src="item.avatar"
              :firstName="item.firstName"
              :lastName="item.lastName"
              line-height="36"
            />
            <ul>
              <p class="user-name">{{ item.fullName || "-" }}</p>
            </ul>
          </div>
          <template v-else>{{ item[label] }}</template>
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <BaseButton @click="cancelSelect">{{ cancelText }}</BaseButton>
      <BaseButton type="primary" :disabled="isDisabled" @click="submit">{{
        submitText
      }}</BaseButton>
    </span>
  </gg-dialog>
</template>

<script>
import BaseButton from "@/components/edoovo-components/baseButton";

export default {
  name: "BaseSelectDialog",
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "title",
    },
    defaultSearchValue: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
	addStudentDes: {
	  type: Boolean,
	  default: false,
	},
    defaultSelectData: {
      type: Array,
      default: () => [],
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    submitText: {
      type: String,
      default: "Add",
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectArr: [],
      courseName: "",
      filterName: this.defaultSearchValue,
      isShow: this.value,
      isLoading: false,
      isSubmitDisabled: false,
      selectObjArr: [],
    };
  },
  computed: {
	  
	  isShowSearchRes(){
		  // console.log()
		  if(this.title=='Add teachers'&& this.filterName){
			  return true
		  }else if(this.title=='Add students'&& this.filterName){
		  	return true
		  }else if(this.title =='Withdraw students' ||  this.title=='Remove teacher'){
			  return false
		  }else if(this.title!='Add students' && this.title!='Add teachers'){
			  return true
		  }else{
			  return false
		  }
	  },
    placeholder() {
      return this.selectArr.length ? "" : "Type a name or email address";
    },
    isShowList() {
      return !(this.isSingle && this.selectArr.length);
    },
    listArr() {
      const _filterName = (this.filterName || "").toLowerCase();
      return this.data.filter((v) => {
        return (
          (v[this.label] || "").toLowerCase().includes(_filterName) ||
          (v.email || "").toLowerCase().includes(_filterName)
        );
      });
    },
    isDisabled() {
      if (this.selectArr.length === 0) {
        return true;
      }
      if (this.max === 0) {
        return false;
      }
      return this.max < this.selectArr.length;
    },
  },
  watch: {
    value(res) {
      this.isShow = res;
      if (res) {
        this.filterName = this.defaultSearchValue;
        this.$nextTick(() => {
          this.selectArr = this.defaultSelectData.map((item) => item.id);
        });
      }
    },
    isShow(res) {
      this.$emit("input", res);
      this.$nextTick(() => {
        try {
          this.$refs.selectChips.$refs.input.$el.focus();
        } catch (e) {
          console.error(e);
        }
      });
    },
    selectArr: {
      handler(res) {
        this.selectObjArr = this.listArr.filter((item) =>
          this.selectArr.includes(item.id)
        );
        this.$emit("change", res);
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.isShow = false;
      this.$emit(
        "submit",
        this.listArr.filter((item) => this.selectArr.includes(item.id))
      );
      this.selectArr = [];
    },
    cancelSelect() {
      this.isShow = false;
      this.selectArr = [];
    },
    // 删除对应的选择项
    mdDelete(res) {
      const index = this.selectArr.findIndex((item) => res.id === item);
      if (index >= 0) {
        this.selectArr.splice(index, 1);
      }
    },
    change() {
      this.filterName = "";
    },
  },
};
</script>

<style lang="less" scoped>
.search-dialog {
  /deep/ .el-dialog {
    max-height: 576px;
    min-height: 244px;
    display: flex;
    flex-direction: column;
  }
  /deep/ .el-dialog__header {
    font-family: OpenSans-Bold;
  }
  /deep/ .el-dialog__footer {
    .base-button + .base-button {
      margin-left: 8px;
    }
  }
  /deep/ .el-dialog__body {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
  .no_result {
    cursor: auto;
    line-height: 36px;
    font-size: 16px;
    color: #202124;
    font-family: Roboto;
    display: flex;
    padding: 10px 0;
    margin-left: 24px;
    &:hover {
      background: transparent;
    }
    .iconfont {
      width: 36px;
      height: 36px;
      font-size: 20px;
      border-radius: 50%;
      background: #e0e0e0;
      text-align: center;
      line-height: 36px;
      margin-right: 20px;
    }
  }
}
.search-res-box {
  padding: 0 20px;
  font-family: Roboto;
  /deep/ .md-field {
    margin-top: 0;
    padding: 0 !important;
    min-height: auto;
  }
  /deep/ .md-chip {
    max-width: 200px;
    .chip-item {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &.is-user {
        margin-left: -8px;
      }
      .user-select-chip {
        display: flex;
        align-items: center;
        .user-avatar {
          width: 24px !important;
          height: 24px !important;
          line-height: 24px !important;
          margin-right: 4px;
        }
      }
    }
  }
}
.search-content {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  .desc {
    padding: 10px 20px 0;
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: white;
	padding-bottom: 20px;
	font-size: 12px;
	font-family: Roboto;
  }
  .search-desc {
    margin-bottom: 8px;
    color: #5f6368;
    font-size: 12px;
    margin-top: 32px;
    padding: 0 20px;
    font-family: Roboto-Medium;
  }
  &.single {
    .select-ul {
      /deep/ .md-checkbox-container {
        display: none;
      }
    }
  }
  .chip-item {
    line-height: 33px;
  }
  .search-res {
    height: 36px;
  }
  .select-ul-list {
    overflow: auto;
    flex: 1;
  }
  .select-ul {
    color: #202124;
    .md-checkbox {
      width: 100%;
      display: flex;
      padding: 0;
      margin: 0;
      height: 100%;
      align-items: center;
      /deep/ .md-checkbox-container {
        margin-right: 16px;
      }
      /deep/ .md-checkbox-label {
        width: 100%;
        height: 100%;
        padding-left: 0;
        display: flex;
        align-items: center;
        font-family: Roboto;
      }
    }
    &.selected {
      display: flex;
      flex-wrap: wrap;
      padding: 22px 20px;
      li {
        margin-right: 19px;
        margin-bottom: 8px;
        height: 36px;
        padding: 0;
      }
      .customer-line {
        display: flex;
        align-items: center;
        height: 100%;
        .customer-icon {
          margin-right: 8px;
        }
      }
    }
    li {
      padding: 0 20px;
      height: 52px;
      line-height: 52px;
      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}
.customer-line {
  display: flex;
  .customer-icon {
    margin-right: 20px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .user-name {
    font-size: 14px;
    color: #202124;
    font-family: Roboto-Medium;
  }
  .user-email {
    font-size: 12px;
    color: #202124;
    font-family: Roboto;
  }
}
/deep/.search-content{
	padding-top: 36px!important;
}
.select-ul{
	min-height: 272px;
}
.slotAva{
	    color: #5f6368;
	    font-size: 12px;
	    font-family: Roboto-Medium;
		padding: 0 20px;
}
.disabledStuednt{
	.user-name,.user-email{
		color: #b4b4b4;
	}
}
</style>

<style lang="less">
@media screen and (max-width: 1600px) {
  .search-content {
    padding-top: 0 !important;
  }
}
.mar20{
	margin-top: 32px;
}
</style>
