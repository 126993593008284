<script>
import { debounce } from 'lodash';
export default {
  name: 'GgSubmitButton',
  functional: true,
  render(createElement, context) {
    const newOn = {};
    context.data.on &&
      Object.entries(context.data.on).forEach((item) => {
        newOn[item[0]] = debounce(item[1], 300);
      });
    return createElement(
      `el-button`,
      {
        props: {
          ...context.props,
          mDprimary: true
        },
        class: (context.data.staticClass || '') + ' gg_submit_button',
        directives: context.data.directives,
        on: newOn,
        style: {
          ...context.data.staticStyle
        }
      },
      [
        context.slots().default //  also pass default slot to child
      ]
    );
  }
};
</script>

<style lang="less" scoped></style>
