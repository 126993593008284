var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ggPage"},[_c('div',{staticClass:"ggPage-header"},[_c('TopAppBar',{attrs:{"topType":_vm.topType,"title":_vm.title}},[(_vm.routerPath.indexOf('announcement') < 0)?[(_vm.topType === 'location')?_c('div',{staticClass:"location-select"},[_c('baseGGSelect',{attrs:{"inline":true,"placeholder":"location"},on:{"change":_vm.change},model:{value:(_vm.locationVal),callback:function ($$v) {_vm.locationVal=$$v},expression:"locationVal"}},_vm._l((_vm.locationList),function(item,index){return _c('md-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1):_vm._e(),(_vm.topType === 'datePicker')?_c('div',{staticClass:"date-picker",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"datebox"},[_c('div',[_vm._v(_vm._s(_vm.getdate(_vm.date)))])]),_c('div',{staticClass:"select-icon-arrow"},[_c('md-drop-down-icon',{on:{"click":_vm.cliDatepicker}})],1),_c('md-datepicker',{ref:"datepicker",staticClass:"date-picker-input",on:{"md-closed":_vm.onClosed},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e()]:_vm._e(),_vm._t("topAppBar")],2)],1),_c('div',{staticClass:"ggPage-main",class:{noPadding:_vm.isType5}},[(_vm.isType2 || _vm.isType3 || _vm.isType6)?_c('div',{staticClass:"ggPage-main-left"},[_vm._t("left")],2):_vm._e(),_c('div',{staticClass:"ggPage-main-center",class:{
          centerPadding1: _vm.isType1,
          centerPadding2: _vm.isType2,
          centerPadding3: _vm.isType3,
          centerPadding4: _vm.isType4,
          centerPadding5: _vm.isType5,
          centerPadding6: _vm.isType6,
        }},[_c('div',{staticClass:"ggPage-main-center-content"},[_vm._t("default")],2)]),(_vm.isType3)?_c('div',{staticClass:"ggPage-main-right"},[_vm._t("right")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }