<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "@/assets/css/reset.less";
import "@/assets/fonts/iconfont.css";
export default {
  
}
</script>
<style lang="less">
@font-face {
  font-family: 'opensans-semibold';
  src: url('../public/fonts/OpenSans-SemiBold.ttf');
  font-display: swap;
}
#app{
  background: #fff;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.draft-dialog .el-dialog {
  width: 560px;

  .el-dialog__footer,
  .el-dialog__header {
    border: 0 !important;
  }
}

.collapse-list,
.section-inner {
  height: 100%;
}

.response-inner .section-inner {
  padding-bottom: 0;
}

.section-inner {
  padding-bottom: 112px;
  height: auto;
}


.announcement.search-bar {

  .datepicker {
    position: relative;

    .calendar-arrow {
      position: absolute;
      right: 0;
      top: 21px;
      width: 24px;
      height: 24px;
    }
  }

  .md-field.md-clearable .md-input {
    margin-left: 0;
  }
  
  .md-button.md-theme-default,
  .datepicker .md-date-icon {
    display: none;
  }

  .md-field>.md-icon~label {
    left: 0;
  }
  
}

// announcement page
.announcement-inner {
  font-family: Roboto;

  .el-loading-mask {
    position: fixed;
    background-color: rgba(255,255,255,.2);
  }

  .el-loading-spinner {
    position: fixed;
    left: 0;
  }

  .response-option {
   .wraper {
      padding: 0;
      margin: 6px 0 12px 20px;

      .base-button {
        font-family: 'opensans-semibold' !important;
        font-size: 14px !important;
      }
    }
  }

  .el-table {
    
    .cell {
      position: relative;
      margin-top: 12px;
      line-height: 32px;
      overflow: inherit;
    }
  }

  .draft-right {
    .el-checkbox__input {
      margin-top: 0;
    }
    .el-checkbox__label {
      margin-top: 0;
    }

    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      border: 2px solid #606368;
      border-radius: 3px;
    }

    .el-checkbox__inner::after {
      width: 5px;
      height: 10px;
      left: 4px;
      top: -1px;
      border-width: 2px;
    }

    .drop_down .drop_down_menus {
      top: 37px;
      width: 108px;
    }
  }

  .iconmore_vert-24px.iconfont {
    font-size: 24px;
    vertical-align: -0.5em;
  }

  .section-box .iconfont {
    vertical-align: -0.6em;
  }

  .hr {
    height: 1px; 
    width: 100%; 
    margin: 0;
    padding: 0; 
    margin-bottom: 8px;
    background: #E0E0E0; 
  }

  .menu_bar .drop_down_menus {
    padding-bottom: 0 !important;
  }

  .menu_bar .drop_down_menus > * {
    padding: 0 !important;
    width: 128px !important;
    
    &:hover {
      background: #fff !important;
    }

    span {
      display: block;
      margin-bottom: 8px;
      padding: 0 0 0 15px;
      height: 32px;
      line-height: 32px;

      &:hover {
        background: #eee;
      }
    }
  }

  .el-table td, .el-table th.is-leaf{
    border-bottom: 0;
  }

  .response-col .el-table::before {
    display: none;
  }

  .pdf-preview canvas {
    width: auto !important;
    height: 72px !important;
    vertical-align: middle !important;
  }

  // .section-box .menu_bar .menu_bar_button:hover {
  //   background: none !important;
  // }

  .edit-editing li,
  .section-mail li {
    margin-left: 15px;
  }

  .el-image__inner {
    height: 100%;
  }

  .response-inner .md-radio {
    display: block;

    .md-radio-label,
    .md-radio-container {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, 
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #3474e0 !important;
    border-color: #3474e0 !important;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #202124 !important;
  }

  .el-button .iconfont{
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    border-right: 1px solid #DCDFE6;

     &:hover {
      background-color: #eee;
    }

    &.downShowIcon {
      background-color: #eee;
    }
  }

  //编辑器
  .mce-panel {
    background: #DCDDDE;
    height: 56px;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
    border: 0;
    box-sizing: border-box;
  }

  .mce-btn {
    background: #DCDDDE;
    box-shadow: none;

    &:hover {
      background: #DCDDDE;
      border-color: #DCDDDE;
    }
  }

  .mce-toolbar .mce-btn-group {
    padding: 0;
  }

  .mce-btn button {
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }

  .mce-btn-group:not(:first-child) {
    padding-left: 8px;
    border-left: 1px solid #9c9c9c;
  }

  .mce-ico {
    font-size: 18px;
    line-height: 18px;
    color: #606368;
  }

  .mce-btn-group .mce-btn {
    margin: 0 8px 0 0;
  }

  .mce-top-part::before {
    box-shadow: none;
  }

  .mce-toolbar-grp {
    padding: 16px 0 0 8px;
  }

  .response-info .md-field {
    margin-bottom: 10px;
  }

  // .rowName input.md-input{
  //   font-size: 14px;
  // }

  .response-date {

    .md-field.md-theme-default:after {
      border-bottom: 1px solid #E0E0E0;
    }

    .md-datepicker .md-date-icon {
      display: none;
    }

    .md-field .md-input-action {
      display: none;
    }

    .md-field>.md-icon~.md-input {
      margin-left: 0;
    }
    
    .md-field label {
      color: #B4B4B4;
      font-size: 14px!important;
    }

    .md-field.md-has-value label {
      font-size: 12px;
    }

    .md-field>.md-icon~label {
      left: 0;
    }
  }

  .el-table__empty-block {
    display: none;
  }
}

.posted-dialog {

  .el-dialog__body {
    padding: 20px;
    // height: 670px;
    // overflow-y: auto;
  }

  .el-dialog__headerbtn {
    font-size: 20px;
    font-weight: bold;

    .el-dialog__close {
      color: #383838;
    }
  }

  .el-dialog__header {
    padding: 20px;
    border-bottom: 1px solid #E0E0E0;
  }

  .md-table .md-table-content thead th {
    border-top: 1px solid #E0E0E0;
    background-color: #F2F2F2;
    color: #666;
  }

  .md-sorted,
  .md-sorted-desc {

    .md-table-head-label {
      color: #000;
    }
  }

  .md-table-head-label,
  .md-table-cell {
    font-family: Roboto;
    font-size: 14px;
    color: #666;
  }

  td.md-table-cell {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }

  .avp {
    box-sizing: border-box;
  }

  .md-card {
    box-shadow: none;

    .no {
      display: inline-block;
      margin-right: 6px;
      margin-top: -1px;
      width: 20px;
      height: 20px;
      background: #b4b4b4;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-size: 10px;
      overflow: hidden;
      vertical-align: middle;
      font-family: Open Sans;
      font-weight: 600;
    }

    .avatar {
      margin-right: 6px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      vertical-align: middle;

      &.np {
        margin: 0;
      }
    }
  }
}

body{
  .optbar{
    position: fixed;
    top:64px;left:0;
    z-index: 99;
    width: 100%;
    background:#fff;
    font-size: 20px;
    color:#202124;
    font-family: 'Roboto';
    box-shadow: 0px 0px 6px #00000029;
    padding:8px 20px;
    line-height: 50px;
    /deep/ .drop_down_menus{
      top:40px!important;
      right:10px!important;
    }
    
    .el-button{
      color:#777;
      margin-right:10px;
      font-size: 20px;
      width: 24px;
      height:24px;
      border-radius: 50%;
      padding: 0;
      &.disabled{
        cursor:not-allowed;color:#999;
      }
      &:hover{
        background:#eeeeee;
      }
    }
    .btns{
      position: absolute;
      right:20px;
      top: 50%;
      margin-top:-15px;
      text-align: right;
      height:30px;
      line-height:30px;
      >span{
        margin:0;
      }
      .menu_bar,.icon-button{
        display: inline-block;
        vertical-align: middle;
      }
      .svg-icon{
        height:20px;
      }
      .el-icon-share{
        font-size: 18px;
      }
      .base-button{
        padding: 0 12px;
      }
    }
  }
}

.posted-dialog {
  .el-dialog {
    border-radius: 8px;
  }

  .el-dialog__title {
    padding-left: 50px !important;
    font-family: 'opensans-semibold';
    font-size: 16px;
    color: #202124;
  }
}

.md-table-fixed-header-container th {
  border-top: 1px solid #E0E0E0;
  background: #F2F2F2;
}

.edit-editing,
.section-mail {
  ul {
    list-style: disc;
  }
}

.mp-dialog-cut {
  .el-dialog {
    margin-top: -225px !important;
    top: 50%;
  }
}

.mp-dialog {
  .el-dialog {
    margin-top: -85px !important;
    top: 50%;

    .el-dialog__header {
      padding: 0 20px !important;
      height: 60px !important;
      line-height: 60px !important;
    }

    .el-dialog__body {
      padding: 0 20px !important;
    }

    .el-dialog__footer {
      box-sizing: border-box;
      padding: 20px !important;
      height: 76px !important;
    }

    .el-button+.el-button {
      margin-left: 8px !important;
    }
  }
}

.create-dialog {
  .el-dialog {
    margin-top: -68px !important;
  }
}

.announcement.search-bar .md-field label,
.announcement.search-bar .md-field .md-input, 
.announcement.search-bar .md-field .md-textarea {
  font-size: 14px !important;

  &::placeholder {
    color: #B4B4B4;
  }
}

.posted-dialog {
  .el-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0 0 0 -580px;
  }

  .md-table-cell.num {
    vertical-align: top;
  }
}

.draft-dialog .dialogContent {
   word-wrap: break-word; 
   word-break: normal;
}

.add-note-dialog .add-note-input .md-textarea {
  font-family: Roboto !important;
  font-size: 14px !important;
  color: #202124 !important;
}

.add-note-dialog .el-dialog__body {
  padding: 20px !important;
}

.journal-inner {
  font-family: Roboto;

  .el-loading-mask {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .el-loading-spinner .circular {
    width: 48px;
    height: 48px;

    .path {
      stroke: #1A73E8;
    }
  }

  .el-loading-spinner {
    position: fixed;
    left: 0;
  }

  .clear-btn .el-icon-close {
    font-size: 20px;
  }

  .photos-albums-bar {
    .el-tabs__item {
      padding: 0;
      width: 132px;
      text-align: center;
      font-family: Roboto;
      color: #202124;
    }

    .el-tabs__active-bar,
    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    .el-tabs__nav-wrap::after {
      background-color: #e0e0e0;
    }

    .el-tabs__header {
      margin: 0 80px 0 0;
    }

    .el-tabs__active-bar {
      background: #1A73E8;
    }

    .el-tabs__item.is-active {
      color: #1A73E8;
    }
  }

  .view-carousel-inner {
    .el-carousel__arrow {
      background: #000;

      &:hover {
        background: #1A73E8;
      }
    }
  }

  .menu_bar {
    width: 40px !important;
    height: 40px !important;
    line-height: inherit !important;

    .iconmore_vert-24px.iconfont {
      font-size: 24px;
      vertical-align: -0.6em;
    }

    .drop_down_menus .hr-class {
      padding: 0 !important;
    }

    .menu_bar_button:hover {
      background: none;
    }

    .drop_down_menus {
      padding: 0;
    }
  }

  .drop_down_menus {
    width: 132px !important;
  }

  .menu_bar .drop_down_menus hr {
    margin: 5px 0;
    border-bottom: 1px solid #e0e0e0 !important;
  }

  .base-list .left-column > div + div {
    margin: 0 60px 0 0;
  }

  .menus .menu_box .menu_item.has_extra .extra {
    font-family: OpenSans-Bold;
    font-size: 12px;
  }

  .empty-box {
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -125px 0 0 -107px;
    width: 214px;
    height: 250px;
    text-align: center;

    img {
      width: 100%;
      display: block;
    }

    p {
      margin-top: 20px;
      font-size: 20px;
      font-family: Roboto;
      color: #606367;
    }
  }

  .cancel {
    .drop_down .drop_down_menus {
      top: -114px !important
    } 

    .el-button .iconfont{
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      border-right: 1px solid #DCDFE6;

      &:hover {
        background-color: #eee;
      }

      &.downShowIcon {
        background-color: #eee;
      }
    }
  }
}

.select-user-popup {
  .md-field.md-theme-default:after {
    background-color: #e0e0e0 !important;
  }
  .el-dialog .el-dialog__body {
    padding: 5px 0 15px 0 !important;
  }
}

.ui-img-upload-wrapper {
  .el-upload {
    width: 100%;
  }
}

.ui-text-edit {
  position: relative;

  &.showbar {
    .mce-top-part.mce-container.mce-stack-layout-item {
      display: block;
    }
  }

  .announcement-inner .mce-btn button {
    min-width: 27px;
    width: auto;
    max-width: 55px;
    height: 26px;
    line-height: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  .mce-menubtn.mce-fixed-width span {
    width: 75px;
  }

  .announcement-inner .mce-ico {
    font-size: 16px;
    line-height: 16px;
    color: #606367;
  }

  .announcement-inner .mce-btn-group:not(:first-child) {
    padding-left: 5px;
    margin-left: 5px;
  }

  .mce-top-part.mce-container.mce-stack-layout-item {
    display: none;
    position: absolute;
    left: -2px;
    top: -60px;
  }

  .mce-container.mce-toolbar {
    width: 440px;
    height: 48px;
    background: #fff;
    border: 1px solid #E0E0E0;
  }

  .mce-btn-group .mce-btn {
    margin: 0 1px 0 0;
  }

  .mce-container-body.mce-flow-layout {
    padding: 10px 0 0 8px;
    background: #fff;
  }

  .mce-toolbar-grp {
    padding: 0;
    margin-right: 10px;
  } 
  .mce-panel {
    height: 48px;
    background: transparent;
  }

  .mce-btn {
    background: #fff;
  }
  
  .mce-btn.mce-active, .mce-btn.mce-active:hover, .mce-btn.mce-active:focus, .mce-btn.mce-active:active {
    background: #e0e0e0;
  }

  .mce-btn.mce-active button, .mce-btn.mce-active:hover button, .mce-btn.mce-active i, .mce-btn.mce-active:hover i {
    color: #606367;
  }
}
.clearfix:after{display:block;clear:both;content:"";visibility:hidden;height:0}
.journal-page .model-box .ui-edit {
  ul,
  ol {
    list-style: disc;
    padding-left: 5px;
  }

  ol {
    padding-left: 20px;
    list-style: auto;
  }
}
.centerPadding6 {
  padding: 0 0 0 32px;
  overflow: visible !important;

  .collapse-list {
    padding-right: 80px;
  }
}

.md-datepicker-body-content {
  // height: 264px !important;
}

.editName {
  .md-error {
    bottom: -16px !important;
  }

  // .operation {
  //   position: absolute;
  //   right: 180px;
  //   top: 3px;
  // }
}

.response-option {
  .el-table__body-wrapper,
  .el-table {
    overflow: inherit;
  }
}

.current-span {
  display: block;
  // width: 700px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.md-radio .md-radio-label {
  display: block !important;
  min-height: 20px !important;
  height: auto !important;
  overflow: hidden !important;
}

.md-radio.md-theme-default.md-disabled .md-radio-container {
  float: left;
}

.mce-colorbutton .mce-preview {
  margin-left: -21px !important;
}

.mce-colorbtn-trans div {
  line-height: 13px !important;
}

// #mceu_143-open{
//  width: auto;
// }
// #mceu_142-open {
//  width: 54px;
// }
</style>
