<template>
  <div class="ggPage">
    <div class="ggPage-header">
      <TopAppBar :topType="topType" :title="title">
        <!-- fengchao修改2021.3.8 announcement模块不显示学校切换 -->
        <template  v-if="routerPath.indexOf('announcement') < 0">
          <div v-if="topType === 'location'" class="location-select">
            <baseGGSelect
              v-model="locationVal"
              :inline="true"
              placeholder="location"
              @change="change"
            >
              <md-option
                v-for="(item, index) in locationList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </md-option>
            </baseGGSelect>
          </div>
         
		  <div style="position: relative" v-if="topType === 'datePicker'" class="date-picker">
		   <div class="datebox">
		        <div>{{getdate(date)}}</div>
		       
		      </div>
			  <div class="select-icon-arrow" >
			    <md-drop-down-icon @click='cliDatepicker' />
			  </div>
		      <md-datepicker class="date-picker-input"
			  ref='datepicker'
		    v-model="date"
		    @md-closed="onClosed" />
		    </div>
        </template>

        <slot name="topAppBar"></slot>
      </TopAppBar>
    </div>
    <div class="ggPage-main" :class="{noPadding:isType5}">
      <div v-if="isType2 || isType3 || isType6" class="ggPage-main-left">
        <slot name="left"></slot>
      </div>
      <!-- fengchao修改2021.7.13 announcement添加无滚动条模式centerPadding6 -->
      <div
        :class="{
          centerPadding1: isType1,
          centerPadding2: isType2,
          centerPadding3: isType3,
          centerPadding4: isType4,
          centerPadding5: isType5,
          centerPadding6: isType6,
        }"
        class="ggPage-main-center"
      >
        <div class="ggPage-main-center-content">
          <slot></slot>
        </div>
      </div>
      <div v-if="isType3" class="ggPage-main-right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @prop { pageType } String  1:只有中间居中内容 、 2:有左侧菜单 、 3:有右侧区域
 * @prop { title } String  标题
 * @prop { topType } String  select/datepicker
 */
import { mapState } from "vuex";
import { Ajax, Utils } from "@/common";
import moment from 'moment'
import MdDropDownIcon from '../common/MdDropDownIcon';
export default {
	components: {	
	  MdDropDownIcon
	},
  name: `GgPage`,
  data() {
    return {
      routerPath: '',
      date: new Date(),
      locationVal: "",
      locationList: [],
    };
  },
  props: {
    pageType: {
      type: String,
      default: "1",
    },
    title: {
      type: String,
      default: "",
    },
    topType: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
    }),
    isType1() {
      return this.pageType === "1";
    },
    isType2() {
      return this.pageType === "2";
    },
    isType3() {
      return this.pageType === "3";
    },
    isType4() {
      return this.pageType === "4";
    },
    isType5() {
      return this.pageType === "5";
    },
    isType6() {
      return this.pageType === "6";
    },
  },
  methods: {
	  cliDatepicker(){
		this.$refs.datepicker.click()
	  },
	  getdate(date){
		  if(this.$material.locale.dateFormat=='dd/MM/yyyy'){
		  	return  moment(date,['DD/MM/YYYY']).format("dddd, DD MMMM YYYY")
		  	
		  }else{
		  	return  moment(date).format("dddd, DD MMMM YYYY")
		  }
		  
		 
	  },
    onClosed(a){
      setTimeout(() => {
        this.$emit('onDateClosed', this.date)
      }, 0);
    },
    forward() {
      this.$emit("forward", this.date);
    },
    next() {
      this.$emit("next", this.date);
    },
    getLocation() {
      let params = {
        userId: this.userId,
      };

      Ajax.get("/usermanage/location/selectLocationByUserId", params).then(
        (res) => {
          this.locationList = res.data;

          if (res.data.length > 1) {
            this.locationList.unshift({
              id: "all",
              name: "All",
            });
          }

          if (this.locationList.length == 0) {
            this.locationVal = "";
          } else {
            this.locationVal = this.locationList[0].id;
          }
		  	  if(sessionStorage.getItem('lastChoiceLocation')){
		  		  this.locationVal=sessionStorage.getItem('lastChoiceLocation')
		  	  }
        }
      );
    },
    change(val){
      this.$emit("changeTopSelect", val);
    }
  },
  watch:{
	  date:{
		  handler(res){

			if(this.$material.locale.dateFormat=='dd/MM/yyyy'){
				if(res.indexOf('-')){
				return false	
				}else{
					sessionStorage.setItem('date', moment(res,['dd/MM/yyyy']).format('YYYY-MM-DD'))
				}
				
			}else{
				sessionStorage.setItem('date', Utils.formatTime(res, '{y}-{m}-{d}'))
			}
			
		  },deep:true
	  }
  },
  created() {
	  this.$material.locale.dateFormat = "yyyy-MM-dd";
	if(sessionStorage.getItem('date')){
		
		this.date = Utils.formatTime(sessionStorage.getItem('date'), '{y}-{m}-{d}')
	}
    this.routerPath = this.$route.path
    this.getLocation();
  },
};
</script>

<style lang="less" scoped>
.ggPage {
  box-sizing: border-box;
  height: 100vh;
  min-width: 1280px;
  min-height: 620px;
  .noPadding{
    padding:0;
  }
  &-header {
    height: 64px;
    /deep/.el-header {
      height: 64px !important;
    }

    .date-picker {
      // display: flex;
      // align-items: center;
      width: 400px;
      height: 45px;
      padding-top: 2px;
      border: 2px solid transparent;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.2);
      .arrow-group {
        display: flex;
        align-items: center;
        height: 48px;
        .arrow {
          flex: 1;
          width: 48px;
          height: 48px;
          line-height: 48px;
          text-align: center;
        }
      }
      /deep/ .md-field {
        padding-top: 0;
      }
      /deep/ .md-field.md-theme-default>.md-icon:after {
        display: none;
      }
      /deep/ .md-field.md-theme-default:after {
        display: none;
      }
    }
    .date-picker-input {
		width: 85%;
      // margin: 0;
      // padding: 0;
      // align-items: center;
      /deep/.md-input-action.md-clear {
        display: none;
      }
      /deep/ svg {
        display: none;
      }
      /deep/ input {
        -webkit-text-fill-color: #fff !important;
      }
      // /deep/.md-input {
      //   padding-left: 28px;
      //   font-family: Roboto;
      //   font-size: 20px;
      //   -webkit-text-fill-color: #fff !important;
      // }
      // &:after {
      //   background-color: transparent;
      // }
    }
  }
  &-main {
    height: calc(100% - 64px);
    display: flex;
    justify-content: center;
    padding-top: 24px;
    &-left {
      width: 280px;
      flex-shrink: 0;
    }
    &-center {
      flex: 1;
      overflow-y: auto;
      &-content {
        height: 100%;
      }
    }
    .centerPadding1 {
      padding: 0 280px;
    }
    .centerPadding2 {
      padding: 0 80px 112px 32px;
    }
    .centerPadding3 {
      padding: 0 0 112px 32px;
    }
    .centerPadding4 {
      padding: 0 80px;
    }
    .centerPadding5 {
      padding: 0;
    }
    &-right {
      width: 280px;
      flex-shrink: 0;
    }
  }
}
.md-input{
	color: rgba(255, 255, 255, 0.2);
}
.datebox{
	display: flex;
	line-height: 41px;
	padding: 0 10px;
	width: 85%;
	justify-content: space-between;
	position: absolute;
	background: #fff;
	z-index: 11;
	height: 100%;
	background: #488FED;
	pointer-events: none;
	line-height: 41px;
	font-family: 'Open Sans';
	font-size: 20px;
	bottom: 0px;
	cursor: pointer;
}
.date-picker {
	.select-icon-arrow{
		display: flex;
		    align-items: center;
		    margin-top: 2px !important;
		    width: 36px !important;
		    height: 36px !important;
		    border-radius: 50%;
		    // background: rgba(255, 255, 255, .2);
		&:hover{
			background: rgba(255, 255, 255, .2);
		}
	}
	/deep/.md-icon.md-theme-default.md-icon-image svg{
		fill: var(--md-theme-default-icon-on-background, #fff);
	}
}



/deep/.ggPage-header .date-picker-input input{
	cursor: pointer;
}
/deep/.location-select{
	font-family: OpenSans;
		font-size: 20px;
}
/deep/.select-icon-arrow{
	position: absolute;
	right: 10px;
}
 /deep/.ggPage-main .centerPadding1{
	 padding: 0 280px!important;
 }
</style>
