<!--
  功能：预览图片组件
  作者：Yu Duan
  邮箱：yu.duan@edoovo.com
  时间：2020年08月26日 23:58:05
-->
<template>
  <div @click="closeModal({ keyCode: 27 })" class="gg_view">
    <div class="gg_view_head">
      <el-tooltip
        :content="'Close'"
        popper-class="edoovo-table-cell-tooltip"
        :visible-arrow="false"
      >
        <img
          class="back"
         :src="require('@/assets/images/arrow_back-24px.png')"
          @click.stop="closeModal({ keyCode: 27 })"
        />
		 
      </el-tooltip>

      <img class="icon" src="./ImageIcon.svg" />
      {{ imgName }}
    </div>
    <div class="print-btn" @click.stop="newPageViewImg">
      <el-tooltip
        :content="'Print'"
        popper-class="edoovo-table-cell-tooltip"
        :visible-arrow="false"
      >
        <i class="iconfont iconprint-24px"></i>
      </el-tooltip>
    </div>
    <div class="gg_view_img">
      <img
        @click.stop="() => {}"
        :src="src"
        ref="img"
        @load="getSize"
        :style="{
          transform: `translate(-50%, -50%) rotate(${imgSize.rotate}deg) scale(${imgSize.scale})`,
        }"
      />
    </div>
    <ul class="gg_view_actions">
      <el-tooltip
        :content="'Rotate clockwise'"
        popper-class="edoovo-table-cell-tooltip"
        :visible-arrow="false"
      >
        <li class="iconfont iconrotate_right-24px" @click.stop="translate" />
      </el-tooltip>
      <el-tooltip
        :content="'Zoom out'"
        popper-class="edoovo-table-cell-tooltip"
        :visible-arrow="false"
      >
        <li
          :class="{ iconfont: true, 'iconzoom-out': true, disabled: imgSize.scale === 1 }"
          @click.stop="zoom('out')"
        />
      </el-tooltip>
      <el-tooltip
        :content="'Reset zoom'"
        popper-class="edoovo-table-cell-tooltip"
        :visible-arrow="false"
      >
        <li
          :class="{
            iconfont: true,
            'iconzoom-reset': true,
            disabled: imgSize.scale === 1 && !(this.imgSize.rotate % 360),
          }"
          @click.stop="zoom()"
        />
      </el-tooltip>
      <el-tooltip
        :content="'Zoom in'"
        popper-class="edoovo-table-cell-tooltip"
        :visible-arrow="false"
      >
        <li
          :class="{ iconfont: true, 'iconadd-24px5': true, disabled: imgSize.scale === 2 }"
          @click.stop="zoom('in')"
        />
      </el-tooltip>
    </ul>
  </div>
</template>

<script>
/**
 * @property @requires { boolean } visible 当前组件的显隐
 * @property @requires { string } src 需要显示图片的url地址
 * @property { string } name 需要显示图片的名称
 */
export default {
  // 组件名称
  name: 'GgViewImg',
  props: {
    visible: Boolean,
    src: String,
    name: String,
  },
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      imgSize: {
        width: 0,
        height: 0,
        rotate: 0,
        scale: 1,
      },
    };
  },
  // 计算属性
  computed: {
    /**
     * @description 获取图片名称，如果存在手动设定，则使用手动设定的名称显示
     * @returns { string } 返回显示的图片名称
     */
    imgName() {
      return this.name || src.match(/\/([^/]*)$/)[1];
    },
  },
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    newPageViewImg() {
      window.open(this.src);
    },
    /**
     * @description 关闭弹窗
     * @param { event } e 按键事件，可以为object模拟
     * @returns { void }
     */
    closeModal(e) {
      if (e.which === 27 || e.keyCode === 27) {
        this.$emit('update:visible', false);
      }
    },
    /**
     * @deprecated 获取图片的真实大小，方便进行放大缩小
     * @returns { void }
     */
    getSize() {
      this.imgSize.width = this.$refs.img.naturalWidth;
      this.imgSize.height = this.$refs.img.naturalHeight;
    },
    /**
     * @description 旋转图片
     * @returns { void }
     */
    translate() {
      this.imgSize.rotate += 90;
    },
    /**
     * @description 缩放图片
     * @param { out | in } type 缩放方式
     * @returns { void }
     */
    zoom(type) {
      if (type === 'out' && this.imgSize.scale > 1) {
        this.imgSize.scale = +(this.imgSize.scale - 0.2).toFixed(1);
      } else if (type === 'in' && this.imgSize.scale < 2) {
        this.imgSize.scale = +(this.imgSize.scale + 0.2).toFixed(1);
      } else if (!type && (this.imgSize.scale > 1 || this.imgSize.rotate % 360)) {
        this.imgSize.scale = 1;
        this.imgSize.rotate = 0;
      }
    },
  },
  /**
   * @description 初始化ESC事件，可以关闭弹窗
   * @returns { void }
   */
  created() {
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      document.attachEvent('onkeydown', this.closeModal);
    } else {
      document.addEventListener('keydown', this.closeModal, false);
    }
  },
  /**
   * @description 组件销毁之前移除代理在document上的esc事件
   * @returns { void }
   */
  beforeDestroy() {
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      document.detachEvent('onkeydown', this.closeModal);
    } else {
      document.removeEventListener('keydown', this.closeModal);
    }
  },
};
</script>

<style lang="less" scoped>
.gg_view {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.8);
  font-family: Roboto;
  &_head {
    height: 56px;
    line-height: 56px;
    color: #fff;
    padding: 0 20px;
    font-size: 14px;
    .back {
      cursor: pointer;
      vertical-align: middle;
      font-size: 24px;
    }
    .icon {
      width: 20px;
      margin-left: 20px;
      margin-right: 12px;
    }
  }
  &_img {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 72px;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
  &_actions {
    width: 160px;
    height: 40px;
    background: #000000;
    border-radius: 4px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    margin-left: -80px;
    display: flex;
    color: #fff;
    text-align: center;
    line-height: 40px;
    > li {
      flex: 1;
      width: 40px;
      height: 40px;
      font-size: 24px;
      cursor: pointer;
      &.iconrotate_right-24px {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }
      &.disabled {
        color: #7f7f7f;
        cursor: not-allowed;
      }
    }
  }
  .print-btn {
    position: absolute;
    z-index: 1;
    top: 16px;
    color: #fff;
    right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
    }
  }
}
</style>
