<script>
/**
 * 属性说明
 *
 * @property {Array<number>} pageSizes 每页条数可选项
 * @property {number} pageSize 每页多少条
 * @property {number} total 总条数
 * @property {number} currentPage 当前第几页 1 开始
 */

/**
 * 事件说明
 *
 * @currentChange 页数改变触发 参数当前页数 number
 * @sizeChange 每页多少条改变触发 参数 每页多少条 number
 */

export default {
  data() {
    return {
      currentPageSize: this.pageSize
    };
  },
  props: {
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50];
      }
    },
    showRow: {
      type: Boolean,
      default: true
    },
    pageSize: { type: Number },
    total: { type: Number, default: 0 },
    currentPage: { type: Number, default: 1 }
  },
  computed: {
    totalPages() {
      if (!this.pageSize) {
        return NaN;
      }
      return Math.ceil(this.total / this.pageSize);
    }
  },
  methods: {
    onPageChange(type) {
      if (type === 'first' && this.currentPage > 1) {
        this.$emit('current-change', 1);
      }

      if (type === 'prev' && this.currentPage > 1) {
        this.$emit('current-change', this.currentPage - 1);
      }

      if (type === 'next' && this.currentPage < this.totalPages) {
        this.$emit('current-change', this.currentPage + 1);
      }
    }
  },
  watch: {
    currentPageSize() {
      this.$emit('size-change', this.currentPageSize);
    }
  }
};
</script>

<template lang="pug">
.edoovo-pagination
  .edoovo-pagination-left(v-if="showRow")
    span Rows per page :
    md-field.edoovo-pagination-pagesize
      md-select(v-model="currentPageSize")
        md-option(v-for="size in pageSizes" :value="size" :key="size") {{ size }}
  .edoovo-pagination-left(v-else)
  .edoovo-pagination-right
    i.iconfont.iconfirst_page-px(:class="{ 'edoovo-pagination-icon-disabled': currentPage <= 1 }" @click="onPageChange('first')")
    span Page {{ currentPage }} of {{ totalPages }}
    i.iconfont.iconnavigate_before-px(:class="{ 'edoovo-pagination-icon-disabled': currentPage <= 1 }" @click="onPageChange('prev')")
    i.iconfont.iconnavigate_next-px(:class="{ 'edoovo-pagination-icon-disabled': currentPage >= totalPages }" @click="onPageChange('next')")
</template>

<style lang="stylus">
.edoovo-pagination
  height 24px
  display flex
  align-items center
  justify-content space-between
  font-size 14px
  color #5F6368
  width 100%
.edoovo-pagination-pagesize.md-field
  width 50px
  padding 0
  margin 0 0 0 30px
  font-size 14px
  color #5F6368
  &:after, &:before
    display none !important
  .md-select
    align-items center
  input.md-select-value
    font-size 14px !important
    color #5F6368 !important
.edoovo-pagination-left, .edoovo-pagination-right
  display flex
  align-items center
.edoovo-pagination-right
  .iconfont
    width 24px
    height 24px
    display flex
    align-items center
    justify-content center
    cursor pointer
    &.edoovo-pagination-icon-disabled
      opacity 0.5
      cursor not-allowed
  .iconfirst_page-px
    margin-right 22px
  .iconnavigate_before-px
    margin-left 20px
    margin-right 16px
</style>
