<template>
  <div class="ggPageTitle">
    <div class="ggPageTitle-left">
      <slot name="avatarHeader"></slot>
      <div v-if="userInfo" class="avatar">
        <div
          @click="
            () => {
				if(isUploadImg && editimg){
					 this.isImgCutShow = true;
				}
             
            }
          "
          class="avatar-uploader"
        >
          <head-img
            class="imgBox"
            :src="userInfo.avatar ? `${userInfo.avatar}` : ``"
            :firstName="userInfo.firstName"
            :lastName="userInfo.lastName"
            line-height="52"
          />
		   <span class="starImg"  v-if="userInfo.isMaster==1">
		                  <img :src="require('@/assets/images/star.png')" alt="" />
		                </span>
          <div class="iconBox" v-if="editimg">
            <i  class="material-icons iconfont iconadd_a_photo" />
          </div>
        </div>
      </div>
      <div v-if="squareInfo" class="avatar">
        <head-img
          class="imgBox squareBox"
          :src="squareInfo.avatar ? `${squareInfo.avatar}` : ``"
          :firstName="squareInfo.firstName"
          :lastName="squareInfo.lastName"
          line-height="52"
        />
      </div>
      <div class="titleInfo">
        <div class="title">{{ title }}<slot name="title-label"></slot></div>
        <div class="subTitle">
          <div v-if="subAvatarUserInfo" class="subAvatar">
            <head-img
              :src="
                subAvatarUserInfo.avatar
                  ? `${subAvatarUserInfo.avatar}`
                  : ``
              "
              :firstName="subAvatarUserInfo.firstName"
              :lastName="subAvatarUserInfo.lastName"
              line-height="32"
            />
          </div>
          <template v-if="subTitle1" class="text" :class="subTitleClassName1">
            <div>{{ subTitle1 }}</div>
            <slot name="subTitleLabel"></slot>
          </template>
        </div>
        <div v-if="subTitle2" class="subTitle" :class="subTitleClassName2">
          {{ subTitle2 }}
        </div>
        <slot name="info"></slot>
      </div>
    </div>
    <div class="ggPageTitle-right">
      <gg-menu-bar v-if="menuData.length">
        <i class="iconmore_vert-24px iconfont" />
        <template slot="menus">
			
          <gg-menu-bar-item
            v-for="(item, key) in menuData"
            @click="menuClick(item)"
            :key="key"
			 :disabled="item?item.isDisabled:false" v-if='!student || !item.isDisabled'
			 :class="{ borderTop: item=='Edit' || item.label=='Edit' }"
            >{{ typeof item === 'object' ? item.label : item }}</gg-menu-bar-item
          >
		 
		  <el-tooltip :visible-arrow="false" placement="bottom" popper-class="text"
		  	content="Student with connected family cannot be deleted"   v-else-if='student && item.isDisabled'>
		  <gg-menu-bar-item
		    @click="menuClick(item)"
		    :disabled="item?item.isDisabled:false"
		    >{{ typeof item === 'object' ? item.label : item }}</gg-menu-bar-item
		  >
		  </el-tooltip>
        </template>
		
      </gg-menu-bar>
    </div>
    <ImgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
  </div>
</template>

<script>
import ImgCutModal from "@/components/edoovo-components/imgCutModal";
import { Ajax } from "@/common";
// import { updateUserAvatar } from '@/service/dashboard';

/**
 * @prop { userInfo } Object  用户信息，用于头像编辑
 * @prop { subAvatarUserInfo } Object  subTitle1的头像
 * @prop { title } String  Page title 文字
 * @prop { subTitle1 } String  subTitle1 文字
 * @prop { subTitle2 } String  subTitle2 文字
 * @prop { menuData } Array  右侧下拉操作菜单列表数据
 */
export default {
  name: `GgPageHeader`,
  data() {
    return {
      isImgCutShow: false,
      timeStamp: new Date().getTime()
    };
  },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
    squareInfo: { type: Object, default: null },
    subAvatarUserInfo: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle1: {
      type: String,
      default: "",
    },
    subTitleClassName1: String,
    subTitleClassName2: String,
    subTitle2: {
      type: String,
      default: "",
    },
    menuData: {
      type: Array,
      default: () => [],
    },
	isUploadImg:{
		type:Boolean,
		default:true
	},
	student:{
		type:Boolean,
		default:false
	},
	editimg:{
		type:Boolean,
		default:true
	}
  },
  components: {
    ImgCutModal,
  },
  watch:{
    squareInfo(){
      console.log(1212)
    }
  },
  methods: {
    _uploadCallback(formData,fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          console.log(res);
          this.$emit("_uploadCallbackLogo", `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`);
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    menuClick(item) {
      this.$emit("menuCallback", item);
    },
  },
};
</script>

<style lang="less" scoped>
.ggPageTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  padding: 0 8px 24px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
  &:not(:first-child) {
    padding-top: 24px;
  }
  &-left {
    display: flex;
    .avatar {
      width: 52px;
      height: 52px;
      background-color: #efefef;
      border-radius: 50%;
      margin-right: 20px;
      .avatar-uploader {
        cursor: pointer;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: #445469;
        color: #fff;
        text-align: center;
        position: relative;
        .iconBox {
          width: 24px;
          height: 24px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 0;
          right: 0;
          .iconfont {
            font-size: 16px;
            color: rgba(95, 99, 104, 0.5);
          }
        }
        &:hover {
          .iconBox {
            .iconfont {
              color: rgba(95, 99, 104, 1);
            }
          }
        }
      }
    }
    .squareBox {
      border-radius: 8px;
    }
    .titleInfo {
      .title {
        display: flex;
        align-items: center;
        font-family: OpenSans;
        font-weight: bold;
        color: #202124;
        font-size: 22px;
        line-height: normal;
        margin-bottom: 4px;
      }
      .subTitle {
        font-family: Roboto;
        font-size: 14px;
        line-height: normal;
        color: #202124;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .greenTitle {
          color: #0b8043;
        }
        .redTitle {
          color: #ea4335;
        }
        .subAvatar {
          width: 32px;
          height: 32px;
          background-color: #efefef;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
  &-right {
  }
}
.borderTop{
	border-top:1px solid #e9e9e9
}
      .starImg {
        width: 17px;
        height: 16px;
        position: absolute;
        top: -2px;
        right: -5px;
        z-index: 9;
        img {
          width: 100%;
          height: 100%;
        }
      }
</style>
