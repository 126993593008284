<!-- dropdown-item -->
<template>
  <div :class="{ disabled }" @click="itemClick">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GgDropdownItem',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {
    itemClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    }
  }
};
</script>
<style lang="less" scoped></style>
