<template>
  <div class="phone">
    <svg class="iconfont flag show_phone" aria-hidden="true" v-show="selIcon">
      <use :xlink:href="'#icon' + selIcon" />
    </svg>
    <el-select
      v-model="countryCode"
      placeholder=" "
      :disabled="disabled"
      popper-class="code-class"
      :class="{ only_icon: onlyIcon }"
    >
      <el-option
        v-for="item in phoneCodeList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        class="phoneCodeListItem"
      >
        <div class="phoneCodeListItem-main">
          <svg class="iconfont flag" aria-hidden="true">
            <use :xlink:href="'#icon' + item.icon" />
          </svg>
          <span class="code-label">{{ item.label }}</span>
          <span class="code">{{ item.value }}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'PhoneCode',
  props: {
    value: { default: '', type: String },
    disabled: { default: false, type: Boolean },
    onlyIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    countryCode: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    selIcon() {
      const _sel = this.phoneCodeList.find((v) => v.value === this.countryCode);
      return _sel && _sel.icon;
    }
  },
  data() {
    return {
      phoneCodeList: [
        {
          value: '+65', // 新加坡
          label: 'Singapore',
          icon: 'icon_SGxinjiapo'
        },
        {
          value: '+86',
          label: 'China',
          icon: 'icon_CNzhongguo'
        },
        {
          value: '+852',
          label: 'Hong Kong',
          icon: 'hk'
        },
        {
          value: '+39',
          label: 'Italy',
          icon: 'icon_IT'
        }
      ]
    };
  },
  methods: {},
  components: {}
};
</script>

<style lang="less" scoped>
.el-select.only_icon {
  /deep/ .el-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    height: 32px;
    .el-input__inner {
      visibility: hidden;
    }
    .el-input__suffix {
      top: 0;
    }
  }
}
</style>

<style lang="less">
.code-class {
  height: 96px;
  overflow-y: auto;
  .popper__arrow {
    display: none !important;
  }
  box-shadow: 0px 3px 3px #0000004d !important;
  border: none !important;
  li + li {
    border-top: 1px solid #ebebeb;
  }
  .phoneCodeListItem {
    padding: 0;
    padding-top: 8px;
    height: 48px;
    width: 348px;
    &:hover {
      background-color: #fff !important;
    }
    &-main {
      height: 32px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      &:hover {
        background-color: #eeeeee;
      }
    }
    &.selected {
      .phoneCodeListItem-main {
        background-color: #eeeeee;
      }
    }
  }
}
</style>

<style scoped>
.code-label {
  color: #202124 !important;
  margin-right: 12px;
  margin-left: 12px;
  font-family: Roboto;
  font-weight: normal;
}
.code {
  color: #5f6368 !important;
  font-family: Roboto;
  font-weight: normal;
}
.phone {
  margin-bottom: -20px;
  position: relative;
  display: inline-block;
  height: 32px;
}
.phone >>> .show_phone {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 16px;
}
.phone .el-select >>> .el-input__inner {
  line-height: 39px;
  border: none;
  border-bottom: 1px solid rgba(68, 84, 105, 0.2);
  border-radius: 0;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 400;
  padding: 0;
  color: rgba(68, 84, 105, 1);
  background: transparent;
  padding-left: 35px;
}
.phone >>> .el-input__suffix {
  top: -5px;
}
</style>
