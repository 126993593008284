<script>
export default {
  name: `BaseCheckbox`,
  functional: true,
  render(createElement, context) {
    return createElement(
      `el-checkbox`,
      {
        directives: context.data.directives,
        on: context.data.on,
        props: {
          size: 'medium',
          ...context.props
        },
        class: (context.data.staticClass || '') + ' base_checkbox',
        style: {
          width: context.props.width,
          ...context.data.staticStyle
        }
      },
      [
        context.slots().default //  also pass default slot to child
      ]
    );
  }
};
</script>

<style scoped lang="less">
.base_checkbox {
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    // color: rgb(0, 162, 129);
    background: #448aff;
	color: #448aff;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    // color: rgb(0, 162, 129);
  }
  /deep/ .el-checkbox__label {
    font-size: 16px;
  }
}
</style>
