


import { Ajax } from "@/common";
const moments = {
  state: {
    pendingCount: 0,
    pendingCountStudent: 0,
    curClassName: '',
    curStudentName: '',
    curStudent: {
      title: "",
      avatar: "",
      firstName: "",
      lastName: "",
      subTitle1: "",
      status: "",
    }
  },

  mutations: {
    SET_PENDINGCOUNT: (state, count) => {
      state.pendingCount = count
    },
    SET_PENDINGCOUNTSTUDENT: (state, count) => {
      state.pendingCountStudent = count
    },
    SET_CURCLASSNAME: (state, name) => {
      state.curClassName = name
    },
    SET_CURSTUDENTNAME: (state, name) => {
      state.curStudentName = name
    },
    SET_CURSTUDENT: (state, obj) => {
      state.curStudent = obj
    }
  },

  actions: {
    // 登录
    fetchPendingData({ commit }, obj) {
      return new Promise((resolve, reject) => {
        Ajax.post("/moments/media/getMediaCountV1", {
          classId: obj.classId,
          status:1,
          // date: obj.date.format('YYYY-MM')
        }).then(res => {
          if(res.code == '0000'){
            commit('SET_PENDINGCOUNT', res.data>9999?9999:res.data)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
        
      })
    },
    fetchPendingDataStudent({ commit }, obj) {
      return new Promise((resolve, reject) => {
        Ajax.post("/moments/media/getMediaCountV1", {
          studentId: obj.studentId,
          classId: obj.classId,
          status:1,
          // date: obj.date.format('YYYY-MM')
        }).then(res => {
          if(res.code == '0000'){
            commit('SET_PENDINGCOUNTSTUDENT', res.data>9999?9999:res.data)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
        
      })
    },
  }
}

export default moments
