<!-- user menus -->
<template>
  <div class="menus">
    <div
      :class="{ menu_box: true, active: item.active }"
      v-for="item in showMenus"
      :key="item.name"
    >
      <div
        @click="itemClick(item)"
        :class="{
          menu_item: true,
          selected: defaultActive === item.key,
          has_extra: item.extra>0
        }"
        :style="{
          paddingLeft: (hasChildren || item.icon || item.menuIcon ? space : space + 15) + 'px'
        }"
      >
        
        <img
          class="menuIcon"
          v-if="item.menuIcon"
          :src="require(`@/assets/images/menu/${item.menuIcon}.svg`)"
          alt=""
        />

        <i
          class="iconfont iconarrow_drop_down-24px"
          v-if="hasChildren || item.icon"
          @click.stop="iconClick(item)"
        />{{ item.name }}

        <span v-if="item.extra>0" class="extra">{{item.extra}}</span>
      </div>
      <!-- 递归组件，如果存在chilren，则继续创建子组件 -->
      <el-collapse-transition v-if="item.children">
        <gg-flex-menus
          v-show="item.active"
          :menus="item.children"
          :default-active="defaultActive"
          :space="space + 44"
          @select="selectChange"
        />
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
/**
 * @property { number } menus 菜单数组
 * @property { string } defaultActive 默认激活菜单
 * @property { number } space 默认左侧padding
 */
export default {
  name: 'GgFlexMenus',
  props: {
    menus: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultActive: {
      type: String,
      default: ''
    },
    space: {
      type: Number,
      default: 24
    }
  },
  data() {
    return {
      showMenus: []
    };
  },
  components: {},
  computed: {
    hasChildren() {
      return this.showMenus.some((v) => v.children);
    }
  },
  created() {
    this.showMenus = JSON.parse(JSON.stringify(this.menus)).map((v) => ({
      active: false,
      ...v
    }));
  },
  watch: {
    menus: {
      handler(newValue) {
        this.showMenus = JSON.parse(JSON.stringify(newValue)).map((v, i) => ({
          active: i ? false : true,
          ...this.showMenus[i],
          ...v
        }));
      },
      deep: true
    }
  },
  methods: {
    /**
     * @description icon点击
     * @param { object } item 点击icon执行收起展开
     * @returns { void }
     */
    iconClick(item) {
      // if (item.children) {
      this.toogleList(item);
      // }
    },
    /**
     * @description 菜单点击
     * @param { object } item 单击的菜单，如果存在key，则进行处理
     * @returns { void }
     */
    itemClick(item) {
      if (item.key !== undefined && item.key !== null) {
        this.selectChange(item.key, item);
        return;
      }
      if (item.children) {
        this.toogleList(item);
      }
    },
    /**
     * menu list 收起/展开
     * @param { object } item 触发的item
     * @returns { void }
     */
    toogleList(item) {
      item.active = !item.active;
    },
    /**
     * select change触发
     * @param { string } key 修改的key
     * @param { object } item 修改的item
     * @returns { void }
     */
    selectChange(key, item) {
      this.$emit('select', key, item);
    }
  }
};
</script>
<style lang="less" scoped>
.menus {
  width: 100%;
  overflow: hidden;
  .menu_box {
    .menu_item {
      font-size: 14px;
      color: #5f6368;
      height: 40px;
      line-height: 40px;
      font-family: OpenSans-Bold;
      // padding-left: 68px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 24px;
      &.has_extra{
        position: relative;
        .extra{
          position: absolute;
          right:5px;
          top:0;
          color:#5F6368;
          font-size:12px;
        }
      }
      .menuIcon {
        display: inline-block;
        width: 24px;
        max-height: 24px;
        vertical-align: middle;
        margin-right: 20px;
        font-size: 24px;
        line-height: 24px;
      }
      .iconfont {
        display: inline-block;
        font-size: 24px;
        vertical-align: middle;
        margin: 8px 20px 8px 0;
        font-size: 24px;
        line-height: 24px;
        transform: rotate(-90deg);
        transition: transform 0.3s;
      }
      &:hover {
        background: #f2f2f2;
      }
      &.selected {
        background: #e8f0fe;
        color: #1a73e8;
      }
    }
    &.active {
      > .menu_item {
        > .iconfont {
          transform: rotate(0deg);
        }
      }
    }
  }
}
</style>
