import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/index',
        meta: {
            login: false
        }
    },
	// 开发过程中加进来的 index
	{
	    path: '/index',
	    name: 'index',
	    component: () => import(/* webpackChunkName: "index" */ '@/views/index.vue')
	},
    // PrivacyPolicy页面 PrivacyPolicy
    {
	    path: '/PrivacyPolicy',
	    name: 'PrivacyPolicy',
	    component: () => import(/* webpackChunkName: "index" */ '@/views/PrivacyPolicy.vue')
	},
	// index结束
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
    },
    {
        path: '/forgotPwd',
        name: 'forgotPwd',
        component: () => import(/* webpackChunkName: "forgotPwd" */ '@/views/ForgotPwd.vue')
    },{
        path: '/dashBoard',
        name: 'home',
        component: () => import(/* webpackChunkName: "dashBoard" */ '@/views/DashBoard.vue'),
        meta: {
            pageType: '4',
            pageTitle: 'DashBoard',
            login: true
        }
    }, {
        path: '/locations',
        name: 'locations',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations.vue'),
        meta: {
            pageType: '4',
            pageTitle: 'Locations',
            login: true
        }
    }, {
        path: '/adminConsole',
        name: 'adminConsole',
        component: () => import(/* webpackChunkName: "adminConsole" */ '@/views/adminConsole'),
        meta: {
            pageType: '4',
            pageTitle: 'Admin console',
            login: true
        }
    }, {
        path: '/users', //setting-users
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/adminConsole/users'),
        meta: {
            pageType: '2',
            pageTitle: 'Users',
            login: true
        }
    },{
        path: '/index3', //setting-users
        name: 'index3',
        component: () => import(/* webpackChunkName: "users" */ '@/views/adminConsole/users/index3'),
        meta: {
            pageType: '2',
            pageTitle: 'index3',
            login: true
        }
    }, {
        path: '/usersInfo', //setting-users-personalInfo
        name: 'usersInfo',
        component: () => import(/* webpackChunkName: "usersInfo" */ '@/views/adminConsole/users/usersInfo.vue'),
        meta: {
            pageType: '2',
            pageTitle: 'PersonalInfo',
            login: true
        }
    }, {
        path: '/userRoles', //setting-userRoles
        name: 'userRoles',
        component: () => import(/* webpackChunkName: "userRoles" */ '@/views/adminConsole/users/userRoles.vue'),
        meta: {
            pageType: '2',
            pageTitle: 'userRoles',
            login: true
        }
    }, {
        path: "/settings",
        name: "settings",
        redirect: '/settings/general',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/adminConsole/settings/subLayout.vue'),
        children: [{
            path: "general",
            name: "settingGeneral",
            component: () => import(/* webpackChunkName: "general" */ '@/views/adminConsole/settings/general.vue'),
            meta: {
                login: true,
                pageTitle: "General",
                breadcrumbType: "0",
                activeIndex: "settingGeneral",
            },
        }, {
            path: "companyInfo",
            name: "settingCompanyInfo",
            component: () => import(/* webpackChunkName: "settingCompanyInfo" */ '@/views/adminConsole/settings/companyInfo.vue'),
            meta: {
                login: true,
                pageTitle: "Company info",
                activeIndex: "settingGeneral",
            },
        }, {
            path: "users",
            name: "settingUsers",
            component: () => import(/* webpackChunkName: "settingUsers" */ '@/views/adminConsole/settings/users.vue'),
            meta: {
                login: true,
                pageTitle: "Users",
                breadcrumbType: "0",
                activeIndex: "settingUsers",
            },
        }, {
            path: "companies",
            name: "settingCompanies",
            component: () => import(/* webpackChunkName: "settingCompanies" */ '@/views/adminConsole/settings/companies.vue'),
            meta: {
                login: true,
                pageTitle: "Companies",
                breadcrumbType: "0",
                activeIndex: "settingCompanies",
            },
        }, {
            path: "staff",
            name: "settingStaff",
            component: () => import(/* webpackChunkName: "settingStaff" */ '@/views/adminConsole/settings/staff.vue'),
            meta: {
                login: true,
                pageTitle: "Staff",
                breadcrumbType: "0",
                activeIndex: "settingStaff"
            },
        },  {
            path: "students",
            name: "settingStudents",
            component: () => import(/* webpackChunkName: "settingStudents" */ '@/views/adminConsole/settings/students.vue'),
            meta: {
                login: true,
                pageTitle: "Students",
                breadcrumbType: "0",
                activeIndex: "settingStudents"
            },
        }, {
            path: "studentId",
            name: "settingStudentId",
            component: () => import(/* webpackChunkName: "settingStudentId" */ '@/views/adminConsole/settings/studentId.vue'),
            meta: {
                login: true,
                pageTitle: "Student ID",
                activeIndex: "settingStudents",
            },
        }, {
            path: "locations",
            name: "settingLocations",
            component: () => import(/* webpackChunkName: "settingLocations" */ '@/views/adminConsole/settings/locations.vue'),
            meta: {
                login: true,
                pageTitle: "Locations",
                breadcrumbType: "0",
                activeIndex: "settingLocations",
            },
        },
        {
            path: "locationInfo",
            name: "settingLocationInfo",
            component: () => import(/* webpackChunkName: "settingLocationInfo" */ '@/views/adminConsole/settings/locationInfo.vue'),
            meta: {
                login: true,
                pageTitle: "Locations",
                activeIndex: "settingLocations",
            },
        },
        {
            path: "classes",
            name: "settingClasses",
            component: () => import(/* webpackChunkName: "settingClasses" */ '@/views/adminConsole/settings/classes.vue'),
            meta: {
                login: true,
                pageTitle: "Classes",
                breadcrumbType: "0",
                activeIndex: "settingClasses",
            },
        }, {
            path: "attendance",
            name: "settingAttendance",
            component: () => import(/* webpackChunkName: "settingAttendance" */ '@/views/adminConsole/settings/attendance.vue'),
            meta: {
                login: true,
                pageTitle: "Attendance",
                breadcrumbType: "0",
                activeIndex: "settingAttendance",
            },
        }, {
            path: "attendanceInfo",
            name: "settingAttendanceInfo",
            component: () => import(/* webpackChunkName: "settingAttendanceInfo" */ '@/views/adminConsole/settings/attendanceInfo.vue'),
            meta: {
                login: true,
                pageTitle: "Attendance",
                activeIndex: "settingAttendance",
            },
        }, {
            path: "moments",
            name: "settingMoments",
            component: () => import(/* webpackChunkName: "settingMoments" */ '@/views/adminConsole/settings/moments.vue'),
            meta: {
                login: true,
                pageTitle: "Attendance",
                breadcrumbType: "0",
                activeIndex: "settingMoments",
            },
        }, {
            path: "commbook",
            name: "settingCommbook",
            component: () => import(/* webpackChunkName: "settingCommbook" */ '@/views/adminConsole/settings/commbook.vue'),
            meta: {
                login: true,
                pageTitle: "Commbook",
                breadcrumbType: "0",
                activeIndex: "settingCommbook",
            },
        }, {
            path: "tablePage",
            name: "tablePage",
            component: () => import(/* webpackChunkName: "tablePage" */ '@/views/adminConsole/settings/tablePage.vue'),
            meta: {
                login: true,
                activeIndex: "",
            },
        }, {
            path: "tablePageLocations",
            name: "tablePageLocations",
            component: () => import(/* webpackChunkName: "tablePageLocations" */ '@/views/adminConsole/settings/tablePageLocations.vue'),
            meta: {
                login: true,
                activeIndex: "",
            },
        },]
    },
    {
        path: '/companyRoles', //admincolse-company-role
        name: 'companyRoles',
        component: () => import(/* webpackChunkName: "companyRoles" */ '@/views/adminConsole/roles'),
        meta: {
            login: true
        }
    },
    {
        path: '/createRoles', //admincolse-company-role
        name: 'createRoles',
        component: () => import(/* webpackChunkName: "companyRoles" */ '@/views/adminConsole/roles/createRoles'),
        meta: {
            login: true
        }
    },
    {
        path: '/editRoles', //admincolse-company-role
        name: 'editRoles',
        component: () => import(/* webpackChunkName: "editRoles" */ '@/views/adminConsole/roles/editRoles'),
        meta: {
            login: true
        }
    },

    {
        path: '/companies', //companies
        name: 'companies',
        component: () => import(/* webpackChunkName: "companies" */ '@/views/companies'),
        meta: {
            pageTitle: 'Companies',
            login: true
        }
    }, {
        path: '/companies/companyInfo', //companyInfo
        name: 'companyInfo',
        component: () => import(/* webpackChunkName: "companyInfo" */ '@/views/companies/companyInfo'),
        meta: {
            pageTitle: 'Companies',
            login: true
        }
    }, {
        path: '/parents',
        name: 'parents',
        component: () => import(/* webpackChunkName: "parents" */ '@/views/parents'),
        meta: {
            pageType: '4',
            pageTitle: 'Parents',
            login: true
        }
    },
    {
        path: '/parentsDetail',
        name: 'parentsDetail',
        component: () => import(/* webpackChunkName: "parentsDetail" */ '@/views/subLayout.vue'),
        meta: {
            pageType: '4',
            pageTitle: 'Parents',
            login: true,
            menuDatas: [{
                name: "Parent info",
                key: "parentsInfo",
                menuIcon: "",
                children: []
            }, {
                name: "Connection",
                key: "connections",
                menuIcon: "",
                children: []
            }]
        },
        children: [{
            path: 'parentsInfo',
            name: 'parentsInfo',
            component: () => import(/* webpackChunkName: "parentsInfo" */ '@/views/parents/parentsInfo.vue'),
            meta: {
                pageType: '4',
                pageTitle: 'Parents',
                login: true
            }
        }, {
            path: 'connections',
            name: 'connections',
            component: () => import(/* webpackChunkName: "connections" */ '@/views/parents/connections.vue'),
            meta: {
                pageType: '4',
                pageTitle: 'Parents',
                login: true,
                menuDatas: [{
                    name: "Parent info",
                    key: "parentsInfo",
                    menuIcon: "",
                    children: []
                }, {
                    name: "Connection",
                    key: "connections",
                    menuIcon: "",
                    children: []
                }]
            }
        }]
    }, {
        path: "/classes",
        name: "classes",
        component: () => import(/* webpackChunkName: "classes" */ '@/views/classes'),
        meta: {
            login: true
        },
    }, {
        path: "/classDetail",
        name: "classDetail",
        redirect: '/classes',
        component: () => import(/* webpackChunkName: "classDetail" */ '@/views/classes/subLayout'),
        meta: {
            login: true,
            pageTitle: 'Classes'
        },
        children: [
            {
                path: "className", // 班级名
                name: "classDetailInfo",
                component: () => import(/* webpackChunkName: "teachers" */ '@/views/classes/classDetail'),
                meta: {
                    login: true,
                    pageTitle: 'Classes'
                },
            },
            {
                path: "teachers",
                name: "classTeachers",
                component: () => import(/* webpackChunkName: "teachers" */ '@/views/classes/teachers'),
                meta: {
                    login: true,
                    pageTitle: 'Classes'
                },
            },
            {
                path: "students",
                name: "classStudents",
                component: () => import(/* webpackChunkName: "students" */ '@/views/classes/students'),
                meta: {
                    login: true,
                    pageTitle: 'Classes'
                },
            },
			{
			    path: "studentAttendance",
			    name: "classAttendance",
			    component: () => import(/* webpackChunkName: "students" */ '@/views/classes/studentAttendance'),
			    meta: {
			        login: true,
			        pageTitle: 'Classes'
			    },
			}
        ]
    }, {
        path: "/staff", // alone
        name: "staff",
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff'),
        meta: {
            login: true
        },
    }, {
        path: "/staffInfo",
        name: "staffInfo",
        redirect: '/staffInfo/personalInfo',
        component: () => import(/* webpackChunkName: "staffInfo" */ '@/views/staff/subLayout'),
        children: [{
            path: "personalInfo",
            name: "staffPersonalInfo",
            component: () => import(/* webpackChunkName: "personalInfo" */ '@/views/staff/personalInfo'),
            meta: {
                login: true,
                pageTitle: "Staff",
                menuDatas: [{
                    name: "Personal info",
                    key: "staffPersonalInfo",
                    menuIcon: ""
                }, {
                    name: "Privileges",
                    key: "staffPrivileges",
                    menuIcon: ""
                }]
            },
        }, {
            path: "Privileges",
            name: "staffPrivileges",
            component: () => import(/* webpackChunkName: "personalInfo" */ '@/views/staff/privileges'),
            meta: {
                login: true,
                pageTitle: "Staff",
                menuDatas: [{
                    name: "Personal info",
                    key: "staffPersonalInfo",
                    menuIcon: ""
                }, {
                    name: "Privileges",
                    key: "staffPrivileges",
                    menuIcon: ""
                }]
            },
        }]
    }, {
        path: "/people",
        name: "people",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/people'),
        meta: {
            login: true
        },
    }, {
        path: "/moments",
        name: "moments",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/moments'),
        meta: {
            login: true
        },
    }, {
      path: "/moment/search",
      name: "momentsSearch",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/searchRes'),
      meta: {
          login: true
      },
  },{
        path: "/moment/class/:id",
        name: "momentClass",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/index'),
        meta: {
            login: true
        },
    }, {
      path: "/moment/class/:id/photos",
      name: "momentClassPhotos",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/photos'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:id/uploaded",
      name: "momentClassUploaded",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/uploaded'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:id/pending",
      name: "momentClassPending",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/pending'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:id/shared",
      name: "momentClassShared",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/shared'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:id/album",
      name: "momentClassAlbum",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/album/index'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:id/album/:aid",
      name: "momentClassAlbumPhotos",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/album/photos'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:id/trash",
      name: "momentClassTrash",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/class/trash'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:cid/student/:id",
      name: "momentStudentIndex",
     redirect: "/moment/class/:cid/student/:id/uploaded",
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:cid/student/:id/photos",
      name: "momentStudentPhotos",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/student/photos'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:cid/student/:id/uploaded",
      name: "momentStudentUploaded",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/student/uploaded'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:cid/student/:id/pending",
      name: "momentStudentPending",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/student/pending'),
      meta: {
          login: true
      },
    }, {
      path: "/moment/class/:cid/student/:id/shared",
      name: "momentStudentShared",
      component: () => import(/* webpackChunkName: "moments" */ '@/views/moments/student/shared'),
      meta: {
          login: true
      },
    }, 
    {
        path: "/students",
        name: "students",
        component: () => import(/* webpackChunkName: "students" */ '@/views/students'),
        meta: {
            login: true
        }
    },{
        path: "/studentsInfo",
        name: "studentsInfo",
        redirect: '/studentsInfo/personalInfo',
        component: () => import(/* webpackChunkName: "studentsInfo" */ '@/views/students/subLayout'),
        children: [{
            path: "personalInfo",
            name: "studentsPersonalInfo",
            component: () => import(/* webpackChunkName: "personalInfo" */ '@/views/students/personalInfo'),
            meta: {
                login: true,
                pageTitle: "Students",
                menuDatas: [{
                    name: "Student info",
                    key: "studentsPersonalInfo",
                    menuIcon: ""
                }, {
                    name: "Contacts",
                    key: "contacts",
                    menuIcon: ""
                }, {
                    name: "Enrollment info",
                    key: "enrollmentInfo",
                    menuIcon: ""
                }
                ,
                {
                    name: "Attendance",
                    key: "studentAttendance",
                    menuIcon: ""
                }
            ]
            },
        }, {
            path: "contacts",
            name: "contacts",
            component: () => import(/* webpackChunkName: "contacts" */ '@/views/students/contacts'),
            meta: {
                login: true,
                pageTitle: "Students",
                menuDatas: [{
                    name: "Personal info",
                    key: "studentsPersonalInfo",
                    menuIcon: ""
                }, {
                    name: "Contacts",
                    key: "contacts",
                    menuIcon: ""
                }, {
                    name: "Enrollment info",
                    key: "enrollmentInfo",
                    menuIcon: ""
                }
                ,
                {
                    name: "Attendance",
                    key: "studentAttendance",
                    menuIcon: ""
                }
            ]
            },
        }
        , {
            path: "enrollmentInfo",
            name: "enrollmentInfo",
            component: () => import(/* webpackChunkName: "enrollmentInfo" */ '@/views/students/enrollmentInfo'),
            meta: {
                login: true,
                pageTitle: "Students",
                menuDatas: [{
                    name: "Personal info",
                    key: "studentsPersonalInfo",
                    menuIcon: ""
                }, {
                    name: "Contacts",
                    key: "contacts",
                    menuIcon: ""
                }, {
                    name: "Enrollment info",
                    key: "enrollmentInfo",
                    menuIcon: ""
                }
                ,
                {
                    name: "Attendance",
                    key: "studentAttendance",
                    menuIcon: ""
                }
            ]
            },
        }
        , {
            path: "studentAttendance",
            name: "studentAttendance",
            component: () => import(/* webpackChunkName: "enrollmentInfo" */ '@/views/students/studentAttendance'),
            meta: {
                login: true,
                pageTitle: "Students",
                menuDatas: [{
                    name: "Personal info",
                    key: "studentsPersonalInfo",
                    menuIcon: ""
                }, {
                    name: "Contacts",
                    key: "contacts",
                    menuIcon: ""
                }, {
                    name: "Enrollment info",
                    key: "enrollmentInfo",
                    menuIcon: ""
                },
                {
                    name: "Attendance",
                    key: "studentAttendance",
                    menuIcon: ""
                }
            ]
            },
        }
    ]
    }, 
	{
		    path: "/peopleInfo",
		    name: "peopleInfo",
		    redirect: '/peopleInfo/peopleStudentsPersonalInfo',
		    component: () => import(/* webpackChunkName: "studentsInfo" */ '@/views/people/peopleSubLayout'),
		    children: [{
		        path: "peopleStudentsPersonalInfo",
		        name: "peopleStudentsPersonalInfo",
		        component: () => import(/* webpackChunkName: "personalInfo" */ '@/views/people/studentPersonalInfo'),
		        meta: {
		            login: true,
		            pageTitle: "people",
		            menuDatas: [{
		                name: "Student info",
		                key: "peopleStudentsPersonalInfo",
		                menuIcon: ""
		            }, {
		                name: "Contacts",
		                key: "peopleContacts",
		                menuIcon: ""
		            }, {
		                name: "Enrollment info",
		                key: "studentEnrollmentInfo",
		                menuIcon: ""
		            }]
		        },
		    }, {
		        path: "peopleContacts",
		        name: "peopleContacts",
		        component: () => import(/* webpackChunkName: "contacts" */ '@/views/people/peopleContacts'),
		        meta: {
		            login: true,
		            pageTitle: "Students",
		            menuDatas: [{
		                name: "Student info",
		                key: "peopleStudentsPersonalInfo",
		                menuIcon: ""
		            }, {
		                name: "Contacts",
		                key: "peopleContacts",
		                menuIcon: ""
		            }, {
		                name: "Enrollment info",
		                key: "studentEnrollmentInfo",
		                menuIcon: ""
		            }]
		        },
		    }, {
		        path: "studentEnrollmentInfo",
		        name: "studentEnrollmentInfo",
		        component: () => import(/* webpackChunkName: "enrollmentInfo" */ '@/views/people/studentEnrollmentInfo'),
		        meta: {
	menuDatas: [{
		                name: "Student info",
		                key: "peopleStudentsPersonalInfo",
		                menuIcon: ""
		            }, {
		                name: "Contacts",
		                key: "peopleContacts",
		                menuIcon: ""
		            }, {
		                name: "Enrollment info",
		                key: "studentEnrollmentInfo",
		                menuIcon: ""
		            }]
		        },
		    }
			
			
			
			]
		},
	{
        path: "/parentPersonalInfo", //parentPersonalInfo
        name: "parentPersonalInfo",
        component: () => import(/* webpackChunkName: "parentPersonalInfo" */ '@/views/students/parentPersonalInfo'),
        meta: {
            login: true,
            pageTitle: "Students",

        },
    },

	 {
	        path: "/peopleParentPersonalInfo", //parentPersonalInfo
	        name: "peopleParentPersonalInfo",
	        component: () => import(/* webpackChunkName: "parentPersonalInfo" */ '@/views/students/peopleParentPersonalInfo'),
	        meta: {
	            login: true,
	            pageTitle: "People",
	
	        },
	    },

	{
        path: "/attendance",
        name: "attendance",
        component: () => import(/* webpackChunkName: "attendance" */ '@/views/attendance'),
        meta: {
            login: true
        }
    }, {
        path: "/attendance/student",
        name: "attendanceStudent",
        component: () => import(/* webpackChunkName: "attendance" */ '@/views/attendance/student'),
        meta: {
            login: true
        }
    }, {
        path: "/attendance/student/timeline",
        name: "attendanceStudentTimeline",
        component: () => import(/* webpackChunkName: "attendance" */ '@/views/attendance/timeline'),
        meta: {
            login: true
        }
    }, {
        path: "/commbook",
        name: "commbook",
        component: () => import(/* webpackChunkName: "commbook" */ '@/views/commbook'),
        meta: {
            login: true
        }
    }, {
        path: "/commbook/student",
        name: "commbookStudent",
        component: () => import(/* webpackChunkName: "commbook" */ '@/views/commbook/specific-student'),
        meta: {
            login: true
        }
    }, 
    {
        path: "/announcement",
        name: "announcement",
        component: () => import(/* webpackChunkName: "announcements" */ '@/views/announcement'),
        meta: {
            login: true
        }
    },
    {
        path: "/announcement/posted",
        name: "announcementPosted",
        component: () => import(/* webpackChunkName: "announcements" */ '@/views/announcement/posted'),
        meta: {
            login: true
        }
    },
    {
        path: "/announcement/archived",
        name: "announcementArchived",
        component: () => import(/* webpackChunkName: "announcements" */ '@/views/announcement/archived'),
        meta: {
            login: true
        }
    },
    {
        path: "/announcement/create",
        name: "announcementCreate",
        component: () => import(/* webpackChunkName: "announcements" */ '@/views/announcement/create'),
        meta: {
            login: true
        }
    },
    {
        path: "/announcement/search",
        name: "announcementSearch",
        component: () => import(/* webpackChunkName: "announcements" */ '@/views/announcement/search'),
        meta: {
            login: true
        }
    },
    {
        path: "/announcement/draft/:id",
        name: "announcementDraftDetail",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/announcement/detail/draft'),
        meta: {
            login: true
        },
    },
    {
        path: "/announcement/posted/:id",
        name: "announcementPostedDetail",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/announcement/detail/posted'),
        meta: {
            login: true
        },
    },
    {
        path: "/announcement/archived/:id",
        name: "announcementArchivedDetail",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/announcement/detail/archived'),
        meta: {
            login: true
        },
    },
    {
        path: '/testFileView',
        name: 'testFileView',
        component: (resolve) => require(['@/components/edoovo-components/baseFileView'], resolve),
        meta: {
            login: true
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '@/views/account'),
        meta: {
            login: true
        }
    },
    {
        path: '/settingAccount',
        name: 'settingAccount',
        component: () => import(/* webpackChunkName: "account" */ '@/views/account/settingAccount'),
        meta: {
            login: true
        }
    },
    {
        path: '/security',
        name: 'security',
        component: () => import(/* webpackChunkName: "account" */ '@/views/account/security'),
        meta: {
            login: true
        }
    },
    {
        path: '/accountPwd',
        name: 'accountPwd',
        component: () => import(/* webpackChunkName: "account" */ '@/views/account/accountPwd'),
        meta: {
            login: true
        }
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/account/resetPassword.vue'),
        meta: {
            // login: true
        }
    }, 
    {
        path: "/journal",
        name: "journal",
        component: () => import(/* webpackChunkName: "announcements" */ '@/views/journal'),
        meta: {
            login: true
        }
    },
    {
        path: "/journal/class/:id",
        name: "journalClass",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/class/index'),
        meta: {
            login: true
        },
    },
    {
        path: "/journal/create",
        name: "journalCreate",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/create/index'),
        meta: {
            login: true
        },
    },
    {
        path: "/journal/draft",
        name: "journalDraft",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/draft/index'),
        meta: {
            login: true
        },
    },
    {
        path: "/journal/search",
        name: "journalSearch",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/search/index'),
        meta: {
            login: true
        },
    },
    {
        path: "/journal/view",
        name: "journalView",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/view/index'),
        meta: {
            login: true
        },
    },
    {
        path: "/journal/temp",
        name: "journalTemp",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/temp'),
        meta: {
            login: true
        },
    },
    {
        path: "/journal/tempdraft",
        name: "journalTempDraft",
        component: () => import(/* webpackChunkName: "moments" */ '@/views/journal/tempdraft'),
        meta: {
            login: true
        },
    },
	{
	    path: "/studentsInfo/timeline",
	    name: "timeline",
	    component: () => import(/* webpackChunkName: "attendance" */ '@/views/students/timeline'),
	    meta: {
	        login: true
	    }
	},
	,
	{
	    path: "/classInfo/timeline",
	    name: "classTimeline",
	    component: () => import(/* webpackChunkName: "attendance" */ '@/views/classes/timeline'),
	    meta: {
	        login: true
	    }
	}
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
let a = location.href	
	if(a.indexOf('PrivacyPolicy')!=-1){
		console.log(a.indexOf('PrivacyPolicy'))
		window.location.href='https://www.classnovo.com/#/PrivacyPolicy'
	}else{
		// console.log(123)
	}
    if (to.matched.some((item) => item.meta.login)) {
        let login = sessionStorage.getItem("login")
        if (login) {
            next();
        } else {
            if (to.path === '/login' || to.path === '/forgotPwd') {
                next();
            } else {
                next({path: '/login'})
            }
        }
    } else {
        next()
    }

})

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
