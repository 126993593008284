<script>
export default {
  name: `GgSwitch`,
  functional: true,
  render(createElement, context) {
    return createElement(
      `el-switch`,
      {
        props: {
          width: 20,
          ...context.props
        },
        directives: context.data.directives,
        on: context.data.on,
        class: (context.data.staticClass || '') + 'gg_switch',
        style: {
          ...context.data.staticStyle
        }
      },
      [
        context.slots().default //  also pass default slot to child
      ]
    );
  }
};
</script>

<style lang="less" scoped>
.gg_switch {
  width: 24px;
  height: 24px;
  padding: 0 2px;
  cursor: pointer;
  /deep/ .el-switch__core {
    height: 10px;
    &::after {
      width: 6px;
      height: 6px;
    }
  }
  &.is-checked {
    /deep/ .el-switch__core::after {
      margin-left: -7px;
    }
  }
}
</style>
