export const menuIconParams = {
    Users: {
      svg: 'users'
    },
    Staff: {
      svg: 'staff'
    },
    Companies: {
      svg: 'companies'
    },
    'Admin Console': {
      svg: 'adminconsole'
    },
    Settings: {
      svg: 'settings'
    },
    Classes: {
      svg: 'classes'
    },
    Parents: {
      svg: 'parents'
    },
    People: {
      svg: 'people'
    },
    Students: {
      svg: 'students'
    },
    Teachers: {
      svg: 'teachers'
    },
    Products: {
      svg: 'product_app'
    },    
    Moments: {
      svg: 'moments'
    },
    Attendance: {
      svg: 'attendance'
    },
    Announcements: {
      svg: 'announcement'
    },
    Commbook: {
      svg: 'commbook'
    },
    Locations: {
      svg: 'locations'
    },
    'Preschool World': {
      svg: 'PerschoolWorld'
    },
    'My Children': {
      svg: 'mychildren'
    },
    Calendar: {
      svg: 'Calendar'
    },
    Class: {
      svg: 'classes_app'
    },
    Curricula: {
      svg: 'curriculum'
    },
    'Quiz Bank': {
      svg: 'quizBank'
    },
    Orders: {
      svg: 'orders'
    },
    'My Orders': {
      svg: 'myOrders'
    },
    'Course Store': {
      icon: 'iconstore',
      color: '#199BD7'
    },
    'Learning Assessment': {
      icon: 'iconassessment',
      color: '#FE5969'
    },
    'Chat Bot': {
      icon: 'iconchat',
      color: '#8956be'
    },
    CRM: {
      icon: 'iconjiatingchengyuan',
      color: '#FE5969'
    },
    'My Classes': {
      svg: 'students'
    },
    Classroom: {
      svg: 'classes_app'
    },
	Journal: {
	  svg: 'journal'
	}
  };