<!-- sectionlist -->
<template>
  <div class="section-list">
    <div class="section-list-header">
      <div class="section-list-header-left">
        <slot name="icon"></slot>
        <div class="title">{{ title }}</div>
      </div>
      <div class="section-list-header-right" v-if="iconButton">
		  <el-tooltip
		    content="Add student"
		    popper-class="edoovo-table-cell-tooltip"
		    :visible-arrow="false"
		  >
		    <img
		      class="img"
		      width="30px"
		      height="30px"
		      style="margin-right: 20px"
		      :src="staffAdd"
		      @click="editStaff(isAddStaff)"
		      alt="staff_add"
		    />
		  </el-tooltip>
		<el-tooltip
		            :content="notAllowed?'Cannot remove with no student':'Remove student'"
		            popper-class="edoovo-table-cell-tooltip"
		            :visible-arrow="false"
		          >
		            <img
		              class="img"
		              :class="{ 'not-allowed': notAllowed }"
		              width="30px"
		              height="30px"
		              :src="staffDelete"
		              @click="editStaff(!isAddStaff)"
		            />
		          </el-tooltip>
      </div>
    </div>
    <div class="section-list-main">
      <ul class="content" v-if="data.length > 0">
        <li
          v-for="(item, index) in data"
          class="item"
          :key="index"
          @click="linkTo(item)"
        >
          <span class="red-dot" v-show="item.messageCount">{{
            item.messageCount
          }}</span>
          <head-img
            class="user-box"
            :src="item.photo"
            :firstName="item.firstName"
            :lastName="item.lastName"
            line-height="88"
          ></head-img>
          <p class="text">{{ item.firstName }} <span >{{ item.lastName }}</span></p>
        </li>
      </ul>
      <!-- ===== -->
      <!-- 空状态 -->
      <!-- ===== -->
      <div class="edoovo-table-empty" v-if="data.length == 0">
        <div style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              margin-bottom: 12px;
              line-height: 200px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
			  v-if="!isAutoBack"
            />
			<slot name="back" v-if="isAutoBack"></slot>
          </div>
          <p style="font-size: 22px">{{isAutoBack?back:'No history yet.'}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionList",
  components: {},
  data() {
    return {
      staffAdd: require("@/assets/images/menu/addTeacher.svg"),
      staffDelete: require("@/assets/images/menu/removeTeacher.svg"),
      isAddStaff: true,
    };
  },
  props: {
    title: String,
    data: {
      type: Array,
      default: [],
    },
    iconButton: {
      type: Boolean,
      default: false,
    },
    notAllowed: {
      type: Boolean,
      default: false,
    },
    isShowLastName: {
      type: Boolean,
      default: false
    },
	isAutoBack:{
		type: Boolean,
		default: false
	},
	back:{
		type: String,
		default: '',
	},
  },
  methods: {
    editStaff(isAddStaff) {
      this.$emit("editStaffCallback", isAddStaff);
    },

    linkTo(item) {
      this.$emit("handleClick", item);
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
.section-list {
  min-height: 132px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-header {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    &-left {
      display: flex;
      align-items: center;
      .title {
        font-family: OpenSans-Bold;
        font-size: 22px;
        color: #5F6368;
      }
    }
    &-right {
      .img {
        cursor: pointer;
        &.not-allowed {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
  &-main {
    padding: 0 20px;
    margin: 20px 0 0;
    color: #202124;
    font-family: Roboto;
    font-size: 14px;
    overflow-y: auto;
    .content {
      display: flex;
      flex-wrap: wrap;
      li {
        position: relative;
        cursor: pointer;
        .red-dot {
          position: absolute;
          z-index: 9;
          right: 28px;
          width: 20px;
          height: 20px;
          border-radius: 50px;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          background-color: #ea4335;
        }
      }
    }
    .item {
      width: 136px;
      height: 116px;
      margin-bottom: 28px;
      //   margin-right: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        margin-top: 9px;
        width: 100%;
        height: 19px;
        line-height: 19px;
        color: #1a73e8;
        font-family: Roboto-medium;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}
/deep/.el-dialog{
	width: 580px;
}
</style>