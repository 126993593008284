<script>
export default {
  name: `GgCancelButton`,
  functional: true,
  render(createElement, context) {
    return createElement(
      `el-button`,
      {
        props: {
          ...context.props
        },
        directives: context.data.directives,
        on: context.data.on,
        class: (context.data.staticClass || '') + ' gg_cancel_button',
        style: {
          ...context.data.staticStyle
        }
      },
      [
        context.slots().default //  also pass default slot to child
      ]
    );
  }
};
</script>

<style lang="less" scoped></style>
