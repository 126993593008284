<!-- SidebarItem -->
<template>
  <div class="menu-bar">
    <el-menu-item
      v-if="!this.$route.path.includes('dashBoard')"
      @click="_topItemClick({ id: 'dashBoard' })"
      key="dashboard"
      class="leftMenuItem"
    >
      <i :style="{ color: '#00A281' }" class="iconfont iconhoutui menuIcon" />
      <span class="menuText" slot="title">Dashboard</span>
    </el-menu-item>
    <el-menu-item
      v-for="item in menuItems"
      @click="_topItemClick(item)"
      :index="item.id"
      :key="item.id"
      class="leftMenuItem"
    >
      <img
        class="menuIcon"
        v-if="menuIconParams[item.name] && menuIconParams[item.name].svg"
        :src="require(`@/assets/images/menu/${_getSvg(item.name)}.svg`)"
        alt
      />
      <span
        v-else
        :style="{
          color: (menuIconParams[item.name] || {}).color || '#00A281',
        }"
        class="iconfont menuIcon"
        :class="[(menuIconParams[item.name] || {}).icon || 'iconchat']"
      />
      <span class="menuText" slot="title">{{ item.name }}</span>
    </el-menu-item>
  </div>
</template>

<script>
import { menuIconParams } from "@/common/baseData";
export default {
  name: "SidebarItem",
  data() {
    return {
      menuIconParams: menuIconParams,
    };
  },
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  methods: {
    _topItemClick(item) {
      // if (this.menusTo[item.id]) {
      this.show_menu = false;
      // this.$router.push({
      //   // path: this.menusTo[item.id]
      // });
      // } else {
      // this.$message("The app is still under development and not usable yet.");
      // }
      if (item.id == "dashBoard") {
        this.$router.push("/dashBoard");
      } else if (item.id) {
        this.$router.push(`/${item.rouerterName}`);
      } else {
        this.$message("The app is still under development and not usable yet.");
      }
    },
    _getSvg(menu) {
      if (menuIconParams[menu] && menuIconParams[menu].svg) {
        return menuIconParams[menu].svg;
      }
      return "product_app";
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.menu-bar {
  .leftMenuItem {
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f2f2f2;
      // .menuText {
      //   color: #1a73e8;
      // }
    }
    .menuIcon {
      font-size: 24px;
      line-height: 24px;
      height: 24px;
      width: 24px;
    }
    .menuText {
      padding-left: 20px;
      font-size: 14px;
      font-family: OpenSans-Bold;
      color: #5f6368;
    }
  }
}
</style>