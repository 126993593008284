<!-- resetPassword -->
<template>
  <div class="sendlink-block">
    <gg-switch
      v-model="aotuSendSwitch"
      active-color="#1A73E8"
      inactive-color="#445469"
    />
    <div class="switch-txt">
      {{ switchTxt }}
    </div>
    <div v-if="!aotuSendSwitch" class="mar-t-10">
      <gg-input
        ref="resetPwd"
        v-model="passwordVal"
        filedType="input"
        type="password"
        placeholder="Password *"
        :error="isError"
        :autofocus="autofocus"
      />
      <!-- <span class="md-error" v-if="!pwdError.show">{{ pwdError.message }}</span> -->
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "resetPassword",
  props: {
    switchTxt: {
      type: String,
      default: "Automatically generate a password link and send to the user",
    },
    autoSend: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    isError() {
      if (!this.aotuSendSwitch && this.passwordVal.length < 8) {
        return {
          message: "Use at least 8 characters",
          show: true,
          val: this.passwordVal,
        };
      }

      return {
        message: "",
        show: false,
        val: this.passwordVal,
      };
    },
  },
  watch: {
    aotuSendSwitch() {
      this.$emit("sendEmail", this.aotuSendSwitch);
    },
    passwordVal: debounce(function (res) {
      this.passwordVal = res.replace(/\s+|\./g, "");
      this.$emit("input", this.passwordVal);
    }, 300),
    value(res) {
      this.passwordVal = res;
    },
    isError: {
      deep: true,
      immediate: true,
      handler(res) {
        // this.initErrorStyle();
        this.$emit("isError", res.show);
      },
    },
  },
  components: {},
  data() {
    return {
      aotuSendSwitch: this.autoSend,
      passwordVal: this.value,
    };
  },
  methods: {},
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.sendlink-block {
  margin: 25px 0 24px;
}
.sendlink-block {
  /deep/ .md-field {
    margin-bottom: 0;
  }
}
.md-error {
  font-size: 12px;
  font-family: Roboto;
  color: #202124;
}
</style>