<template>
  <div class="section">
    <div class="section-header">
      <div class="section-header-left">
        <slot name="icon"></slot>
        <span class="title">{{ title }}</span>
        <span class="subText">{{ subText }}</span>
      </div>
      <div class="section-header-right">
        <slot name="editright"></slot>
        <gg-menu-bar v-if="menuData.length">
          <i class="iconmore_vert-24px iconfont" />
          <template slot="menus">
            <gg-menu-bar-item
              v-for="item in menuData"
              @click="menuClick(item)"
              :key="item"
              >{{ item }}</gg-menu-bar-item
            >
          </template>
        </gg-menu-bar>
      </div>
    </div>
    <div class="section-main">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="section-bottom gg_dialog">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    title: {
      type: String,
      default: "",
    },
    subText: {
      type: String,
      default: "",
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    menuData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    menuClick(item) {
      this.$emit("menuCallback", item);
    },
  },
};
</script>

<style lang="less" scoped>
.section {
  min-height: 132px;
  // max-height: 880px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-header {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    &-left {
      display: flex;
      align-items: center;
      .title {
        font-family: OpenSans-Bold;
        font-size: 20px;
        color: #5f6368;
      }
      .subText {
        font-family: Roboto;
        font-size: 12px;
        color: #b4b4b4;
        margin-left: 8px;
      }
    }
    &-right {
    }
  }
  &-main {
    padding: 0 20px;
    margin: 20px 0;
    flex: 1;
    color: #202124;
    font-family: Roboto;
    font-size: 14px;
    overflow-y: auto;
  }
  &-bottom {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e0e0e0;
  }
}
.gg_confirm .el-button.gg_cancel_button,
.gg_confirm .el-button.gg_cancel_button:focus,
.gg_dialog .el-button.gg_cancel_button,
.gg_dialog .el-button.gg_cancel_button:focus {
  color: #5f6368;
  font-family: OpenSans-Bold;
  font-size: 13px;
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  background: transparent;
  border: none;
}

.gg_confirm .el-button.gg_cancel_button:hover,
.gg_dialog .el-button.gg_cancel_button:hover {
  background: #eeeeee;
}

.gg_confirm .el-button.gg_cancel_button.is-disabled,
.gg_confirm .el-button.gg_cancel_button.is-disabled:hover,
.gg_confirm .el-button.gg_cancel_button.is-disabled:focus,
.gg_dialog .el-button.gg_cancel_button.is-disabled,
.gg_dialog .el-button.gg_cancel_button.is-disabled:hover,
.gg_dialog .el-button.gg_cancel_button.is-disabled:focus {
  background: transparent;
  color: #b4b4b4;
}

</style>
