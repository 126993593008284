<!-- dropdwonmenu -->
<template>
  <div class="menu_bar">
    <div class="menu_bar_button" ref="menuBar" @click="barClick">
      <slot />
    </div>
    <div
      class="drop_down_menus"
      ref="menuBarMenus"
      :style="{
        top: menu_top + 'px',
        right: menu_right + 'px',
        width: `${width === 'auto' ? width : width + 'px'}`
      }"
      :class="{ show: menu_show }"
    >
      <slot name="menus" />
    </div>
  </div>
</template>

<script>
/**
 * @prop {top} Number 菜单列表相对icon底部的top
 * @prop {right} Number 菜单列表相对icon右侧的right
 * @prop {width} Number 菜单列表宽度
 * @prop {autoHide} Boolean 是否触发点击之后自动收起，默认为ture
 */
export default {
  name: 'GgMenuBar',
  props: {
    top: {
      type: Number,
      default: 0
    },
    right: {
      type: Number,
      default: 0
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    width: {
      type: [Number, String],
      default: 'auto'
    },
    otherClickElCls: {
      type: String,
      default: 'ggMenuBarClickBox'
    }
  },
  provide() {
    return {
      autoHide: this.autoHide
    };
  },
  data() {
    return {
      menu_top: 0,
      menu_right: 0,
      menu_show: false
    };
  },
  mounted() {
    document.body.addEventListener('click', this.hideMenu);
    document.querySelector(`.${this.otherClickElCls}`) &&
      document.querySelector(`.${this.otherClickElCls}`).addEventListener('click', this.hideMenu);
  },
  methods: {
    barClick() {
      const _postion = this.$refs.menuBar.getBoundingClientRect();
      this.menu_top = _postion.top + this.$refs.menuBar.clientHeight + this.top;
      this.menu_right = document.documentElement.clientWidth - _postion.right + this.right;
      this.$nextTick(() => {
        this.menu_show = !this.menu_show;
      });
    },
    hideMenu(e) {
      if (this.$refs.menuBar.contains(e.target)) {
        return;
      }
      if (!this.$refs.menuBarMenus.contains(e.target)) {
        this._hideMenu();
      }
    },
    _hideMenu() {
      this.menu_show = false;
    }
  },
  destroyed() {
    document.body.removeEventListener('click', this.hideMenu);
    document.querySelector(`.${this.otherClickElCls}`) &&
      document
        .querySelector(`.${this.otherClickElCls}`)
        .removeEventListener('click', this.hideMenu);
  }
};
</script>
<style lang="less" scoped>
.menu_bar {
  bottom: 20px;
  width: 24px;
  right: 10px;
  height: 24px;
  line-height: 8px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  .menu_bar_button {
    cursor: pointer;
    position: relative;
    font-family: Roboto;
    font-weight: normal;
    &:hover {
      background: #eeeeee;
    }
    height: 100%;
    width: 100%;
  }
  /deep/ .drop_down_menus {
    position: fixed;
    z-index: 999;
    transform: scaleY(0);
    overflow: hidden;
    transform-origin: top;
    transition: transform 0.5s;
    background: #ffffff;
    line-height: 32px;
    padding: 7px 0;
    border-radius: 4px;
    box-shadow: 0px 3px 3px #0000004d;
    text-align: left;
    &.show {
      transform: scaleY(1);
    }
    hr {
      border: none;
    }
    > * {
      font-family: Roboto;
      font-weight: normal;
      color: #202124;
      font-size: 14px;
      cursor: pointer;
      &.disabled {
        color: #b4b4b4;
        cursor: not-allowed;
        &:hover {
          color: #b4b4b4;
        }
      }
      &:hover {
        background: #eeeeee 0% 0% no-repeat padding-box;
      }
      padding: 0 16px;
    }
  }
}
</style>
