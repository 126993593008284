<template>
  <md-field class="gg_select" :class="{ 'md-invalid': showError }" :md-inline="inline">
    <label v-if="!inline">{{ placeholder }}</label>
    <md-select v-model="_value" @md-opened="canDelete" @md-selected="change" @md-closed="canNotDelete" :multiple="multiple" :disabled="disabled" v-if="!chips">
      <slot />
    </md-select>
    <md-chips-select v-model="_value" v-else>
      <slot />
    </md-chips-select>
    <span class="md-error" v-if="error.show">{{ error.message }}</span>
  </md-field>
</template>

<script>
import MdSelect from './MdSelect/index';
import MdChipsSelect from './MdChipsSelect/index';

/**
 * @prop { inline } Boolean 是否是行内，如果为行内的话，则placeholder将不会转换为label
 * @property { string } placeholder placeholder文字信息
 * @property { boolean } multiple 是否为多选，多选value要求为Array
 * @property { boolean } chips select类型，默认为text，如果为chips，则只有multiple
 * @property { object } error 需要提示错误
 *  @error { show } Boolean 是否校验失败
 *  @error { message }  String  错误消息
 */
export default {
  name: 'GgSelect',
  components: {
    MdSelect,
    MdChipsSelect
  },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object,
      default() {
        return {
          show: false,
          message: ''
        };
      }
    }
  },
  data() {
    return {
      init: true
    };
  },
  computed: {
    showError() {
      return this.error.show && !this.init;
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.init) {
          this.init = false;
        }
        this.$emit('input', val);
      }
    }
  },
  methods: {
    canDelete() {
      let self = this
      let code = 0
      let code2 = 0
      document.onkeydown = function(e) {
        let evn = e || event
        let key = evn.keyCode || evn.which || evn.charCode
        if (key === 8 || key === 46) {
          self._value = ''
        }
      }
    },
    canNotDelete() {
      let self = this
      let code = 0
      let code2 = 0
      document.onkeydown = function(e) {
        let evn = e || event
        let key = evn.keyCode || evn.which || evn.charCode
        if (key === 8 || key === 46) {}
      }
    },
    validate() {
      this.init = false;
      return !this.error.show;
    },
    change(val){
      this.$emit("change",val)
    },
  }
};
</script>

<style lang="less" scoped>
.gg_select {
  font-family: Roboto;
  &::before {
    height: 1px;
    background: #1a73e8;
  }
  > label {
    font-size: 14px;
    color: #b4b4b4;
    white-space: nowrap;
  }
  /deep/ .md-input {
    font-family: Roboto;
    font-size: 14px;
    color: #202124;
  }
  &.md-focused {
    > label {
      font-size: 12px;
      color: #1a73e8;
    }
  }
  &.md-has-value {
    > label {
      font-size: 12px;
    }
  }
  &.md-highlight {
    > label {
      color: #1a73e8;
    }
  }
}
</style>
