import breadcrumb from "./modules/breadcrumb";

export default {
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    rolePermission: state => state.user.rolePermission,
    userType: state => state.user.userType,
    userId: state => state.user.userId,
    isAdmin: state => state.user.isAdmin,
    location: state => state.user.location,
    locationId: state => state.user.locationId,
    companyId: state => state.user.companyId,
    schoolName: state => state.user.schoolName,
    className: state => state.user.className,
    classId: state => state.user.classId,
    // roleList: state => state.user.roleList,
    // locationList: state => state.user.locationList,
    breadcrumbData: state => state.breadcrumb.breadcrumbData,
    pendingCount: state => state.moments.pendingCount,
    pendingCountStudent: state => state.moments.pendingCountStudent,
}