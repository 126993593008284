<template>
  <md-field
    :md-counter="mdCounter"
    class="gg_input"
    :class="{ 'md-invalid': showError }"
    :md-inline="inline"
  >
    <md-input
      v-if="filedType === 'input'"
      ref="mdInput"
      :type="type"
      :maxlength="maxlength"
      v-model="_value"
      @focus="focus"
      @blur="blur"
      @keyup="keyup"
      :placeholder="inputPlaceholder"
      :disabled="disabled"
	  :md-counter='mdCounter?(mdCounterNumber?mdCounterNumber:"500"):""'
    />
    <md-textarea
      v-else
      ref="mdInput"
      :type="type"
      :maxlength="maxlength"
      v-model="_value"
      @focus="focus"
      @blur="blur"
      :md-autogrow="true"
      :placeholder="inputPlaceholder"
    />
    <label v-if="!inline">{{ placeholder }}</label>
    <span class="md-error" v-if="error.show">{{ error.message }}</span>
  </md-field>
</template>

<script>
/**
 * @prop { inline } Boolean 是否是行内，如果为行内的话，则placeholder将不会转换为label
 * @prop { type } String 文本类型[text, password]
 * @prop { error } Object 需要提示错误
 *  @error { show } Boolean 是否校验失败
 *  @error { message }  String  错误消息
 */
export default {
  name: "GgInput",
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    filedType: {
      type: String,
      default: "input",
    },
    error: {
      type: Object,
      default() {
        return {
          show: false,
          message: "",
        };
      },
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
	mdCounter: {
      type: Boolean,
      default: false,
    },
	mdCounterNumber:{
		type:Number,
		 default: 0
	}
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.mdInput.$el.focus();
    }
  },
  data() {
    return {
      init: true,
    };
  },
  computed: {
    showError() {
      return this.error.show && !this.init;
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.init) {
          this.init = false;
        }
        this.$emit("input", val);
      },
    },
    inputPlaceholder() {
      return this.inline ? this.placeholder : "";
    },
  },
  methods: {
    validate() {
      this.init = false;
      return !this.error.show;
    },
    focus() {
      this.$emit("focus");
    },
    blur() {
      this.$emit("blur");
    },
    keyup() {
      this.$emit("keyup");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: Roboto;
}
.md-field {
  margin-bottom: 20px;
  label {
    font-size: 14px;
  }
  &.md-has-value {
    label {
      font-size: 12px;
    }
  }
}
.gg_input {
  label {
    color: #b4b4b4 !important;
  }
  .md-input {
    font-family: Roboto;
    color: #202124;
    font-size: 14px;
  }
  &:before {
    height: 1px;
  }
}
.gg_input.md-field.md-theme-default.md-focused {
  &::before {
    background-color: #1a73e8;
  }
  label {
    color: #1a73e8 !important;
    font-size: 12px;
  }
}
.gg_input.md-field.md-theme-default.md-invalid {
  &::after {
    background-color: #ea4335;
  }
  &::before {
    background-color: #ea4335;
  }
  label {
    color: #ea4335 !important;
  }
  .md-error {
    color: #ea4335;
  }
}
</style>
