<template>
  <div class="section-info">
    <div class="section-info-header">
      <div class="section-info-header-left">
        <div class="title">{{ title }}</div>
      </div>
      <div class="section-info-header-right">
		  <div class="title">{{ subTitle }}</div>
	  </div>
    </div>
    <div class="section-info-main">
      <slot name="custom-html"></slot>
      <ul v-if="readonly && data.length" class="readonly-content">
        <li
          class="item"
          :class="{ edit: !readonly, block: isBlock }"
          v-for="(item, index) in data"
          :key="index"
		
        >
          <div class="label">{{ item.placeholder }}</div>
          <div class="value"  v-if="item.placeholder=='Phone'">
			
			 <div >{{item.phoneNumberVal.phoneNumber==''?'-': item.phoneNumberVal.countryCode}} {{item.phoneNumberVal.phoneNumber==''?'': item.phoneNumberVal.phoneNumber}}</div>
          </div>
		  <div class="value"  v-else-if="item.placeholder=='Office number'&& item.officeNumberVal.phoneNumber=='' ">
		  			 <div >-</div>
		  </div>
		  <div class="value"  v-else-if="item.placeholder=='Phone number'&& item.phoneNumberVal.phoneNumber=='' ">
		  			 <div >-</div>
		  </div>
		  <div class="value"  v-else>
		    <input
		      v-if="item.value"
		      type="text"
		      v-model="item.value"
		      readonly
		    />
		    <div v-else>-</div>
		  </div>
        </li>
      </ul>
      <div v-if="!readonly">
        <div class="not-readonly-content">
          <template v-for="(item, index) in fromData">
            <label v-if="isShowLabel" class="label" :key="index">{{
              item.placeholder
            }}</label>
            <slot v-if="item.renderHtml" name="renderHtml" :data="item"></slot>
            <gg-input
              v-else
              :class="{ float: item.float }"
              :key="item.key"
              :ref="item.key"
              v-model="item.value"
              :placeholder="showPlaceholder?item.placeholder:''"
              :error="errorObj[item.key]"
              :autofocus="item.autofocus"
              :disabled="item.disabled"
              :type="item.type"
              :inline="inline"
            />
            <!--:inline="!readonly" -->
          </template>
        </div>
        <div class="tip"><slot name="tip"></slot></div> 
        <div class="section-info-bottom gg_dialog">
          <span class="dialog-footer">
            <BaseButton @click="cancel">{{ cancelBtnTxt }}</BaseButton>
            <BaseButton type="primary" :disabled="disabled" @click="submit">{{
              submitBtnTxt
            }}</BaseButton>
          </span>
        </div>
      </div>
      <i
        v-if="iconEdit && readonly"
        class="iconfont iconedit"
        style="display: none"
        @click="editStaff"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionInfo",
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    renderPass: {
      type: Boolean,
      default: true,
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    cancelBtnTxt: {
      type: String,
      default: "Cancel",
    },
    submitBtnTxt: {
      type: String,
      default: "Save",
    },
    title: String,
    // data: {
    //   type: Array,
    //   default: [],
    // },
	subTitle:{
		type:String,
		default:""
	},
    iconEdit: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    isShowLabel: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromData: this.data,
      errorObj: {},
      isPass: false,
    };
  },
  watch: {
    data(res) {
      this.fromData = res;
    },
	data:"consoleData",
    fromData: {
      deep: true,
      handler() {
        this.valid();
      },
    },
  },
  mounted(){
	  // this.fromData.forEach((item) => {
	  //   this.$refs[item.key] && (this.$refs[item.key][0].init = false);
	  // });
	  // this.valid()
  },
  computed: {
    disabled() {
      return !this.isPass || !this.renderPass;
    },
  },
  methods: {
	  consoleData(){
		console.log(this.data)
	  },
    editStaff() {
      this.$emit("update:readonly", false);
    },
    submit() {
      // 提交之前先将每个input初始化字段设为false，要不无法触发默认错误提示
      this.fromData.forEach((item) => {
        this.$refs[item.key] && (this.$refs[item.key][0].init = false);
      });
      if (this.valid()) {
        this.$emit("update:readonly", true);
        this.$emit("submit", this.fromData, true);
        this.isPass = false;
      } else {
        this.$emit("submit", this.fromData, false);
      }
    },
    cancel() {
      this.$emit("update:readonly", true);
      this.isPass = false;
      this.$emit("cancel", this.fromData);
      // this.$router.go(-1);
    },
    valid() {
      this.errorObj = {};
      let isError = false;
      this.fromData.forEach((item) => {
		
		if(item.reg && item.value==null){
			this.errorObj[item.key] = {
			  show: true,
			  message: item.errorText,
			};
			isError = true;
		} 
      else  if (item.reg && !item.reg.test(item.value)) {
          this.errorObj[item.key] = {
            show: true,
            message: item.errorText,
          };
          isError = true;
        }
      });
      this.isPass = !isError;
      console.log(111, this.isPass);
      return !isError;
    },
  },
};
</script>

<style lang="less" scoped>
.section-info {
  min-height: 132px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #fff;
  &-header {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    &-left {
      display: flex;
      align-items: center;
      .title {
        font-family: OpenSans-Bold;
        font-size: 22px;
        color: #5f6368;
      }
    }
    &-right {
    }
  }
  &-main {
    position: relative;
    color: #202124;
    font-family: Roboto-medium;
    font-size: 14px;
    overflow-y: auto;
    &:hover {
      .iconedit {
        display: block !important;
      }
    }

    .readonly-content {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      &:hover {
        background-color: #f2f2f2;
      }
      .item {
        display: flex;
        width: 50%;
        margin-bottom: 22px;
        &.block {
          width: 100%;
        }
        &.edit {
          padding-right: 20px;
        }
        .label {
          flex-basis: 284px;
        }
        .value {
          flex-basis: 316px;
          input {
            border: 0;
            outline: none;
            background: none;
            &.border-bottom {
              border-bottom: 1px solid #ccc;
            }
          }
        }
      }
    }

    .iconedit {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .not-readonly-content {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
      // max-height: 444px;
      max-width: 85%;
      overflow: auto;
      .label {
        width: 140px;
        flex-basis: 140px;
        display: flex;
        align-items: center;
      }
      .md-field {
        margin-top: 0;
        width: calc(100% - 140px);
      }
      .select-hei{
        width: calc(100% - 140px);
        .md-field {
          margin-top: 0;
          width: 220px;
        }
      }
      .text-reminder {
        color: #b4b4b4;
      }
      .user-reminder {
        color: #202124;
      }
      .reminder {
        font-size: 12px;
        font-family: Roboto;
        & + .reminder {
          margin-top: 20px;
        }
      }
      /deep/.float {
        width: calc(50% - 10px);
        flex-basis: calc(50% - 10px);
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }
  &-bottom {
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  .tip{
    font-size:12px;
    color:#999;
    padding:0 20px;
  }
}
</style>
