<template>
  <gg-dialog
    class="upload_img"
    :append-to-body="true"
    :visible.sync="isImgCutShow"
    width="560px"
    :title="title"
  >
    <img-cut
      :openCropper="openCropper"
      ref="imgCut"
      :acceptPdfFile='acceptPdfFile'
      @_uploadCallback="_uploadCallback"
      @_uploadPdfCallback="_uploadPdfCallback"
      :fixed-number="fixedNumber"
      :fixed="fixed"
      :fixedBox="fixedBox"
      :init-url="initUrl"
      @uploadSuccess="uploadSuccess"
	  :isCut='isCut'
    />

    <slot />
    <span slot="footer" class="dialog-footer" >
      <baseButton @click="cancel">Cancel</baseButton>
      <baseButton @click="sureUploadImg" type="primary" v-if="!isCut">{{ comfirmText }}</baseButton>
    </span>
  </gg-dialog>
</template>

<script>
export default {
  props: {
    visible: { default: false, type: Boolean },
    fixedNumber: {
      type: Array,
      default() {
        return [1, 1];
      }
    },
    fixed: {
      type: Boolean,
      default: true
    },
    fixedBox: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Upload Photo'
    },
    initUrl: {
      // 初始图片链接
      default: '',
      type: [String, Array]
    },
    comfirmText: {
      type: String,
      default: 'Save'
    },
    acceptPdfFile: {
      type: Boolean,
      default: false
    },
    isCut: {
      type: Boolean,
      default: false
    },
    openCropper: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    isImgCutShow: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  methods: {
    cancel() {
      this.$refs.imgCut.file = '';
      this.$refs.imgCut.isCropperShow = false;
      this.isImgCutShow = false;
    },
    sureUploadImg() {
      this.$refs.imgCut._sureUploadImg();
    },
    _uploadCallback(formData, file) {
      this.$emit('_uploadCallback', formData, file);
    },
    _uploadPdfCallback(file) {
      this.$emit('_uploadPdfCallback', file);
    },
    uploadSuccess(file) {
      this.$emit('uploadSuccess', file);
    }
  }
};
</script>

<style></style>
