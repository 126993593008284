<template>
  <div class="cropperBox">
    <div class="cropper-content">
      <!-- 剪裁框 -->
      <div class="cropper">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="false"
          :maxImgSize="option.maxImgSize"
          :full="option.full"
          :canMove="option.canMove"
          :canScale="true"
          :fixed="fixed"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixedBox="fixedBox"
          @realTime="realTime"
          :fixedNumber="fixedNumber"
          :centerBox="option.centerBox"
        />
      </div>
    </div>
    <div class="footer-btn">
      <!-- 缩放旋转按钮 -->
      <!-- <div class="scope-btn">
        <el-button type="primary" icon="el-icon-zoom-in" @click="changeScale(1)"></el-button>
        <el-button type="primary" icon="el-icon-zoom-out" @click="changeScale(-1)"></el-button>
        <el-button type="primary" @click="rotateLeft">逆时针旋转</el-button>
        <el-button type="primary" @click="rotateRight">顺时针旋转</el-button>
      </div>-->
      <!-- 确认上传按钮 -->
      <!-- <div class="upload-btn">
        <el-button type="primary" @click="uploadImg('blob')">上传</el-button>
      </div>-->
    </div>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper';
export default {
  data() {
    return {
      previews: {}, // 预览数据
      option: {
        img: '', // 裁剪图片的地址  (默认：空)
        size: 1, // 裁剪生成图片的质量  (默认:1)
        full: true, // 是否输出原图比例的截图 选true生成的图片会非常大  (默认:false)
        outputType: 'jpg', // 裁剪生成图片的格式  (默认:jpg)
        canMove: false, // 上传图片是否可以移动  (默认:true)
        original: false, // 上传图片按照原始比例渲染  (默认:false)
        canMoveBox: true, // 截图框能否拖动  (默认:true)
        autoCrop: true, // 是否默认生成截图框  (默认:false)
        // maxImgSize: 10000,
        // autoCropWidth: 100, // 默认生成截图框宽度  (默认:80%)
        // autoCropHeight: 100, // 默认生成截图框高度  (默认:80%)
        // fixedBox: false, // 固定截图框大小 不允许改变  (默认:false)
        // fixed: true, // 是否开启截图框宽高固定比例  (默认:true)
        centerBox: true
      },
      downImg: '#'
    };
  },
  props: ['imgFile', 'fixedNumber', 'fixed', 'fixedBox', 'limitMinSize', 'autoCropWidth', 'autoCropHeight'],
  components: { VueCropper },
  methods: {
    changeScale(num) {
      // 图片缩放
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    rotateLeft() {
      // 向左旋转
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      // 向右旋转
      this.$refs.cropper.rotateRight();
    },
    Update() {
      // this.file = this.imgFile
      this.option.img = this.imgFile.url;
    },
    realTime(data) {
      // 实时预览
      this.previews = data;
    },
    _getImgData(type = 'blob') {
      if(event) event.preventDefault();
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          console.log('getCropBlob === ', data);
          // _sureUploadImgCallback
          this.$emit('_sureUploadImgCallback', data);
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.$emit('_sureUploadImgCallback', data);
        });
      }
    },
    uploadImg(type) {
      // 将剪裁好的图片回传给父组件
      if(event) event.preventDefault();
      let that = this;
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          that.$emit('upload', data);
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          that.$emit('upload', data);
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.cropper-content {
  // background-color: #ff6100;
  // width: 752px;
  display: flex;
  justify-content: center;
  .cropper {
    width: 298px;
    height: 196px;
    /deep/.vue-cropper {
      background-image: none;
      .cropper-modal {
        background-color: rgba(0, 0, 0, 0);
      }
      .cropper-view-box {
        outline-color: #000;
      }
      .crop-point {
        width: 6px;
        height: 6px;
        border: 1px solid #000000;
        top: -3px;
        right: -3px;
        background-color: #ffffff;
        &.point8,
        &.point7,
        &.point6 {
          top: unset;
          bottom: -4px;
        }
        &.point4,
        &.point5 {
          top: 50%;
        }
      }
    }
  }
}
</style>
