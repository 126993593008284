import Vue from 'vue'

// private-components
import SectionList from '@/components/private-components/SectionList';
import SectionList1 from '@/components/private-components/SectionList/index1';
import SectionInfo from '@/components/private-components/SectionInfo';
import SectionInfo1 from '@/components/private-components/SectionInfo/index2';
import CheckboxGroup from '@/components/private-components/CheckboxGroup';
import ResetPassword from '@/components/private-components/ResetPassword';

// edoovo-components
import EdoovoTable from '@/components/edoovo-components/Table/Table'
import EdoovoTableColumn from '@/components/edoovo-components/Table/TableColumn'
import EdoovoPagination from '@/components/edoovo-components/Pagination'
import BaseFilter from '@/components/edoovo-components/BaseFilter'
import BaseDatePicker from '@/components/edoovo-components/baseDatePicker'
import BaseButton from '@/components/edoovo-components/baseButton'
import GgPage from '@/components/edoovo-components/ggPage'
import GgPageHeader from '@/components/edoovo-components/GgPageHeader'
import GgPageHeader2 from '@/components/edoovo-components/GgPageHeader/index2'
import TopAppBar from '@/components/edoovo-components/TopAppBar'
import AvatarCard from '@/components/edoovo-components/AvatarCard'
import HeadImg from '@/components/edoovo-components/headImg'
import BaseImg from '@/components/edoovo-components/baseImg'
import ggDialog from '@/components/edoovo-components/ggDialog'
import imgCutModal from '@/components/edoovo-components/imgCutModal'
import imgCut from '@/components/edoovo-components/ImgCut'
import ggCancelButton from '@/components/edoovo-components/ggCancelButton'
import ggSubmitButton from '@/components/edoovo-components/ggSubmitButton'
import GgInput from '@/components/edoovo-components/GgInput'
import GgCreateButton from '@/components/edoovo-components/createButton'
import GgFlexMenus from '@/components/edoovo-components/ggFlexMenus'
import GgMenuBar from '@/components/edoovo-components/menuBar/index'
import GgMenuBarItem from '@/components/edoovo-components/menuBar/ggDropdownItem'
import BaseBreadcrumb from '@/components/edoovo-components/baseBreadcrumb'
import GgDropdown from '@/components/edoovo-components/ggDropdown/index.vue'
import ggDropdownItem from '@/components/edoovo-components/ggDropdown/ggDropdownItem'
import BorderLessTable from '@/components/edoovo-components/borderLessTable'
import BaseFormDialog from '@/components/edoovo-components/baseFormDialog'
import BaseFormDialog1 from '@/components/edoovo-components/baseFormDialog/index2'
import BaseFormDialog3 from '@/components/edoovo-components/baseFormDialog/index3'
import Snackbar from '@/components/edoovo-components/Snackbar'
import PhoneNumber from '@/components/edoovo-components/PhoneNumber'
import phoneCode from '@/components/edoovo-components/PhoneCode'
import Dashboard from '@/components/edoovo-components/Dashboard'
import Section from '@/components/edoovo-components/Section';
import Section1 from '@/components/edoovo-components/Section/index1';
import baseGGSelect from '@/components/edoovo-components/baseGGSelect';
import GgSwitch from '@/components/edoovo-components/ggSwitch';
import SelectDialog from '@/components/edoovo-components/BaseSelectDialog'
import GgChips from '@/components/edoovo-components/GgChips'
import BaseList from '@/components/edoovo-components/BaseList'
import baseCheckbox from '@/components/edoovo-components/baseCheckbox'
import GgViewImg from '@/components/edoovo-components/viewImg'
import EdoovoOption from '@/components/edoovo-components/EdoovoOption'

// element UI
import { MessageBox } from 'element-ui'

Vue.prototype.$confirm = (message, title, options) =>
    MessageBox.confirm(
        message, 
        title,
        Object.assign(
            {},
            {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                cancelButtonClass: 'gg_cancel_button',
                confirmButtonClass: 'gg_submit_button',
                showClose: false,
                center: true
            },
            options
        )
    );

export default {
    SectionList,
	SectionList1,
    SectionInfo, 
	SectionInfo1, 
    CheckboxGroup,
    ResetPassword,
    EdoovoOption,
    EdoovoTable,
    EdoovoTableColumn,
    EdoovoPagination,
    BaseFilter,
    BaseDatePicker,
    BaseButton,
    GgPage,
    GgPageHeader,
    GgPageHeader2,
    TopAppBar,
    AvatarCard,
    HeadImg,
    BaseImg,
    ggDialog,
    imgCutModal,
    imgCut,
    ggCancelButton,
    ggSubmitButton,
    GgInput,
    GgCreateButton,
    GgFlexMenus,
    GgMenuBar,
    GgMenuBarItem,
    BaseBreadcrumb,
    GgDropdown,
    ggDropdownItem,
    BorderLessTable,
    BaseFormDialog,
    BaseFormDialog1, // todo
    BaseFormDialog3,
    Snackbar,
    PhoneNumber,
    phoneCode,
    Dashboard,
    Section,
	Section1,
    baseGGSelect,
    GgSwitch,
    SelectDialog,
    GgChips,
    BaseList,
    baseCheckbox,
	GgViewImg,
}