<script>
export default {
  name: `BaseDatePicker`,
  functional: true,
  render(createElement, context) {
    return createElement(
      `el-date-picker`,
      {
        directives: context.data.directives,
        on: context.data.on,
        props: {
          format: 'dd.MM.yyyy',
          ...context.props
        },
        class: (context.data.staticClass || '') + ' base_picker',
        style: {
          width: context.props.width,
          ...context.data.staticStyle
        }
      },
      [
        context.slots().default //  also pass default slot to child
      ]
    );
  }
};
</script>

<style scoped>
.base_picker >>> .el-input__inner {
  line-height: 39px;
  border: none;
  border-bottom: 1px solid rgba(68, 84, 105, 0.2);
  border-radius: 0;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 400;
  padding: 0;
  padding-left: 35px;
  color: rgba(68, 84, 105, 1);
}
.base_picker >>> .el-input__inner:focus {
  border-bottom: 1px solid rgba(68, 84, 105, 1);
}
</style>
