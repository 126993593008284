var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ggPageTitle"},[_c('div',{staticClass:"ggPageTitle-left"},[_vm._t("avatarHeader"),(_vm.userInfo)?_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar-uploader",on:{"click":function () {
				if(_vm.isUploadImg && _vm.editimg){
					 this$1.isImgCutShow = true;
				}
             
            }}},[_c('head-img',{staticClass:"imgBox",attrs:{"src":_vm.userInfo.avatar ? ("" + (_vm.userInfo.avatar)) : "","firstName":_vm.userInfo.firstName,"lastName":_vm.userInfo.lastName,"line-height":"52"}}),(_vm.userInfo.isMaster==1)?_c('span',{staticClass:"starImg"},[_c('img',{attrs:{"src":require('@/assets/images/star.png'),"alt":""}})]):_vm._e(),(_vm.editimg)?_c('div',{staticClass:"iconBox"},[_c('i',{staticClass:"material-icons iconfont iconadd_a_photo"})]):_vm._e()],1)]):_vm._e(),(_vm.squareInfo)?_c('div',{staticClass:"avatar"},[_c('head-img',{staticClass:"imgBox squareBox",attrs:{"src":_vm.squareInfo.avatar ? ("" + (_vm.squareInfo.avatar)) : "","firstName":_vm.squareInfo.firstName,"lastName":_vm.squareInfo.lastName,"line-height":"52"}})],1):_vm._e(),_c('div',{staticClass:"titleInfo"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title)),_vm._t("title-label")],2),_c('div',{staticClass:"subTitle"},[(_vm.subAvatarUserInfo)?_c('div',{staticClass:"subAvatar"},[_c('head-img',{attrs:{"src":_vm.subAvatarUserInfo.avatar
                  ? ("" + (_vm.subAvatarUserInfo.avatar))
                  : "","firstName":_vm.subAvatarUserInfo.firstName,"lastName":_vm.subAvatarUserInfo.lastName,"line-height":"32"}})],1):_vm._e(),(_vm.subTitle1)?[_c('div',[_vm._v(_vm._s(_vm.subTitle1))]),_vm._t("subTitleLabel")]:_vm._e()],2),(_vm.subTitle2)?_c('div',{staticClass:"subTitle",class:_vm.subTitleClassName2},[_vm._v(" "+_vm._s(_vm.subTitle2)+" ")]):_vm._e(),_vm._t("info")],2)],2),_c('div',{staticClass:"ggPageTitle-right"},[(_vm.menuData.length)?_c('gg-menu-bar',[_c('i',{staticClass:"iconmore_vert-24px iconfont"}),_c('template',{slot:"menus"},_vm._l((_vm.menuData),function(item,key){return (!_vm.student || !item.isDisabled)?_c('gg-menu-bar-item',{key:key,class:{ borderTop: item=='Edit' || item.label=='Edit' },attrs:{"disabled":item?item.isDisabled:false},on:{"click":function($event){return _vm.menuClick(item)}}},[_vm._v(_vm._s(typeof item === 'object' ? item.label : item))]):(_vm.student && item.isDisabled)?_c('el-tooltip',{attrs:{"visible-arrow":false,"placement":"bottom","popper-class":"text","content":"Student with connected family cannot be deleted"}},[_c('gg-menu-bar-item',{attrs:{"disabled":item?item.isDisabled:false},on:{"click":function($event){return _vm.menuClick(item)}}},[_vm._v(_vm._s(typeof item === 'object' ? item.label : item))])],1):_vm._e()}),1)],2):_vm._e()],1),(_vm.isImgCutShow)?_c('ImgCutModal',{attrs:{"visible":_vm.isImgCutShow},on:{"update:visible":function($event){_vm.isImgCutShow=$event},"_uploadCallback":_vm._uploadCallback}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }