<!-- dropdown-item -->
<template>
  <div :class="{ disabled }" @click.stop="itemClick">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GgMenuBarItem',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  inject: ['autoHide'],
  components: {},

  computed: {},

  methods: {
    itemClick() {
      if (!this.disabled) {
        this.$emit('click');
        if (this.autoHide) {
          let _parent = this.$parent;
          while (_parent.$el === this.$el) {
            _parent = _parent.$parent;
          }
          _parent._hideMenu();
        }
      }
    }
  }
};
</script>
<style lang="less" scoped></style>
