<!--
 * @Description: dialog 表单
 * @Author: luozhong
 * @Date: 2020-11-17 00:20:53
-->
<template>
  <gg-dialog
    class="form-dialog"
    :title="title"
    :visible.sync="isShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="580px"
    v-loading="isLoading"
  >
    <div class="content">
      <template v-for="item in fromData">
        <slot v-if="item.renderHtml" name="renderHtml" :data="item"></slot>
        <gg-input
          v-else
          :class="[{ float: item.float }, item.className]"
          :key="item.key"
          :ref="item.key"
          v-model="item.value"
          :placeholder="item.placeholder"
          :error="errorObj[item.key]"
          :autofocus="item.autofocus"
        />
      </template>
      <p class="text-reminder reminder" v-if="textReminder">
        {{ textReminder }}
      </p>
      <p class="user-reminder reminder" v-if="userReminder">
        {{ userReminder }}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <BaseButton @click="cancel">{{ cancelBtnTxt }}</BaseButton>
      <BaseButton type="primary" :disabled="disabled" @click="submit">{{
        submitBtnTxt
      }}</BaseButton>
    </span>
  </gg-dialog>
</template>

<script>
// import BaseButton from '@/components/base/baseButton';

export default {
  name: "BaseFormDialog",
  components: {
    // BaseButton
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    renderPass: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "title",
    },
    textReminder: {
      type: String,
      default: "",
    },
    userReminder: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    cancelBtnTxt: {
      type: String,
      default: "Cancel",
    },
    submitBtnTxt: {
      type: String,
      default: "Add",
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShow: this.value,
      isLoading: false,
      name: "",
      fromData: this.data,
      errorObj: {},
      isPass: false,
    };
  },
  watch: {
    value(res) {
      this.isShow = res;
    },
    isShow(res) {
      this.$emit("input", res);
    },
    data(res) {
      this.fromData = res;
    },
    fromData: {
      deep: true,
      handler() {
         this.valid();
      },
    },
  },
  computed: {
    disabled() {
      return !this.isPass || !this.renderPass;
    },
  },
  methods: {
    submit() {
      // 提交之前先将每个input初始化字段设为false，要不无法触发默认错误提示
      this.fromData.forEach((item) => {
        // this.$refs[item.key] && (this.$refs[item.key][0].init = false);
      });
      if (this.valid()) {
        this.isShow = false;
        this.$emit("submit", this.fromData, true);
      } else {
        this.$emit("submit", this.fromData, false);
      }
    },
    cancel() {
      this.isShow = false;
    },
    valid() {
      this.errorObj = {};
      let isError = false;
      this.fromData.forEach((item) => {
        if (item.reg && !item.reg.test(item.value)) {
          this.errorObj[item.key] = {
            show: true,
            message: item.errorText,
          };
          isError = true;
        }
      });
      this.isPass = !isError;
      console.log(111, this.isPass);
      return !isError;
    },
  },
};
</script>

<style lang="less" scoped>
.form-dialog {
  /deep/ .el-dialog__header {
    font-family: OpenSans-Bold;
    height: 56px !important;
    line-height: 56px !important;
  }
  /deep/ .el-dialog__body {
    padding: 0 !important;
  }
  /deep/ .el-dialog__footer {
    padding-top: 20px !important;
    height: 76px !important;
    .base-button + .base-button {
      margin-left: 8px;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    max-height: 444px;
    overflow: auto;
    .text-reminder {
      color: #b4b4b4;
    }
    .user-reminder {
      color: #202124;
    }
    .reminder {
      font-size: 12px;
      font-family: Roboto;
      & + .reminder {
        margin-top: 20px;
      }
    }
    // /deep/.md-field.float{

    //   width: calc(50% - 10px) !important;
    //   float: left !important;
    // }
    // /deep/.float {
    //   width: calc(50% - 10px);
    //   float: left;
    //   &:nth-child(2n) {
    //     margin-left: 20px;
    //   }
    // }
    /deep/.float {
      width: calc(50% - 10px);
      flex-basis: calc(50% - 10px);
      flex-grow: 0;
      flex-shrink: 0;
      max-height: 48px;
    }
  }
}
</style>
