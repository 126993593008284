import { Auth } from "@/common"

const user = {
    state: {
        token: Auth.getToken(),
        userInfo: Auth.getUserInfo(),
        rolePermission: Auth.getRolePermission() || [],
        userType: Auth.getUserType(), //  0是company 1是super admin
        userId: Auth.getUserId(),
        isAdmin: Auth.getIsAdmin(), // 1_管理员
        locationId: Auth.getLocationId(),
        companyId: Auth.getCompany(),
        schoolName: Auth.getSchoolName(),
        className: Auth.getClassName(),
        classId: Auth.getClassId(),
        // workType: Auth.getWorkType(),
        // jobTitle: Auth.getJobTitle(),
        // roleList: Auth.getRoleList(),
        // locationList: Auth.getLocationListList(),
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            Auth.setToken(token)
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo;
            Auth.setUserInfo(userInfo)
        },
        SET_ROLEPERMISSION: (state, rolePermission) => {
            state.rolePermission = rolePermission;
            Auth.setRolePermission(rolePermission)
        },
        SET_USERTYPE: (state, userType) => {
            state.userType = userType;
            Auth.setUserType(userType)
        },
        SET_USERID: (state, userId) => {
            state.userId = userId;
            Auth.setUserId(userId)
        },
        SET_ISADMIN: (state, isAdmin) => {
            state.isAdmin = isAdmin;
            Auth.setIsAdmin(isAdmin)
        },
        SET_LOCATION: (state, location) => {
            state.location = location;
            Auth.setLocation(location)
        },
        SET_LOCATIONID: (state, locationId) => {
            state.locationId = locationId;
            Auth.setLocationId(locationId)
        },
        SET_COMPANYID: (state, companyId) => {
            state.companyId = companyId;
            Auth.setCompany(companyId)
        },
        SET_SCHOOLNAME: (state, schoolName) => {
            state.schoolName = schoolName
            Auth.setSchoolName(schoolName)
        },
        SET_CLASSNAME: (state, className) => {
            state.className = className
            Auth.setClassName(className)
        },
        SET_CLASSID: (state, classId) => {
            state.classId = classId
            Auth.setClassId(classId)
        },
        // SET_WORKTYPE: (state, workType) => {
        //     state.workType = workType
        //     Auth.setWorkType(workType)
        // },
        // SET_JOBTITLE: (state, jobTitle) => {
        //     state.jobTitle = jobTitle
        //     Auth.setJobTitle(jobTitle)
        // },
        // SET_ROLELIST:(state, roleList) => {
        //     state.roleList = roleList
        //     Auth.setRoleList(roleList);
        // },
        // SET_LOCATIONLIST:(state, locationList) => {
        //     state.locationList = locationList
        //     Auth.setLocationListList(locationList);
        // },
    },
    actions: {}
}
export default user