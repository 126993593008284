<template>
  <div class="dashboard">
    <div class="dashboard-header">
      <slot name="header"> </slot>
    </div>
    <div class="dashboard-main">
      <div class="dashboard-main-center">
        <div class="dashboard-main-center-top">
          <div
            v-for="(item, index) in appData"
            :key="index"
            class="appIcon"
            @click="routerTo(item)"
          >
            <!-- <span class="iconfont" :class="[item.icon]"></span> -->
            <div class="menuIcon-wrapper">
              <img class="menuIcon" :src="item.src" alt />
            </div>
            <span class="text">{{ item.label }}</span>
          </div>
        </div>
        <div class="dashboard-main-center-bottom">
          <slot name="bottomContent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuIconParams } from "@/common/baseData";
export default {
  name: `Dashboard`,
  props: {
    appData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuIconParams: menuIconParams,
    };
  },
  methods: {
    routerTo(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard {
  box-sizing: border-box;
  height: 100vh;
  min-width: 1280px;
  min-height: 620px;
  padding-bottom: 112px;
  &-header {
    height: 64px;
    /deep/.el-header {
      height: 64px !important;
    }
  }
  &-main {
    padding-top: 52px;
    &-center {
      width: 100%;
      overflow-y: auto;
      padding: 0 160px;
      &-top {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .appIcon {
          width: 200px;
          height: 200px;
          border-radius: 8px;
          padding-top: 48px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          .menuIcon-wrapper {
            display: flex;
            align-items: center;
            height: 72px;
            .menuIcon {
              width: 100%;
              height: auto;
              max-height: 72px;
            }
          }
          .iconfont {
            font-size: 72px;
            line-height: 72px;
            color: #4685f1;
          }
          .text {
            margin-top: 16px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            color: #202124;
          }
          &:hover {
            background-color: #f2f2f2;
          }
        }
      }
      &-bottom {
        width: 100%;
        padding: 52px 64px 0;
      }
    }
  }
}
</style>
