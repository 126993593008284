const TOKEN_KEY = 'Authorization'

export default {
  getToken() {
    return sessionStorage.getItem(TOKEN_KEY)
  },
  setToken(token) {
    return sessionStorage.setItem(TOKEN_KEY, token)
  },
  setRolePermission(rolePermission) {
    return sessionStorage.setItem('rolePermission', JSON.stringify(rolePermission))
  },
  getRolePermission() {
    return JSON.parse(sessionStorage.getItem('rolePermission'));
  },
  removeToken() {
    return sessionStorage.clear()
  },
  getUserType() {
    return sessionStorage.getItem('userType')
  },
  setUserType(userType) {
    return sessionStorage.setItem('userType', userType)
  },
  getUserId() {
    return sessionStorage.getItem('userId')
  },
  setUserId(userId) {
    return sessionStorage.setItem('userId', userId)
  },  
  setUserInfo(userInfo) {
    return sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  getUserInfo() {
    return JSON.parse(sessionStorage.getItem('userInfo'));
  },
  setIsAdmin(isAdmin) {
    return sessionStorage.setItem('isAdmin', isAdmin)
  },
  getIsAdmin() {
    return sessionStorage.getItem('isAdmin')
  },
  setLocation(location) {
    return sessionStorage.setItem('location', location)
  },
  getLocation() {
    return sessionStorage.getItem('location')
  },
  setLocationId(locationId) {
    return sessionStorage.setItem('locationId', locationId)
  },
  getLocationId() {
    return sessionStorage.getItem('locationId')
  },
  setCompany(companyId) {
    return sessionStorage.setItem('companyId', companyId)
  },
  getCompany() {
    return sessionStorage.getItem('companyId')
  },
  setSchoolName(schoolName) {
    return sessionStorage.setItem('schoolName', schoolName)
  },
  getSchoolName() {
    return sessionStorage.getItem('schoolName')
  },
  setClassName(className) {
    return sessionStorage.setItem('className', className)
  },
  getClassName() {
    return sessionStorage.getItem('className')
  },
  setClassId(classId) {
    return sessionStorage.setItem('classId', classId)
  },
  getClassId() {
    return sessionStorage.getItem('classId')
  },
  setBreadcrumbData(breadcrumb) {
    return sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb))
  },
  getBreadcrumbData() {
    return JSON.parse(sessionStorage.getItem('breadcrumb'));
  },


  // setRolePermission(rolePermission) {
  //   return sessionStorage.setItem('rolePermission', rolePermission)
  // },
  // getRolePermission() {
  //   return sessionStorage.getItem('rolePermission')
  // },
  // getWorkType() {
  //   return sessionStorage.getItem('workType')
  // },
  // setWorkType(workType){
  //   return sessionStorage.setItem('workType', workType)
  // },
  // getJobTitle() {
  //   return sessionStorage.getItem('jobTitle')
  // },
  // setJobTitle(jobTitle){
  //   return sessionStorage.setItem('jobTitle', jobTitle)
  // },
  // getRoleList() {
  //   return sessionStorage.getItem('roleList')
  // },
  // setRoleList(roleList){
  //   return sessionStorage.setItem('roleList', roleList)
  // },
  // getLocationListList() {
  //   return sessionStorage.getItem('locationList')
  // },
  // setLocationListList(locationList){
  //   return sessionStorage.setItem('locationList', locationList)
  // },
}