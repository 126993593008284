<!--
 * @Description: 按钮
 * @Author: luozhong
 * @Date: 2020-11-19 23:38:02
-->
<template>
  <div class="base-button" :class="[type, { disabled }, { active: active }]" @click="click">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    click() {
      if (this.disabled) {
        return;
      }
      this.$emit('click');
    }
  }
};
</script>

<style lang="less" scoped>
.base-button {
  display: inline-block;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  font-family: OpenSans-Bold;
  color: #5f6368;
  font-size: 14px;
  &.primary,
  &.active {
    color: #1a73e8;
  }
  &.active {
    background-color: #e8f0fe;
    color: #1a73e8;
  }
  &:hover {
    background-color: #eeeeee;
    &.primary,
    &.active {
      background-color: #e8f0fe;
      color: #1a73e8;
    }
  }
  &.disabled {
    color: #b4b4b4;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
      color: #b4b4b4;
    }
  }
}
</style>
