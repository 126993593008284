<!-- checkbox-menu -->
<template>
  <div class="checkbox-indeterminate">
    <el-checkbox
      :indeterminate="isAllSty"
      v-model="isAll"
      @change="handleCheckAllChange"
	  disabled
      >{{ checkTitle }}</el-checkbox
    >
    <el-checkbox-group v-model="selectedData" :min="1"  disabled>
      <el-checkbox
        v-for="(item, index) in checkboxList"
        :label="item.name"
      >
      </el-checkbox>
	
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "CheckboxIndeterminate",
  components: {},
  props: {
    checkTitle: {
      type: String,
      default: "",
    },
    checkedData: {
      type: Array,
      default: () => {},
    },
    checkboxList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      selectedData: this.checkedData,
      isAllSty: true,
    };
  },
  computed: {
    isAll: {
      get() {
		  console.log(this.checkboxList)
		  console.log(this.selectedData)
        if (this.selectedData.length === this.checkboxList.length) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    handleCheckAllChange(val) {
      this.selectedData = val ? this.checkboxList : [];
      this.isAllSty = false;
      this.checkChange(this.selectedData);
    },
    handleCheckedItemChange(value) {
      let checkedNum = value.length;
      this.isAll = checkedNum === this.checkboxList.length;
      this.isAllSty = checkedNum > 0 && checkedNum < this.checkboxList.length;
      this.checkChange(this.selectedData);
    },
    checkChange(val) {
      this.$emit("check", val);
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.checkbox-indeterminate {
  .el-checkbox-group {
    padding-left: 34px;
  }
  /deep/.el-checkbox {
    display: block;
    padding: 8px 0;
    color: #202124;
    font-family: Roboto;
    font-size: 16px;
    .el-checkbox__label {
      padding-left: 15px;
    }
    .el-checkbox__inner {
      width: 24px;
      height: 24px;
      border: 0;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: transparent;
      border-color: transparent;
    }
    .el-checkbox__inner::after {
      top: 6px;
      left: 10px;
      width: 6px;
      height: 8px;
      border-color: #b4b4b4;
      transform: rotate(45deg) scaleY(1.5);
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #202124;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner::after {
      border-color: #1a73e8 !important;
    }
    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 6px;
      left: 10px;
      box-sizing: content-box;
      border: 1px solid #b4b4b4;
      border-left: 0;
      border-top: 0;
      width: 6px;
      height: 8px;
      transform: rotate(45deg) scaleY(1.5);
      transition: transform 0.15s ease-in 0.05s;
      transform-origin: center;
    }
  }
}
/deep/.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner::before{
	background-color: transparent;
}

</style>