<!--
 * @Description: 统一列表组件
 * @Author: luozhong
 * @Date: 2020-11-15 23:23:03
-->
<template>
  <div class="base-list">
    <p class="title">{{ title }}</p>
    <ul>
      <li v-if="!isMainEmpty" class="main-li">
        <div class="left-column">
          <div class="main-column">
            <i
              class="iconfont icon"
              :style="{ color: mainData.leading.color || '' }"
              :class="mainData.leading.icon"
            ></i>
            <span>{{ mainData.leading.text }}</span>
          </div>
          <div
            v-for="(item, index) in mainData.list"
            :key="index"
            :class="{ 'center-main-column': item.isMain }"
          >
            <p class="main-text">{{ item.text }}</p>
            <p class="sub-text">{{ item.subText }}</p>
          </div>
        </div>
        <div class="last-column">
          <p class="main-text">{{ mainData.trailing.text }}</p>
          <p class="sub-text">{{ mainData.trailing.subText }}</p>
        </div>
      </li>
      <li class="sub-li" v-for="(item, index) in data" :key="index" @click="$emit('onClickItem', item)">
        <div class="left-column">
          <div class="main-column">
            <template v-if="item.leading.icon">
              <img v-if="urlRegExp.test(item.leading.icon)" :src="item.leading.icon" class="icon" alt="">
              <i
                v-else
                class="iconfont icon"
                :style="{ color: item.leading.color || '' }"
                :class="item.leading.icon"
              ></i>
            </template>
            
            <span
              v-else
              class="icon-text"
              :style="{ backgroundColor: item.leading.bgColor || '' }"
              >{{ item.leading.text.toUpperCase() | getIconText }}</span
            >
            <span>{{ item.leading.text }}</span>
          </div>
		  <div class="statusBox" v-if="type==1&&item.classStatus">
			  <div class="status" :class="item.classStatus=='closed'?'statusRed':''">
						  {{item.classStatus.slice(0, 1).toUpperCase()}}{{item.classStatus.slice(1)}}
						  
			  </div>
		  </div>
		 <div class="locationBox" v-else>
		 	
		 </div>
          <div
            v-for="(ele, index) in item.list"
            :key="index"
            :class="{ 'center-main-column': ele.isMain }"
          >
            <p class="main-text">{{ ele.text }}</p>
            <p class="sub-text">{{ ele.subText }}</p>
          </div>
        </div>
        <div class="last-column">
          <p class="main-text">{{ item.trailing.text }}</p>
          <p class="sub-text">{{ item.trailing.subText }}</p>
        </div>
      </li>
    </ul>
    <slot v-if="isMainEmpty && data.length==0" name="empty"></slot>
  </div>
</template>

<script>
// const urlRegExp = new RegExp(/(http|https):\/\/([\w.]+\/?)\S*/)
export default {
  name: 'baseList',
  data(){
    return {
      urlRegExp: new RegExp(/(http|https):\/\/([\w.]+\/?)\S*/)
    }
  },
  props: {
    title: {
      type: String,
      default: 'title'
    },
	type: {
	  type: Number,
	  default: 0
	},
    // 顶部汇总数据
    mainData: {
      type: Object,
      default: () => {}
    },
    // 列表数据
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isMainEmpty() {
      if (Object.prototype.toString.call(this.mainData) !== '[object Object]') {
        return true;
      }
      return Object.keys(this.mainData).length === 0;
    }
  },
  filters: {
    getIconText(text) {
      if (!text) {
        return '';
      }
      const arr = text.split(' ');
      const first = arr[0].substr(0, 1);
      if (arr.length > 1) {
        const last = arr[arr.length - 1].substr(0, 1);
        return `${first}${last}`;
      }
      return first;
    }
  }
};
</script>

<style lang="less" scoped>
.base-list {
  width: 100%;
  .title {
    height: 64px;
    border-bottom: 1px solid #e3e3e3;
    color: #202124;
    font-size: 22px;
    font-weight: 600;
    line-height: 64px;
    font-family: OpenSans-Bold;
    margin-left: 10px;
  }
  .main-li {
    margin-bottom: 92px;
  }
  ul li {
    // &:nth-child(2) {
    //   border-bottom: none;
    // }
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 92px;
    box-sizing: border-box;
    border-bottom: 1px solid #e3e3e3;
    cursor: pointer;
    .main-text {
      font-family: OpenSans-Bold;
      font-size: 20px;
      color: #202124;
    }
    .sub-text {
      margin-top: 4px;
      font-family: Roboto;
      font-size: 14px;
      color: #b4b4b4;
    }
    &:hover {
      position: relative;
      &::after {
        position: absolute;
        z-index: 0;
        pointer-events: none;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
      }
    }
  }
  .sub-li {
    // border-top: 1px solid #e3e3e3;
  }
  .left-column {
    display: flex;
    align-content: center;
    .center-main-column {
      margin-right: 60px;
      margin-left: 0;
    }
    > div + div {
      margin-left: 20px;
    }
  }
  .main-column {
    display: flex;
    align-items: center;
    width: 312px;
    // margin-right: 80px;
    font-family: "OpenSans-Bold";
    span {
      color: #5F6368;
      font-size: 20px;
    }
    .icon {
      margin-right: 20px;
      // border-radius: 50%;
      overflow: hidden;
      width: 32px;
      height: 32px;
      font-size: 32px;
      text-align: center;
      line-height: 32px;
    }
    .icon-text {
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 36px;
      height: 36px;
      margin-right: 20px;
      font-size: 12px;
      font-family: 'opensans-semibold';
    }
  }
}
.locationBox{
	width: 148px;
	height: 32px;
}
.status{
	width: 68px;
	height: 32px;
	border: 1px solid rgba(61, 177, 117, 1);
	border-radius: 8px;
	opacity: 1;
	margin-right: 80px;
	color: rgba(61, 177, 117, 1);
	font-size: 14px;
	font-family: "OpenSans-Bold";
	
	text-align: center;
	line-height: 32px;
	vertical-align: middle;
}
.statusRed{
	border: 1px solid rgba(234, 67, 53, 1);
	color: rgba(234, 67, 53, 1);
}
.statusBox{
	display: flex;
	align-items: center;
}
</style>
