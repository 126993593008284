<template>
  <div class="gg_chips">
    <md-field class="md-chips" :class="[$mdActiveTheme, chipsClasses]">
      <slot />
      <md-chip
        class="gg_chip"
        v-for="(chip, key) in value"
        :key="chip.id"
        :md-deletable="!mdStatic"
        :md-clickable="!mdStatic"
        :md-duplicated="false"
        @keydown.enter="$emit('md-click', chip, key)"
        @click.native="$emit('md-click', chip, key)"
        @md-delete.stop="removeChip(chip)"
      >
        <slot name="md-chip" :chip="chip" v-if="$scopedSlots['md-chip']">{{ chip }}</slot>
        <template v-else>{{ chip }}</template>
      </md-chip>

      <md-input
        ref="input"
        v-model.trim="inputValue"
        v-if="!mdStatic && modelRespectLimit"
        :type="mdInputType"
        :id="id"
        :placeholder="mdPlaceholder"
        @keydown.8="handleBackRemove"
      >
      </md-input>
    </md-field>
  </div>
</template>

<script>
//   @keydown.enter="valueChange"  @input="handleInput"
/**
 * @prop { filter } String 移除keydown.enter,input事件，移除valueChange事件，现在传递一个filter.sync进行控制
 */
import MdComponent from '../common/MdComponent';
import MdUuid from '../common/utils/MdUuid';
import MdPropValidator from '../common/utils/MdPropValidator';

export default new MdComponent({
  name: 'GgChips',
  props: {
    value: Array,
    id: {
      type: [String, Number],
      default: () => 'md-chips-' + MdUuid()
    },
    mdInputType: {
      type: [String, Number],
      ...MdPropValidator('md-input-type', [
        'email',
        'number',
        'password',
        'search',
        'tel',
        'text',
        'url'
      ])
    },
    mdPlaceholder: [String, Number],
    mdStatic: Boolean,
    mdLimit: Number,
    mdCheckDuplicated: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    }
  },
  data: () => ({}),
  computed: {
    chipsClasses() {
      return {
        'md-has-value': this.value && this.value.length
      };
    },
    modelRespectLimit() {
      return !this.mdLimit || this.value.length < this.mdLimit;
    },
    inputValue: {
      get() {
        return this.filter;
      },
      set(val) {
        this.$emit('update:filter', val);
      }
    }
  },
  methods: {
    removeChip(chip) {
      const index = this.value.indexOf(chip);

      this.value.splice(index, 1);
      this.$emit('input', this.value);
      this.$emit('md-delete', chip, index);
      this.$nextTick(() => this.$refs.input.$el.focus());
    },
    handleBackRemove() {
      if (!this.inputValue) {
        this.removeChip(this.value[this.value.length - 1]);
      }
    }
    // handleInput() {
    //   if (this.mdCheckDuplicated) {
    //     this.valueChange();
    //   }
    // },
    // valueChange() {
    //   this.$emit("change", this.inputValue);
    // }
  }
});
</script>

<style lang="less" scoped>
.gg_chips {
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  .md-chips.md-field {
    padding: 12px 0 0 0;
    margin-bottom: 0;
    .md-input {
      &::placeholder {
        font-size: 14px;
      }
      font-size: 14px;
    }
    /deep/ .gg_chip {
      border: 1px solid rgba(224, 224, 224, 1);
      background: #fff;
      height: 34px;
      line-height: 32px;
      border-radius: 16px;
      font-size: 14px;
      color: #202124;
      margin-right: 4px;
      & + .gg_chip {
        margin-left: 0;
      }
      > .md-ripple {
        padding-right: 38px;
        line-height: 24px;
      }
      > .md-clear {
        margin-left: 8px;
        width: 24px;
        height: 24px;
        background: #5f6268;
        .md-button-content {
          height: 20px;
          .md-icon {
            height: 20px;
            line-height: 20px;
            min-width: 20px;
          }
        }
      }
    }
    &::before {
      height: 1px;
      background: #1a73e8;
    }
  }
}
</style>
