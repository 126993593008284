import objectPath from 'object-path';

export default {
  props: ['vnode', 'data', 'index', 'defaultValue'],
  name: 'EdoovoTableColumn',
  functional: true,
  /**
   * 优先渲染插槽，其次渲染 props对应的数据，否则渲染默认值
   * @param { function } h render组件的方法
   * @param { object } context 页面context属性，包含各种需要render的属性
   * @returns { void }
   */
  // eslint-disable-next-line complexity
  render(h, context) {
    const columnOption = context.props.vnode.componentOptions;
    const columnData = context.props.vnode.data;

    const columnProps = columnOption.propsData;

    if (columnProps.tooltip !== false && typeof columnProps.tooltip !== 'function') {
      columnProps.tooltip = true;
    }

    const defaultScopedSlots = context.props.vnode.data.scopedSlots
      ? context.props.vnode.data.scopedSlots.default
      : undefined;

    const cellData = { row: context.props.data, $index: context.props.index };

    /** 是否使用 tooltip：在非使用插槽和默认值的前提下使用 */
    let canUseTooltip = false;

    // 优先使用作用域插槽，否则使用默认插槽
    let children = defaultScopedSlots ? defaultScopedSlots(cellData) : columnOption.children;

    const value = objectPath.get(context.props.data, columnProps.prop);
    let text = '';
    if (columnProps.getTooltipText && typeof columnProps.getTooltipText === 'function') {
      text = columnProps.getTooltipText(context.props.data);
    }
    const tooltipText = text || value || '';

    // 如果都没有读取prop对应的值
    if (!children) {
      canUseTooltip = value !== null && value !== undefined;

      /** 读取默认值 column上的设置如果有设置，会覆盖 table上的设置 */
      children =
        value === null || value === undefined || value === ''
          ? columnProps.defaultValue || context.props.defaultValue || ''
          : value;
      if (columnProps.formatter) {
        children = columnProps.formatter(cellData.row, columnOption, value, cellData.$index);
      }
    }

    const renderChildren = h(
      'div',
      {
        class: `edoovo-table-cell-inner ${columnData.staticClass || ''}`,
        attrs: columnData.attrs,
        style: columnData.style,
        on: Object.keys(columnOption.listeners || {}).reduce(
          (p, key) => ({
            ...p,
            [key](...e) {
              columnOption.listeners[key](cellData, ...e);
            }
          }),
          {}
        )
      },
      children
    );
    // columnProps.tooltip !== undefined && columnProps.tooltip !== null && canUseTooltip
    if (true) {
      const tooltip =
        typeof columnProps.tooltip === 'function'
          ? columnProps.tooltip(cellData)
          : columnProps.tooltip;

      // TODO
      if (tooltip && typeof tooltipText !== 'object' && !!tooltipText) {
        return h(
          'el-tooltip',
          {
            props: {
              'visible-arrow': false,
              effect: 'light',
              'popper-class': 'edoovo-table-cell-tooltip',
              placement: 'bottom-start',
              content: `${tooltipText}`
            }
          },
          [renderChildren]
        );
      }
    }

    return renderChildren;
  }
};
