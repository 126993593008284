<template>
  <md-snackbar
    :md-position="position"
    :md-duration="actionText ? Infinity : duration"
    :md-active.sync="_visible"
    md-persistent
    :class="{
      edoovoSnackbar: true,
      edoovoSnackbarhtml: html,
      edoovoSnackbarConfirm: asComfirm
    }"
  >
    <div class="left" v-if="html" v-html="content"></div>
    <div class="left" v-else>{{ content }}</div>
    <div class="right" v-if="actionText">
      <div class="mdButton" @click="closeFn">{{ actionText }}</div>
    </div>
  </md-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    html: {
      type: Boolean,
      default: false
    },
    asComfirm: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 3000
    },
    content: {
      type: String,
      default: ''
    },
    actionText: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    position: 'center',
    isInfinity: false
  }),
  computed: {
    _visible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  methods: {
    closeFn() {
      console.log('closeFn');
      this._visible = false;
      this.$$emit('callback');
    }
  }
};
</script>

<style lang="less">
.edoovoSnackbar.md-snackbar {
  width: 344px;
  padding: 8px 16px;
  min-height: 52px;
  border-radius: 4px 4px 0px 0px;
  background-color: #202124;
  .md-snackbar-content {
    .left {
      flex: 1;
      font-family: Roboto;
      font-size: 14px;
      color: #e0e0e0;
      word-break: normal;
    }
    .right {
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .mdButton {
        font-family: OpenSans-Bold;
        font-size: 14px;
        color: #67a1ff;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
.edoovoSnackbarhtml.edoovoSnackbar.md-snackbar {
  padding: 16px;
  .md-snackbar-content {
    .right {
      align-items: center;
    }
  }
}
.edoovoSnackbarConfirm.edoovoSnackbar.md-snackbar {
  padding: 0;
  max-height: 112px;
  .md-snackbar-content {
    flex-direction: column;
    align-items: stretch;
    .left {
      padding: 16px;
    }
    .right {
      padding: 0 8px 8px 16px;
    }
  }
}
</style>
