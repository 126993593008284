import store from '@/store'
/**
 * 格式化数组
 * @param {*} actual actual数组
 */
const cleanArray = (actual) => {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * object对象转换成url后面拼接的param
 * @param {*} obj obj对象
 */
const obj2Param = (obj) => {
  if (!obj) return ''
  return cleanArray(
    Object.keys(obj).map(key => {
      if (obj[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    })
  ).join('&')
}

/**
 * url后面带的param转换成object对象
 * @param {*} url url
 */
const param2Obj = (url) => {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  )
}

/**
 * 格式化时间
 * @param {*} time 时间
 * @param {*} cFormat 格式化格式
 */
const formatTime = (time, cFormat) => {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

const getDateNowByAmPm = () => {
  let now = new Date;
  // let y = now.getFullYear();
  // let m = now.getMonth();
  // let d = now.getDate();
  let h = now.getHours();
  let mm = now.getMinutes();
  let s = now.getSeconds();
  let str;
  if (h > 12) {
    h -= 12;
    str = " PM";
  } else {
    str = " AM";
  }
  h = h < 10 ? "0" + h : h;
  // d = d < 10 ? "0" + d : d;
  // m = m < 10 ? "0" + m : m;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  let xy = h + ":" + mm + ":" + s;
  xy += str;
  return xy;
};

// 格式化字符串，首字母大写
const firstUpperCase = (str) => {
  if (str) return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
};
// 按钮权限，在全局注册userType == 2是staff登录
const buttonPermission = (moduleName, buttonName) => {
  let rolePermission = store.state.user.rolePermission || [];
  let userType = store.state.user.userType
  let permissonArr = rolePermission.filter(
    (item) => item.indexOf(moduleName) > -1
  );
  return userType != '2' || (userType == '2' && permissonArr.indexOf(`${moduleName}_${buttonName}`) > -1);
}
const escape2Html = (str) => {
				var arrEntities = {
					'lt': '<',
					'gt': '>',
					'nbsp': ' ',
					'amp': '&',
					'quot': '"',
					' #39':"'",
					' #40':'(',
					' #41':')',
				};
				if(str){
							return str.replace(/&(lt|gt|nbsp|amp|quot| #39| #40| #41);/ig, function(all, t) {return arrEntities[t];});
				}
		
			}
export default {
  obj2Param,
  param2Obj,
  formatTime,
  getDateNowByAmPm,
  firstUpperCase,
  buttonPermission,
  escape2Html
}