import { Auth } from '@/common'

const breadcrumb = {
    state: {
        breadcrumbData: Auth.getBreadcrumbData(),
    },
    mutations: {
        SET_BREADCRUMDATA: (state, breadcrumb) => {
            state.breadcrumbData = breadcrumb
            Auth.setBreadcrumbData(breadcrumb)
        }
    }
}
export default breadcrumb