<template>
  <div class="filter">
    <div class="filter-left">
      <div class="userSelect">
        <template v-for="(item, index) in userSelectData">
          <div class="userSelect-item" :key="index">
            <div class="userSelect-item-left">
              {{ `${item.title || item.filterKey}: "${item.showVal}"` }}
            </div>
            <div class="userSelect-item-right">
              <span @click="_deleteUserSelect(item)" class="iconfont iconcancel-24px1" />
            </div>
          </div>
        </template>
        <div class="addFilterBox">
          <!-- 下拉弹窗 -->
          <el-popover
            popper-class="addFilterPopover"
            trigger="click"
            placement="bottom-start"
            v-model="isPopoverVisible"
            :disabled="stopUse || isStop"
          >
            <div class="popoverList">
              <!-- filter列表 -->
              <template v-for="(item, index) in renderData">
                <div
                  @click="_selectCurrentItem(item, disabledKeys.includes(item.filterKey))"
                  class="popoverList-item"
                  :class="{
                    selectDisabled: disabledKeys.includes(item.filterKey)
                  }"
                  :key="index"
                >
                  {{ item.title || item.filterKey }}
                </div>
              </template>
            </div>
            <!-- 新增按钮 -->
            <div slot="reference" @click="toWatch">
              <div class="addFilter">
                <div class="addFilterBtn">
                  <span class="iconfont iconadd" />
                  <span>Add a filter</span>
                </div>
              </div>
            </div>
          </el-popover>
          <!-- 新增时编辑filter -->
          <div v-if="isAddFilterEditShow" class="addFilterEdit">
            <div class="addFilterEdit-title">
              <span>{{ renderData_current.title || renderData_current.filterKey }}</span>
            </div>
            <div class="addFilterEdit-main">
              <div class="addFilterEdit-main-formBox">
                <!-- 输入框 -->
                <div
                  v-if="renderData_current.type === 'input'"
                  class="inputBox"
                  @keyup.enter="addApply"
                >
                  <div class="inputBox-label">
                    {{
                      renderData_current.label
                        ? renderData_current.label
                        : renderData_current.inputType === 'number'
                        ? 'Fill in'
                        : 'Contains'
                    }}
                  </div>
                  <el-input
                    v-model="inputVal"
                    :type="renderData_current.inputType || 'text'"
                    placeholder
                    ref="input"
                  />
                </div>
                <!-- 单选框 -->
                <div v-if="renderData_current.type === 'radio'" class="radioBox">
                  <template v-for="(radioItem, index) in renderData_current.radioData">
                    <el-radio v-model="radioVal" :label="radioItem.value" :key="index">
                      {{ radioItem.key }}
                    </el-radio>
                  </template>
                </div>
                <!-- 复选框 -->
                <div v-if="renderData_current.type === 'checkbox'" class="checkboxBox">
                  <el-checkbox-group v-model="checkVal">
                    <el-checkbox
                      v-for="checkItem in renderData_current.checkboxData"
                      :label="checkItem.key"
                      :key="checkItem.key"
                    >
                      {{ `${checkItem.value}` }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <!-- 日历 -->
                <div v-if="renderData_current.type === 'date'" class="dateBox">
                  <base-date-picker
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    v-model="dateVal"
                    type="date"
                    placeholder="Select date"
                  />
                </div>
                <!-- 时间选择 -->
                <div v-if="renderData_current.type === 'time'" class="dateBox">
                  <el-time-select
                    v-model="timeVal"
                    :picker-options="{
                      start: '07:00',
                      step: '00:05',
                      end: '23:30'
                    }"
                    placeholder="Select time"
                  >
                  </el-time-select>
                </div>
              </div>
              <div class="applyBox">
                <el-button type="text" @click="_addApply" class="apply" :disabled="canApply">
                  APPLY
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-right">
      <!-- 清空筛选 -->
      <!-- <div v-if="userSelectData.length" @click="_clear" class="clearBtn">
        Clear filter
      </div> -->
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

export default {
  data() {
    return {
      inputVal: '',
      radioVal: '',
      checkVal: [],
      dateVal: '',
      timeVal: '',
      isStop: false,
      userSelectData: [],
      isPopoverVisible: false,
      isAddFilterEditShow: false,
      renderData_current: {}
    };
  },

  props: {
    renderData: {
      type: Array,
      default() {
        return [];
      }
    },
    multKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    stopUse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disabledKeys() {
      let keys = [];
      this.userSelectData.forEach((item) => {
        if (!this.multKeys.includes(item.filterKey)) {
          keys.push(item.filterKey);
        }
      });
      return keys;
    },
    canApply() {
      switch (this.renderData_current.type) {
        case 'input':
          return !this.inputVal;
        case 'radio':
          return !this.radioVal;
        case 'checkbox':
          return !this.checkVal.length;
        case 'date':
          return !this.dateVal;
        case 'time':
          return !this.timeVal;
        default:
          return true;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this._clickOtherHideFilterEdit);
  },
  destroyed() {
    document.removeEventListener('click', this._clickOtherHideFilterEdit);
  },
  methods: {
    toWatch() {
      // 监听 userSelectData 和 renderData一样时候 提示不能再选
      if (this.userSelectData.length === this.renderData.length) {
        // this.isStop = true
      } else {
        this.isStop = false;
      }
    },
    _clickOtherHideFilterEdit(e) {
      const addFilterEditEl = document.querySelector('.addFilterEdit');
      if (!addFilterEditEl) {
        return;
      }
      const target = e.target;
      if (!addFilterEditEl.contains(target)) {
        this.isAddFilterEditShow = false;
      }
    },
    _selectCurrentItem(item, disabled) {
      if (disabled) {
        return;
      }
      this.renderData_current = item;
      this.isPopoverVisible = false;
      this.isAddFilterEditShow = true;
      if (item.type === 'input') {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    /**
     * 输入框输入之后enter执行apply
     * @returns { void }
     */
    addApply() {
      if (!this.canApply) {
        this._addApply();
      }
    },
    _addApply() {
      const { type, filterKey, radioData, title } = this.renderData_current;
      let newData = null;
      if (type === 'input') {
        newData = {
          showVal: this.inputVal,
          filterVal: this.inputVal
        };
      } else if (type === 'radio') {
        newData = {
          showVal: radioData.find((item) => item.value === this.radioVal).key,
          filterVal: this.radioVal
        };
      } else if (type === 'checkbox') {
        const checkArr = this.renderData_current.checkboxData.filter((item) =>
          this.checkVal.includes(item.key)
        );
        newData = {
          showVal: checkArr.map((item) => item.value).join('" or "'),
          filterVal: this.checkVal
        };
      } else if (type === 'date') {
        newData = {
          showVal: this.dateVal,
          filterVal: this.dateVal
        };
      } else if (type === 'time') {
        newData = {
          showVal: this.timeVal,
          filterVal: this.timeVal
        };
      }
      newData.filterKey = filterKey;
      newData.title = title || filterKey;
      if (
        newData.showVal &&
        newData.showVal.length &&
        !this.userSelectData.some((item) => {
          return isEqual(item, newData);
        })
      ) {
        this.userSelectData = [...this.userSelectData, newData];
        this._filter();
      }
      this.isAddFilterEditShow = false;
      this.inputVal = '';
      this.radioVal = '';
      this.checkVal = [];
      this.dateVal = '';
      this.timeVal = '';
    },
    _deleteUserSelect(deleteData) {
      this.userSelectData = this.userSelectData.filter((item) => !isEqual(item, deleteData));
      this._filter();
    },
    _clear() {
      this.userSelectData = [];
      this._filter();
    },
    _filter() {
      this.$emit('filterCallback', this.userSelectData);
    }
  }
};
</script>

<style lang="less" scoped>
.filter {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .userSelect {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        border-radius: 20px;
        background: rgba(224, 224, 224, 1);
        box-sizing: border-box;
        padding: 0 20px;
        margin-right: 8px;
        box-sizing: border-box;
        &-left {
          margin-right: 20px;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 500;
          color: rgba(95, 99, 104, 1);
          white-space: nowrap;
          max-width: 272px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-right {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 20px;
          line-height: 20px;
          color: rgba(95, 99, 104, 1);
        }
      }
    }
    .addFilterBox {
      position: relative;
      .addFilter {
        position: relative;
        .addFilterBtn {
          cursor: pointer;
          width: 124px;
          height: 32px;
          background: rgba(255, 255, 255, 1);
          border: 1px dashed #e0e0e0;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 500;
          color: rgba(32, 33, 36, 0.5);
          .iconfont {
            color: rgba(32, 33, 36, 0.5);
          }
        }
        .addFilterMark {
          width: 124px;
          height: 32px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
        }
      }

      .addFilterEdit {
        width: 240px;
        min-height: 204px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(232, 232, 232, 1);
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        position: absolute;
        top: -14px;
        left: 0;
        z-index: 1000;
        border-radius: 8px;
        overflow: hidden;
        &-title {
          box-sizing: border-box;
          height: 64px;
          line-height: 64px;
          background: rgba(26, 115, 232, 1);
          padding-left: 20px;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          position: relative;
          .close {
            cursor: pointer;
            color: #fff;
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 20px;
            line-height: 20px;
          }
        }
        &-main {
          box-sizing: border-box;
          padding: 20px 0 16px;

          &-formBox {
            box-sizing: border-box;
            padding: 0 20px;
            max-height: 400px;
            overflow-y: auto;
            .inputBox {
              &-label {
                font-size: 14px;
                font-family: Roboto;
                font-weight: 500;
                color: rgba(26, 115, 232, 1);
              }
              /deep/.el-input__inner {
                border-radius: 0;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 2px solid rgba(26, 115, 232, 1);
              }
              /deep/input {
                padding: 0 !important;
                color: #707070;
              }
            }
            .radioBox {
              /deep/.el-radio {
                display: block;
                margin-bottom: 8px;
                font-size: 14px;
                font-family: Roboto;
                font-weight: 500;
                color: rgba(68, 84, 105, 1);
              }
            }
            .checkboxBox {
              /deep/.el-checkbox {
                display: block;
                font-size: 14px;
                font-family: Roboto;
                font-weight: 400;
                color: rgba(32, 33, 36, 1);
                margin-bottom: 8px;
              }
            }
          }
          .applyBox {
            display: flex;
            justify-content: flex-end;
            padding: 16px 16px 0;
            .apply {
              font-size: 14px;
              font-family: Roboto;
              font-weight: 400;
              color: rgba(26, 115, 232, 1);
              padding: 7px 12px;
              &.is-disabled {
                color: #c0c4cc;
              }
            }
          }
        }
      }
    }
  }
  &-right {
    .clearBtn {
      cursor: pointer;
      font-size: 16px;
      font-family: Roboto;
      font-weight: 500;
      color: rgba(0, 162, 129, 1);
      white-space: nowrap;
    }
  }
}
</style>
<style lang="less">
.el-popover.addFilterPopover {
  padding: 0;
  border: none;
  // margin-top: 0 !important;
  /deep/.popper__arrow {
    display: none;
  }
  .popoverList {
    box-sizing: border-box;
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0px 3px 3px #0000004d;
    &-item {
      user-select: none;
      cursor: pointer;
      box-sizing: border-box;
      padding: 0 16px;
      height: 48px;
      line-height: 48px;
      background-color: #fff;
      min-width: 240px;
      font-size: 14px;
      font-family: Roboto;
      font-weight: 400;
      color: rgba(32, 33, 36, 1);
      &:hover {
        background-color: rgba(238, 238, 238, 1);
      }
    }
    .selectDisabled {
      cursor: not-allowed;
      // pointer-events: none;
    }
  }
}
</style>
