<script>
// footerLine 为true的时候，footer有一根分割线
export default {
  name: `GgDialog`,
  functional: true,
  render(createElement, context) {
    const _slots = context.slots();
    const footerLine = context.props.footerLine || true;
    return createElement(
      `el-dialog`,
      {
        directives: context.data.directives,
        on: context.data.on,
        props: {
          appendToBody: true,
          closeOnClickModal: true,
          showClose: false,
          destroyOnClose: true,
          footerLine,
          ...context.props,
        },
        class:
          (context.data.staticClass
            ? context.data.staticClass + " gg_dialog"
            : "gg_dialog") + (footerLine ? " gg_dialog_line" : ""),
      },
      [
        _slots.default,
        _slots.footer
          ? createElement(
              _slots.footer[0].tag,
              { slot: "footer" },
              _slots.footer[0].children
            )
          : null,
        _slots.title
          ? createElement(
              _slots.title[0].tag,
              { slot: "title" },
              _slots.title[0].children
            )
          : null,
      ]
    );
  },
};
</script>

<style lang="less" scoped>
.gg_dialog {
  /deep/ .el-dialog {
    border-radius: 8px;
    margin-bottom: 0;
    .el-dialog__header {
      padding: 0 24px;
      height: 64px;
      line-height: 64px;
      font-size: 16px;
      font-family: 'opensans-semibold';
      // font-weight: 600;
      color: #202124;
      border-bottom: 1px solid rgba(68, 84, 105, 0.2);
      .el-dialog__title {
        font-size: 16px;
        color: #202124;
      }
      .title-bold {
        font-family: OpenSans;
        font-weight: 600;
      }
    }
    .el-dialog__body {
      padding: 20px 24px;
    }
    .el-dialog__footer {
      height: 60px;
      box-sizing: border-box;
      padding-top: 12px;
    }
  }
  &.gg_dialog_line {
    /deep/ .el-dialog__footer {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }
}
</style>
