<template>
  <div class="imgCut">
    <div class="avatar-uploader" v-show="!isCropperShow">
      <el-upload
        drag
        action="'string'"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleCrop"
        :http-request="upload"
        :accept="acceptPdfFile ? 'image/jpeg,image/png,application/pdf' : 'image/jpeg,image/png'"
        list-type="picture-card"
		    :before-upload="beforeUpload"
      >
        <!-- 预览 -->
        <img
          v-if="imageUrl"
          :src="imageUrl"
          class="avatar"
          ref="singleImg"
          @mouseenter="mouseEnter"
          @mouseleave="mouseLeave"
          :style="{ width: width + 'px', height: height + 'px' }"
        />
        <!-- 上传文件操作 -->
        <div class="uploadMain" v-else>
          <i class="iconfont iconadd_a_photo" />
          <h5 class="desc">Drag an image file here</h5>
          <p class="desc">or</p>
          <div class="selectPhotoBtn">Upload image</div>
        </div>
        <div class="reupload" ref="uploading">上传中..</div>
        <div class="reupload" ref="failUpload">上传失败</div>
      </el-upload>
      <p class="prompt">The image file should be in PNG or JPEG{{isCut?' or PDF':''}} format.</p>
    </div>
    <!-- 剪裁组件弹窗 -->
    <div v-show="isCropperShow" class="cropperBox">
      <cropper
        :autoCropHeight="autoCropHeight"
        :autoCropWidth="autoCropWidth"
        :limitMinSize="limitMinSize"
        :img-file="file"
        ref="vueCropper"
        :fixedNumber="fixedNumber"
        :fixed="fixed"
        :fixedBox="fixedBox"
        @_sureUploadImgCallback="_sureUploadImgCallback"
        @upload="upload"
      />

      <p class="prompt">Drag the region above to crop this image.</p>
    </div>
  </div>
</template>
<script>
import Cropper from './cropper';
export default {
  props: {
    multiple: {
      // 多图开关
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: true
    },
    fixedBox: {
      type: Boolean,
      default: false
    },
    initUrl: {
      // 初始图片链接
      default: '',
      type: [String, Array]
    },
    boxwh: {
      // 上传控件宽高
      default: function () {
        return [230,208];
      },
      type: Array
    },
    fixedNumber: {
      // 剪裁框比例设置
      default: function () {
        return [1, 1];
      },
      type: Array
    },
    width: {
      // 单图剪裁框宽度
      type: Number,
      default: 178
    },
    height: {
      // 单图剪裁框高度
      type: Number,
      default: 178
    },
    comfirmText: {
      type: String,
      default: 'Set as profile photo'
    },
    acceptPdfFile: {
      type: Boolean,
      default: false
    },
    isCut: {
      type: Boolean,
      default: false
    },
    openCropper: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      autoCropHeight: '',
      autoCropWidth: '',
      limitMinSize: [],
      file: '', // 当前被选择的图片文件
      imageUrl: '', // 单图情况框内图片链接
      dialogImageUrl: '', // 多图情况弹窗内图片链接
      uploadList: [], // 上传图片列表
      reupload: true, // 控制"重新上传"开关
      dialogVisible: false, // 展示弹窗开关
      isCropperShow: false, // 剪裁组件弹窗开关
      reuploadWidth: this.height * 0.7 // 动态改变”重新上传“大小
    };
  },
  components: {
    Cropper
  },
  updated() {
    if (this.$refs.vueCropper) {
      this.$refs.vueCropper.Update();
    }
  },
  watch: {
    initUrl: function (val) {
      // 监听传入初始化图片
      if (val) {
        if (typeof this.initUrl === 'string') {
          this.imageUrl = val;
        } else {
          this.uploadList = this.formatImgArr(val);
        }
      }
    }
  },
  mounted() {
    if (typeof this.initUrl === 'string') {
      this.imageUrl = this.initUrl;
    } else {
      this.uploadList = this.formatImgArr(this.initUrl);
    }

    //直接打开裁剪控件
    if(this.openCropper){
      this.handleCrop(this.openCropper)
    }
  },
  methods: {
	  beforeUpload(file) { 
      const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
      if(!isLt2M) {
        this.$message({
          message: 'Upload files should not exceed 25MB',
          type: 'warning'
        });
      }
      return  isLt2M
    },
    mouseEnter() {
      //鼠标划入显示“重新上传”
      this.$refs.reupload.style.display = 'block';
      if (this.$refs.failUpload.style.display === 'block') {
        this.$refs.failUpload.style.display = 'none';
      }
      this.$refs.singleImg.style.opacity = '0.6';
    },
    mouseLeave() {
      // 鼠标划出隐藏“重新上传”
      this.$refs.reupload.style.display = 'none';
      this.$refs.singleImg.style.opacity = '1';
    },
    // 判断上传图片尺寸
    imageRatio(raw) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(raw)
        reader.onload = () => {
          let img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.naturalWidth && img.naturalHeight) {
              resolve([img.naturalWidth, img.naturalHeight])
            } else {
              resolve(false)
            }
          }
        }
      })
    },

    handleCrop(file) {
      // 获取上传图片尺寸
      // this.imageRatio(file.raw).then(async result => {
      //   if (result) {
      //     // 裁切框外框容器大小
      //     let b_w = 298,
      //     b_h = 196,

      //     // 上传图片大小
      //     w = result[0],
      //     h = result[1],

      //     // 图片宽高缩小比例
      //     w_l = (w > b_w) ? (w / b_w) : (w / w),
      //     h_l = (h > b_h) ? (h / b_h) : (h / h),

      //     // 采用宽或高的缩小比例
      //     bl = (w >= h) ? w_l : h_l,

      //     // 根据缩小比例计算的裁切框尺寸
      //     cropper_w = this.boxwh[0] * 3 / bl,
      //     cropper_h = this.boxwh[1] * 3 / bl

      //     // 如果裁切框的宽或高，大于或等于图片真实宽高
      //     if(cropper_w >= w || cropper_h >= h) { 
      //       if(w >= h) {
      //         this.autoCropWidth = h > b_h ? b_h : h
      //         this.autoCropHeight = h > b_h ? b_h : h
      //         this.limitMinSize = [h > b_h ? b_h / bl : h / bl, h > b_h ? b_h / bl : h / bl]
      //       }else{
      //         this.autoCropWidth = w > b_w ? b_w : w
      //         this.autoCropHeight = w > b_w ? b_w : w
      //         this.limitMinSize = [w > b_w? b_w / bl : w / bl, w > b_w ? b_w / bl : w / bl]
      //       }
      //     } 
          
      //     // 如果裁切框的宽或高，小于图片真实宽高
      //     else {
      //       let __w = cropper_w > w ? b_w : cropper_w,
      //       __h = cropper_h > h ? b_h : cropper_h

      //       console.log('预计裁切框尺寸：' + __w, __h)
            
      //       // 如果裁切框的宽高，大于裁切框外框容器宽高，采用外框容器宽高
      //       if(__w > b_w || __h > b_h) {
      //         if(__w < __h) {
      //           this.limitMinSize = [b_w, b_w]
      //           this.autoCropWidth = b_w
      //           this.autoCropHeight = b_w
      //         }else{
      //           this.limitMinSize = [b_h, b_h]
      //           this.autoCropWidth = b_h
      //           this.autoCropHeight = b_h
      //         }
      //       } 
      //       // 如果裁切框的宽高，小于裁切框外框容器宽高，采用裁切框宽高
      //       else {
      //         this.limitMinSize = [__w, __h]
      //         this.autoCropWidth = __w
      //         this.autoCropHeight = __h
      //       }
      //     }
          
      //     console.log('采纳：' + (cropper_w >= b_w || cropper_h >= b_h), '上传图片大小：' + result, '裁切框尺寸：' + [cropper_w, cropper_h], '缩小比例：' + bl, '最综裁切框尺寸：' + this.limitMinSize)
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })

		  const isLt2M = file.size / 1024 / 1024 < 25     //这里做文件大小限制
      if(!isLt2M) {
        this.$message({
          message: 'Upload files should not exceed 25MB',
          type: 'warning'
        });
        return  isLt2M
      }
		if(this.isCut){
			this.$emit('_uploadPdfCallback', file)
			return
		}
      if (/\.pdf$/.test(file.name)) {
        this.$emit('_uploadPdfCallback', file)
        return
      }
      // 点击弹出剪裁框
      this.isCropperShow = true;
      this.file = file;
      this.$emit('uploadSuccess', file);
    },
    _sureUploadImg() {
      this.$refs.vueCropper._getImgData();
    },
    _sureUploadImgCallback(data) {
      let formData = new FormData();
      formData.append('file', data);
      this.$emit('_uploadCallback', formData, this.file);
    },
    upload() {},
    formatImgArr(arr) {
      const result = arr.map((item, index) => {
        if (typeof item === 'string') {
          return {
            url: item,
            uid: `index${index}`
          };
        }
        return item.url;
      });
      return result;
    }
  }
};
</script>
<style lang="less" scoped>
.imgCut {
  font-family: Roboto;
  height: 287px;
  padding-top: 8px;
  text-align: center;
  .avatar-uploader {
    text-align: center;
    padding-top: 16px;
  }
  .prompt {
    color: #b4b4b4;
    font-size: 12px;
    margin-top: 28px;
    line-height: 16px;
  }
  /deep/.el-upload {
    width: auto;
    height: auto;
    border: none;
  }
  /deep/.el-upload-dragger {
    width: auto;
    height: auto;
    border: none;
  }
  .uploadMain {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h5.desc {
      font-size: 20px;
      font-weight: normal;
      line-height: 26px;
      color: #b4b4b4;
      margin: 18px 0;
    }
    p.desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: #202124;
      margin-bottom: 18px;
    }
    .iconfont {
      font-size: 75px;
      line-height: 75px;
      color: #aeb0b3;
    }
    .selectPhotoBtn {
      width: 120px;
      height: 32px;
      line-height: 32px;
      background: #1a73e8;
      text-align: center;
      border-radius: 4px;
      font-size: 13px;
      font-family: OpenSans-Bold;
      color: #ffffff;
    }
  }
  .cropperBox {
    box-sizing: border-box;
    &-title {
      font-size: 16px;
      font-weight: 400;
      color: rgba(68, 84, 105, 1);
      padding: 16px 32px;
      box-sizing: border-box;
    }
  }
  .footerBtnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
    box-sizing: border-box;
    .cancel {
      cursor: pointer;
      width: 128px;
      height: 32px;
      border: 1px solid rgba(68, 84, 105, 0.2);
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: rgba(68, 84, 105, 1);
    }
    .comfirm {
      cursor: pointer;
      width: 176px;
      height: 32px;
      background: rgba(0, 162, 129, 1);
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      margin-left: 32px;
    }
  }
}
// .avatar-uploader .el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
// .avatar-uploader .el-upload:hover {
//   border-color: #409eff;
// }
.avatar-uploader-icon {
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
}
.reupload {
  border-radius: 50%;
  position: absolute;
  color: #fff;
  background-color: #000000;
  opacity: 0.6;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
#uploadIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
</style>
