<template>
  <div class="borderless">
    <div v-for="(item, index) in data" :key="index" class="borderless-list">
      <div class="borderless-left">
        <div class="label">
          {{ item.label }}
        </div>
      </div>
      <div class="borderless-right">
        <base-img class="logo" v-if="item.label == 'Logo'" :src="item.value" />
        <div v-else class="value">
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BorderlessTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.borderless {
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  width: 492px;
  &-list{
    display: flex;
  }
  &-left {
    width: fit-content;
    margin-right: 20px;
    width: fit-content;
    .label {
      min-width: 150px;
      max-width: 220px;
      min-height: 20px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      color: #202124;
      margin-bottom: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-right {
    .logo{
      margin-bottom: 32px;
      width: 320px;
      height: 132px;
      border: 1px solid rgba(68, 84, 105, .2);
      border-radius: 4px;
      font-size: 0;
      line-height: 0;
    }
    .value {
      width: 312px;
      min-height: 20px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      color: #202124;
      word-break: break-all;
      margin-bottom: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
