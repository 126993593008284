<template>
  <div
    :class="{ user_avatar: true, text_head: !showSrc }"
    :style="
      lineHeight
        ? {
            lineHeight: lineHeight + 'px',
            height: lineHeight + 'px',
            width: lineHeight + 'px'
          }
        : {}
    "
  >
    <base-img fit="fill" v-if="showSrc" :src="showSrc">
      <div class="image-slot" slot="placeholder">{{ name }}</div>
      <div class="image-slot" slot="error">{{ name }}</div>
    </base-img>
    <span class="textPhoto" v-else :style="lineHeight==20?' font-size: 10px!important;':''">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'HeadImg',
  props: {
    src: {
      type: String,
      default: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    lineHeight: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    showSrc() {
      return this.src ? this.src + '?' + Date.now() : '';
    },
    name() {
      if (/[\u4e00-\u9fa5]/gi.test(this.firstName)) {
        const allName = `${this.lastName}${this.firstName}`;
        if (allName.length <= 2) {
          return allName;
        }
        return (this.firstName || '').substr(0, 2);
      }
      return (
        (this.firstName || '').substr(0, 1).toUpperCase() +
        (this.lastName || '').substr(0, 1).toUpperCase()
      );
    }
  }
};
</script>

<style lang="less" scoped>
.el-image {
  height: 100%;
  width: 100%;
}
.user_avatar {
  flex-shrink: 0;
  border-radius: 50%;
  background: #ddd;
  color: #fff;
  text-align: center;
  overflow: hidden;
  &.text_head {
    background: #b4b4b4;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  .textPhoto {
    // font-size: 20px;
    font-family: 'opensans-semibold';

    color: rgba(255, 255, 255, 1);
  }
}
.user_avatar span {
  // font-size: 22px;
}
</style>

<style scoped>
.user_avatar >>> .image-slot {
  background: transparent;
}
</style>
