<!-- dropdwonmenu -->
<template>
  <gg-submit-button @click="setShow" class="drop_down">
    <slot />
    <i
      class="iconfont iconup"
      :class="{
        downShowIcon: isDownShow,
        downHideIcon: !isDownShow
      }"
    />
    <div
      class="drop_down_menus"
      :class="{
        downShow: isDownShow,
        downHide: !isDownShow
      }"
    >
      <slot name="menus" />
    </div>
  </gg-submit-button>
</template>

<script>
export default {
  name: 'GgDropdown',
  data() {
    return {
      isDownShow: false
    };
  },
  methods: {
    setShow() {
      this.isDownShow = !this.isDownShow;
    }
  }
};
</script>
<style lang="less" scoped>
.drop_down {
  position: relative;
  font-family: Roboto;
  font-weight: normal;
  /deep/ .drop_down_menus {
    position: absolute;
    top: 36px;
    // transform: scaleY(0);
    overflow: hidden;
    right: 0;
    min-width: 100%;
    transform-origin: top;
    transition: transform 300ms;
    background: #ffffff;
    line-height: 32px;
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0px 3px 3px #0000004d;
    text-align: left;
    hr {
      border: none;
    }
    > * {
      font-family: Roboto;
      font-weight: normal;
      color: #202124;
      font-size: 14px;
      &.disabled {
        color: #b4b4b4;
        cursor: not-allowed;
        &:hover {
          color: #b4b4b4;
        }
      }
      &:hover {
        background: #eeeeee 0% 0% no-repeat padding-box;
      }
      padding: 0 16px;
    }
  }
  .iconup {
    transition: transform 0.5s;
    display: inline-block;
    vertical-align: middle;
  }
  .downHide {
    transform: scaleY(0);
  }
  .downShow {
    transform: scaleY(1);
  }
  .downHideIcon{
	  // 0319 cy修改注释 让箭头永远朝下
	  transform: rotate(180deg);
  }
  .downShowIcon {
	 // 0319 cy修改注释 让箭头永远朝下
    transform: rotate(180deg);
  }
  &:hover {
    // .drop_down_menus {
    //   transform: scaleY(1);
    // }
    // .iconup {
    //   transform: rotate(180deg);
    // }
  }
}
</style>
