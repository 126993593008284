<script>
export default {
  name: `BaseImg`,
  functional: true,
  render(createElement, context) {
    return context.parent.$createElement(
      `el-image`,
      {
        directives: context.data.directives,
        on: context.data.on,
        props: {
          fit: 'cover',
          ...context.props
        },
        class: (context.data.staticClass || '') + ' base_img',
        style: {
          width: context.props.width,
          lineHeight: context.props.lineHeight,
          ...context.data.staticStyle
        }
      },
      context.children
    );
  }
};
</script>

<style scoped></style>
