import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import user from './modules/user'
import moments from './modules/moments'
import breadcrumb from './modules/breadcrumb'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters,
  modules: {
    user,
    breadcrumb,
    moments
  }
})
