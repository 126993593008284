<!--
 * @Description: 面包屑导航，基于element 面包屑组件
 * @Author: luozhong
 * @Date: 2020-07-07 10:40:07
-->
<template>
  <el-breadcrumb separator-class="iconfont iconnavigate_next-px" class="edoovo-breadcrumb">
    <slot>
      <template v-for="(item, index) in data">
        <el-breadcrumb-item :key="index">
          <router-link
            v-if="item.routeName"
            @click.native="pageTo(item)"
            :to="{ name: item.routeName, query: item.query ? item.query : $route.query, params: item.params || {} }"
            :replace="item.replace || false"
            :tag="item.tag || 'a'"
          >
            {{ item.name }}
          </router-link>
          <router-link
            v-else-if="item.path"
            @click.native="pageTo(item)"
            :to="{ path: item.path }"
            :replace="item.replace || false"
            :tag="item.tag || 'a'"
          >
            {{ item.name }}
          </router-link>
          <template v-else>
            <span class="current-span">{{ item.name }}</span>
          </template>
        </el-breadcrumb-item>
      </template>
    </slot>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'BaseBreadcrumb',
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    },
    // separator: {
    //   type: String,
    //   default: '/'
    // }
  },
  data() {
    return {};
  },
  watch: {},
  filters: {},
  mounted() {},
  methods: {
    pageTo(res) {
      this.$emit('pageTo', res);
    }
  }
};
</script>

<style lang="less" scoped>
.edoovo-breadcrumb {
  font-family: Roboto;
  /deep/ .el-breadcrumb__inner,
  /deep/ .el-breadcrumb__inner a {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    font-family: Roboto;
    font-weight: normal;
  }
  /deep/ .el-breadcrumb__inner {
    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  /deep/ .el-breadcrumb__item {
    display: flex;
    align-items: center;
    i {
      color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      margin: 0 4px;
    }
  }
  /deep/ .el-breadcrumb__item:last-child {
    .el-breadcrumb__inner,
    .el-breadcrumb__inner a {
      font-family: Roboto;
      color: rgba(0, 0, 0, 1);
    }
  }
  /deep/ .el-breadcrumb__separator {
    margin-right: 6px;
    margin-left: 3px;
  }
}
</style>
