<!-- create button -->
<template>
  <div class="gg_create_button" ref="createBtn">
    <div class="createBtn" @click="clickBtn">
      <svg width="36" height="36" viewBox="0 0 36 36">
        <path fill="#34A853" d="M16 16v14h4V20z" />
        <path fill="#4285F4" d="M30 16H20l-4 4h14z" />
        <path fill="#FBBC05" d="M6 16v4h10l4-4z" />
        <path fill="#EA4335" d="M20 16V6h-4v14z" />
        <path fill="none" d="M0 0h36v36H0z" />
      </svg>
      <span class="text">{{ label }}</span>
    </div>
    <ul class="menu" :style="{ left: left, top: top }" v-if="menu.length" v-show="showMenu">
      <li v-for="(item, index) in menu" :key="index" @click="itemClick(item.click)">
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * menu存在的时候，会自动读取里面的label属性和click属性
 */
export default {
  name: 'GgCreateButton',
  props: {
    menu: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default: 'Create'
    },
    width: {
      type: String,
      default: 'fit-content'
    },
    left: {
      type: String,
      default: '136px'
    },
    top: {
      type: String,
      default: '0px'
    }
  },
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    clickBtn() {
      this.showMenu = true;
      this.$emit('click');
    },
    hideMenu(e) {
      if (!this.$refs.createBtn.contains(e.target)) {
        this.showMenu = false;
      }
    },
    itemClick(callback) {
      this.showMenu = false;
      callback && callback();
    }
  },
  mounted() {
    document.body.addEventListener('click', this.hideMenu);
  },
  destroyed() {
    document.body.removeEventListener('click', this.hideMenu);
  },
  components: {},

  computed: {}
};
</script>
<style lang="less" scoped>
.gg_create_button {
  position: relative;
  .createBtn {
    width: fit-content;
    cursor: pointer;
    height: 48px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(224, 224, 224, 1);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 24px;
    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
    .text {
      font-size: 14px;
      font-family: OpenSans-Bold;
      color: #5f6368;
      margin-left: 20px;
      white-space: nowrap;
    }
    &:hover {
      box-shadow: 0px 3px 6px #0000004d;
      background-color: #f7f8f8;
      .text {
        color: #202124;
      }
    }
  }
  > .menu {
    z-index: 20;
    position: absolute;
    left: 136px;
    top: 0;
    width: 168px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #0000004d;
    border-radius: 4px;
    overflow: hidden;
    > li {
      height: 32px;
      line-height: 32px;
      padding: 0 16px;
      cursor: pointer;
      color: #202124;
      font-size: 12px;
      font-family: Roboto;
      &:hover {
        background: #eeeeee 0% 0% no-repeat padding-box;
      }
    }
  }
}
</style>
