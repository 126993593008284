
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import { Auth, Utils } from '@/common'

/* 引入vue-material */
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import VueLazyload from 'vue-lazyload'
/* 引入element-ui */
import ElementUI from 'element-ui';
import enLocale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/css/index.css'
/* 自定义的组件 */
import Components from "@/components";
Object.keys(Components).forEach(compName => {
  Vue.component(compName, Components[compName]);
});

Vue.use(VueMaterial);
Vue.use(ElementUI,{ enLocale });
Vue.use(VueI18n)

const loadimage = require('./assets/images/blank.png')
const errorimage = require('./assets/images/blank.png')

Vue.use(VueLazyload, {
  preLoad: 1.1,
  error: errorimage,
  loading: loadimage,
  attempt: 2
})


Vue.config.productionTip = false



// Vue.material.locale.dateFormat = 'MM yyyy'

// example
// Auth.setToken('Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXJyZW50X2xvZ2luX2RhdGUiOiIxNjA1NzgwMzc2NzI1IiwidXNlcl9uYW1lIjoiYWRtaW5AZWRvb3ZvLmNvbSIsInNjb3BlIjpbInVzZXIiXSwiaWQiOiIxIiwicmVtZW1iZXJfbWUiOiJmYWxzZSIsImV4cCI6MTYwNTc5NDc4MSwiYXV0aG9yaXRpZXMiOlsiQVVUSF9TVFVERU5UUyIsIkFVVEhfUVVJWl9CQU5LIiwiQVVUSF9QQVJFTlRTIiwiQVVUSF9QUk9EVUNUUyIsIkFVVEhfQ0xBU1MiLCJBVVRIX1VTRVJTIiwiQVVUSF9PUkRFUlMiLCJBVVRIX0NVUlJJQ1VMQSIsIkFVVEhfVEVBQ0hFUlMiLCJBVVRIX01PTklUT1IiLCJBVVRIX1NUQUZGIl0sImp0aSI6IjMyODBlN2FmLTRmNGQtNGEyMS1hZGNhLTQxNDllZThlOTJkZSIsImNsaWVudF9pZCI6ImNsaWVudGlkIn0.lv3qjIagjCFf29IVfq64w1dQY1CdMmWIHcnzgJYPkggPIlJCT2Vm9li-onJROwHSuhCfKZPnH-dalHo7a43Bug_pCqWJBqG2GCPkkQI-YjZi-Cm4vNex5kgsp5Oc0dhZgj2JjbbvhJnPVnXX-XEsBr5vF0GIQicle_tTQLU4m8TNedLRmynM282gxs11U8Y5BCmqRzJ8OAHzGx12i3xfS0Wg_NXPSvIpAYXZojjW9oQ45HqIqYVH2Ufw6ah2058GAI9eC2bjK59krFBKY8bdR5FIaRTgJtdbOoZ47-9cYyrtiUd6Ua4rW4xrJxDY4xRjKmfmLO5XEoqetCF-pEHbpg')

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// I18
// const i18n = new VueI18n({
//   locale: "en",
//   messages: {
//     'zh': require('@/lang/zh.js'),
//     'en': require('@/lang/en.js')
//   }
// })
export const getFirstBrowserLanguage = () => {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let i;
  let language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return null;
};
const getLocale = () => {
  if (localStorage.lang) {
    return localStorage.lang;
  }
  return getFirstBrowserLanguage() === 'zh-CN' ? 'en' : 'en';
};
const i18n = new VueI18n({
  locale: getLocale(),
  messages: {
    en: require('./assets/lang/en'),
    zh: require('./assets/lang/zh'),
  },
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$buttonPermission = Utils.buttonPermission
Vue.prototype.$escape2Html = Utils.escape2Html
// example
// store.commit('SET_USERTYPE', 0)
// Auth.setUserType(0)
// store.commit('SET_ISADMIN', 1)
// Auth.setIsAdmin(1)
store.commit('SET_LOCATION', ["All12", "Bukit Panjang", "Punggol", "Jurong west"])
Auth.setLocation(["All12", "Bukit Panjang", "Punggol", "Jurong west"])
store.commit('SET_COMPANYID', 'f6d03cad31be4e39830651138759e92a')
// //locationId: "dd5af72474934632aa53077edf050e8f"
// store.commit('SET_LOCATIONID', 'dd5af72474934632aa53077edf050e8f')


// // 登录后保存当前用户的userid,即company列表里的userid
// store.commit('SET_USERID', '04d3ddc5dbbe4a568a99424c469a08fa')

// console.log('userType', store.state.user.userType)