/**
 * 此处仅仅定义类型以及 props 给外层使用传值
 *
 * 真正的实际使用的_TableColumn组件
 *
 * 该组件不需要render任何内容
 *
 */

/**
 * 属性说明
 *
 * @property {string} label 列的表头文字
 * @property {string} prop 需要显示的数据项的key，可以以path作为prop，如：a.b.c
 * @property {string} key 默认循环使用prop，如重复或空缺，添加 key, key = 'action' 为特殊处理字段，表明为hover action
 * @property {S | M | L | XL} size 列宽规格 分别对应 68 140 220 296 的宽度，默认 M
 * @property {string} width 列宽度，优先级高于 size
 * @property {string} align 当前列所有cell(包含thead以及tbody)的对齐方式，默认 left
 * @property {left | right | center} headerAlign 当前列thead的cell对齐方式，优先级高于align
 * @property {string} sortName 有值时表示当前字段可以排序，并定义一个name，该name为初始传入table的值sortedBy
 * @property {string} defaultValue 在当前cell渲染时候，如果没有插槽并且对应的prop没有值(undefined | null)，渲染默认值，优先级高于Table组件上设置的全局的值
 * @property {boolean | (data) => string | boolean} tooltip 是否开启当前列的tooltip，函数的参数 data 数据结构和插槽一致 @see {CellData}
 *                             - 如果是false/函数返回值为false 表示 不提供当前列的 tooltip
 *                             - 如果是true/函数返回值为true 表示 提供toolip 并且tooltip的值为当前cell对应的值
 *                             - 如果是string/函数返回值为string 表示提供tooltip 并且tooltip的值为string的内容
 *                             以前条件均遵循：仅在有值（值不是使用defaultValue的情况）并且不是使用非slot的情况下生效
 *                             如果使用slot可以自己使用el-tooltip并且添加edoovo-table-cell-tooltip类即可
 * @callback { (row, column, cellValue, index) => string } formatter 格式化方法，优先级高于defaultValue
 * @param { object } row 返回row信息
 * @param { object } column 返回column信息
 * @param { string } cellValue 返回当前文本
 * @param { number } index 返回当前行index
 * @returns { string } 返回一个格式化之后的数据
 *
 */

/**
 * 默认插槽的插槽数据 v-slot/slot-scoped
 *
 * @typedef {Object} CellData
 * @property {any} row prop所对应当前cell的数据
 * @property {number} $index 当前数据项所对应 data 中的index
 */

/**
 * 事件可以绑定所有原生事件
 * 参数的第一个值为数据 @see {CellData} 第二个为 event
 */

export default {
  props: [
    'prop',
    'label',
    'size',
    'width',
    'align',
    'headerAlign',
    'sortName',
    'defaultValue',
    'tooltip',
    'formatter',
    'getTooltipText'
  ],
  name: 'EdoovoTableColumn'
};
